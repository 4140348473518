<fieldset *ngIf="element.isShowAdvancedOptions" [disabled]="isReadOnly()">
    <select *ngIf="this.hasStates" [(ngModel)]="this.currentState" (change)="emitState()">
        <option *ngFor="let state of this.states" [value]="state.id">
          {{state.caption}}
        </option>
    </select>
</fieldset>
<div *ngIf="element.isShowAdvancedOptions">
    <frame-config *ngIf="showFrame" [element]="element.frameState"></frame-config>
</div>
<fieldset [disabled]="isReadOnly()" *ngIf="element.isShowAdvancedOptions">
    <div class="simple-form-container">
        <label>
            Disable Alt Text Tooltip:
            <input type="checkbox" [style.width.em]="4" [(ngModel)]="element.disableAltText"/>
        </label><br>

        <label> 
            Use Full Width?
            <input type="checkbox" [(ngModel)]="element.isFullWidth"/>
        </label>
    </div>
</fieldset>
<fieldset [disabled]="isReadOnly()">
    <div *ngIf="canMove && false">
        <label>
            <input class="input is-small" [(ngModel)]="this.element.XPosition" type="number" [style.width.em]="4">&nbsp;X
        </label>
        <label>
            <input class="input is-small" [(ngModel)]="this.element.YPosition" type="number" [style.width.em]="4">&nbsp;Y
        </label>
    </div>
</fieldset>
<div *ngIf="this.element.images[this.currentState]">
    <div *ngIf="isNormal()">
        <capture-image [element]="element.images[this.currentState].image" [inverseContrastImg]="getHiContrastImage()" [parentElement]="element" fileType="image" [isNoScale]="this.isNoScale" (change)="change.emit()"></capture-image>
        <asset-library-link [element]="element.images[this.currentState].image"></asset-library-link>
    </div>
    <div *ngIf="isInHiContrast()">
        <capture-image [element]="getHiContrastImage()" [inverseContrastImg]="element.images[this.currentState].image" [parentElement]="element" fileType="image" [isNoScale]="this.isNoScale"></capture-image>
        <asset-library-link [element]="element.images[this.currentState].image"></asset-library-link>
    </div>
    <!-- <div>
        <div class="select is-small is-fullwidth" *ngIf="isFontFamiliesEnabled()">
            <select [(ngModel)]="sub.fontFamily">
              <option value="">(Default)</option>
              <option *ngFor="let font of fontStyles; let index = index" [value]="font.id">
                {{font.caption}}
              </option>
            </select>
        </div><br>
        <span>
            <button *ngFor="let align of alignments" [disabled]="isReadOnly()" class="button is-small"  (click)="sub.alignment = align.id">
              <i class="fa {{align.icon}}"></i> -->
</div><br>
<div [style.font-size]="16" [style.font-weight]="'bold'"><tra slug="auth_texte"></tra></div>

<fieldset *ngIf="element.isShowAdvancedOptions" [disabled]="isReadOnly()">
    <div *ngFor="let sub of this.element.subtexts" style="border-bottom:1px solid #ccc; padding-bottom:1em; margin-bottom:1em;">
        <div style="margin-bottom:1em">
            <div class="simple-form-container">
                <label title="Width">               W  <num-scrub [el]="sub" prop="width" [max]="40"></num-scrub></label>
                <label title="Rotation">            R  <num-scrub [el]="sub" prop="rotation" [step]="0.1" [min]="-180" [max]="180"></num-scrub></label>
                <label title="Horizontal Position"> X  <num-scrub [el]="sub" prop="x" [max]="40"></num-scrub></label>
                <label title="Vertical Position">   Y  <num-scrub [el]="sub" prop="y" [max]="60"></num-scrub></label>
                <label title="Font Size">           S  <num-scrub [el]="sub" prop="size"[max]="4"></num-scrub></label>
                <label title="Line Height">         LH  <num-scrub [el]="sub" prop="lineHeight" [max]="3"></num-scrub></label>
                <label> <tra slug="auth_invisible"></tra>  <input type="checkbox" [(ngModel)]="sub.invisible" /></label>
            </div>
        </div>
        <div class="text-container-row">
            <textarea class="textarea text-container-content" 
                  type="text" 
                  cdkTextareaAutosize 
                  [cdkTextareaAutosize]="true" 
                  [cdkAutosizeMinRows]="2" 
                  [(ngModel)]="sub.text"></textarea>
            <button  (click)="deleteSubText(sub)" class="button is-medium has-icon text-container-button">
                <span class="icon"><i class="fa fa-trash" aria-hidden="true"></i></span>
            </button>
        </div>
        <div>
            <div [class.is-disabled]="isReadOnly()" class="select is-small is-fullwidth" *ngIf="isFontFamiliesEnabled()">
                <select [(ngModel)]="sub.fontFamily">
                  <option value="">(Default)</option>
                  <option *ngFor="let font of fontStyles; let index = index" [value]="font.id">
                    {{font.caption}}
                  </option>
                </select>
            </div><br>
            <span>
                <button *ngFor="let align of alignments" [disabled]="isReadOnly()" class="button is-small"  (click)="sub.alignment = align.id">
                  <i class="fa {{align.icon}}"></i>
                </button>
            </span>
        </div>
        <div class="option-container" style="display: flex; justify-content: flex-start">
            <label><tra slug="auth_text_colour"></tra> 
                <input [(ngModel)]="sub.colourScheme.textColor" class="is-small content-right" type="color">
                <input class="is-small" type="text" [(ngModel)]="sub.colourScheme.textColor" [maxLength]="7" [style.width.em]="5">
            </label>
        </div>
        <div class="control">
        <!--<button type="submit" (click)="deleteSubText(sub)" class="button is-primary">Delete Caption</button>-->
        </div>
    </div>
    <div class="control">
        <button  (click)="addSubText()" class="button is-small has-icon add-option">
            <span class="icon"><i class="fa fa-plus" aria-hidden="true"></i></span>
            <span [style.vertical-align]="'middle'"><tra slug="auth_add_text"></tra></span>
        </button>
    </div>

    <capture-voice 
        [isDisabled]="isReadOnly()"
        [element]="element.voiceover" 
        [scriptChanges]="getVoiceChange(element)" 
        title="Image Voice-over"
        [isSmall]="true"
        [isMagicEnabled]="false"
    ></capture-voice>
</fieldset>
<div>
    <label class="checkbox">
      <input type="checkbox" [(ngModel)]="element.isShowAdvancedOptions">
      <tra slug="auth_show_advanced_options"></tra>
    </label>
</div>