import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { AuthService } from "src/app/api/auth.service";
import { AccountType } from "src/app/constants/account-types";
import { LangService } from "../lang.service";
import { first } from "rxjs/operators";

@Injectable({ providedIn: 'root' })
export class GlobalAuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private auth: AuthService,
    private lang: LangService
  ) {}

  /**
   * Doesn't immediately block access to the route, instead:
   *  - Subscribes to the userSub and waits for first userInfo to be emitted
   *  - Checks if the user has access to the route/subroute based on their account types
   *  - If the user doesn't have access, they are redirected to the login page
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const allowedRoles = route.data['allowedRoles'] as AccountType[] | undefined;

    console.log('canActivate()', allowedRoles);

    if(!allowedRoles || allowedRoles.length === 0) {
      return true; // no roles specified, allow access to all
    }

    this.checkAccess(allowedRoles);

    return true;
  }

  checkAccess(allowedRoles: AccountType[]) {
    this.auth.userSub.pipe(first(user => !!user)).subscribe(user => {
      const hasAccess = allowedRoles.some(requiredRole => {
        return user.accountTypes.some(userRole => {
          return userRole.account_type === requiredRole;
        });
      });

      console.log(`checkAccess(${allowedRoles})`, hasAccess);
      if (!hasAccess) {
        this.router.navigate([this.lang.c(), 'login-router-st']);
      }
    });
  }
}

/**
 * Checks if the user has access to the route/subroute based on their account types
 * @param allowedRoles: AccountType[] - The roles that are allowed to access the route
 */
export const allowOnlyRoles = (allowedRoles: AccountType[]) => {
  return {
    canActivate: [GlobalAuthGuard],
    data: {
      allowedRoles
    }
  }
};
