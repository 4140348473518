<div>
    <div *ngIf="!isPrivateSchool && !isIssueReviewer" class="dont-print filter-panels">
        <div>
            <filter-toggles 
                [state]="schoolService.getClassFilterToggles()"
                (id)="setClassFilter($event)"
            ></filter-toggles>
        </div>
        <div *ngIf="isShowingReports && adminWindows.length > 0">
            <tra slug="sa_class_test_window_lb"></tra> 
            <select (change)="onAdminWindowChanged($event.target.value)" class="school-year-select">
                <option *ngFor="let adminWindow of adminWindows; let i = index" [value]="i" [selected]="i===currentAdminWindowIndex()">{{getAdminWindowSlug(adminWindow)}}</option>
            </select>
        </div>
        <div *ngIf="isShowingG9Reports && g9AdminWindows.length > 0">
            <tra slug="sa_class_test_window_lb"></tra> 
            <select (change)="onG9AdminWindowChanged($event.target.value)" class="school-year-select">
                <option *ngFor="let adminWindow of g9AdminWindows; let i = index" [value]="i" [selected]="i===currentG9AdminWindowIndex()">{{getAdminWindowSlug(adminWindow)}}</option>
            </select>
        </div>   
        <div *ngIf="isShowingPJReports && adminWindows.length > 0">
            <tra slug="sa_class_test_window_lb"></tra> 
            <select (change)="onPJAdminWindowChanged($event.target.value)" class="school-year-select">
                <option *ngFor="let adminWindow of adminWindows; let i = index" [value]="i" [selected]="i===currentAdminWindowIndex()">{{getAdminWindowSlug(adminWindow)}}</option>
            </select>
        </div>
        <button *ngIf="showAppealBtn()" (click)="showAppealBtnClick()" class="button" [class.is-info]="showAppealInterface">
            <tra slug="btn_request_appeal"></tra>
        </button>
    </div>
    <div *ngIf="isPrivateSchool && !isIssueReviewer" class="dont-print filter-panels">
        <div>
          <filter-toggles 
            [state]="mySchool.getPrivateSchoolClassFilterToggles()"
            (id)="setClassFilter($event)"
          ></filter-toggles>
        </div>
        <div *ngIf="isShowingReports && adminWindows.length > 0">
            <tra slug="sa_class_test_window_lb"></tra> 
            <select (change)="onAdminWindowChanged($event.target.value)" class="school-year-select">
                <option *ngFor="let adminWindow of adminWindows; let i = index" [value]="i" [selected]="i===currentAdminWindowIndex()">{{getAdminWindowSlug(adminWindow)}}</option>
            </select>
        </div>
        <div *ngIf="isShowingG9Reports && g9AdminWindows.length > 0">
            <tra slug="sa_class_test_window_lb"></tra> 
            <select (change)="onG9AdminWindowChanged($event.target.value)" class="school-year-select">
                <option *ngFor="let adminWindow of g9AdminWindows; let i = index" [value]="i" [selected]="i===currentG9AdminWindowIndex()">{{getAdminWindowSlug(adminWindow)}}</option>
            </select>
        </div>
        <div *ngIf="isShowingPJReports && adminWindows.length > 0">
            <tra slug="sa_class_test_window_lb"></tra> 
            <select (change)="onPJAdminWindowChanged($event.target.value)" class="school-year-select">
                <option *ngFor="let adminWindow of adminWindows; let i = index" [value]="i" [selected]="i===currentAdminWindowIndex()">{{getAdminWindowSlug(adminWindow)}}</option>
            </select>
        </div>
        <button *ngIf="showAppealBtn()" (click)="showAppealBtnClick()" class="button" [class.is-info]="showAppealInterface">
            <tra slug="btn_request_appeal"></tra>
        </button> 
    </div>
    <div *ngIf="showAppealInterface" class="dont-print">
        <sa-reports-appeal [testWindowID] = "getCurrentTestWindowID()"></sa-reports-appeal>
    </div>
    <div *ngIf="allowBypassDomain() && !isIssueReviewer" class="dont-print">
        <input #setBypassAdminSignOff id="setBypassAdminSignOff" type="checkbox" [checked]="bypassAdminSignOff" (change)="onSetBypassAdminSignOff(setBypassAdminSignOff.checked)" />Bypass Admin SignOff
    </div>    
    <!-- <ng-container *ngIf="!isShowingReports && !isShowingPJReports" class="dont-print">
        <tra-md slug="txt_report_blk_print_instr"></tra-md>
    </ng-container> -->
    <ng-container *ngIf="(isShowingReports || isShowingPJReports)">
        <div *ngIf="singleStudentUid && !isIssueReviewer" class="dont-print">
            <tra slug="txt_report_print_single"></tra>
            <a routerLink="/{{lang.c()}}/school-admin/reports" [queryParams]="getFullReportParam()" (click)="clearSingleSudentFocus()">
                <tra slug="txt_report_print_single_cancel"></tra>
            </a>
            <tra-md slug="txt_report_single_print_warning"></tra-md>
        </div>
        <div *ngIf="!singleStudentUid && currentClassFilter == ClassFilterId.OSSLT && !isIssueReviewer" class="dont-print">
            <tra-md slug="txt_report_blk_print_warning"></tra-md>
            <p><tra-md slug="admin_err_msg_reports_invalidate_stu"></tra-md></p>
        </div>
        <div *ngIf="!singleStudentUid && (currentClassFilter == ClassFilterId.Primary || currentClassFilter == ClassFilterId.Junior) && studentPJData.length > 0" class="dont-print">
            <tra-md slug="txt_pj_report_blk_print_warning"></tra-md>
            <p><tra-md slug="admin_err_msg_reports_invalidate_stu"></tra-md></p>
            <div><tra slug="title_pj_total_num_report"></tra>&nbsp;<b>{{ studentPJData.length }}</b></div>
        </div>
        <div *ngIf="!singleStudentUid && currentClassFilter == ClassFilterId.OSSLT && !showExport() && !isIssueReviewer" class="dont-print">
            <tra-md slug="txt_report_csv_note"></tra-md>
        </div>
        <div *ngIf="!singleStudentUid && currentClassFilter == ClassFilterId.OSSLT && !isIssueReviewer" class="dont-print" ><tra slug="adm_reports_generated_after"></tra><input type="date" [formControl]="report_after_date" max="2099-01-01" (change)="generateReportsAfterDate()"></div>
        <!-- <div *ngIf="studentReports && isReportsLoaded"> -->
        <div *ngIf="(studentReportsAfterDate || studentPJData)">  
            <div *ngIf="!isIssueReviewer" class="top-level" class="dont-print">
              <div  class="dont-print">
                <div *ngIf="isShowingReports">
                    <button (click)="print()" class="button is-success ">
                        <tra slug="ie_print"></tra>
                        <!-- <span>&nbsp;{{studentReports.length}} <tra slug="txt_reports_plur"></tra> </span> -->
                        <span *ngIf="studentReportsAfterDate">&nbsp;{{studentReportsAfterDate.length}} <tra slug="txt_reports_plur"></tra> </span>
                    </button>
                </div>
                <br>
                <div *ngIf ='isShowingPJReports && studentPJData.length > 0'>
                    <div>
                        <button (click)="generetePJReport()" class="button is-success ">
                            <tra slug="btn_gen_pj_pdf_reports"></tra>
                        </button>
                    </div>
                    <div><b><tra slug="sa_text_report_generated_on"></tra>{{getPJReportGenerateOn()}}</b></div><br>
                    <div>
                        <button (click)="printPJReport()" class="button is-success">
                            <tra slug="sa_download_pj_report"></tra>
                        </button>
                    </div>
                    <br>
                </div>       
                <span *ngIf="isAllowBypassDomain() && !isShowingPJReports">
                    <span *ngIf="((isSignoffAvail() || bypassAdminSignOff) && showExport())||isSecreteUser">
                        <button style="margin-left: 1em;" class="button is-success" (click)="exportOSSLTResult()">
                            <span class="icon"><i class="fas fa-table"></i></span>
                            <span><tra slug="g9_export"></tra></span>
                        </button>
                        <span *ngIf="isAllowBypassDomain()" class="btn_export_report"><tra slug="osslt_export_report_csv"></tra></span>
                    </span>
                </span>
              </div>
            </div>
            <!-- <ng-container *ngFor="let report of studentReports"> -->
            <p *ngIf ='isShowingPJReports && studentPJData.length > 0' class="dont-print"><b><tra slug="sa_report_top_msg"></tra></b><br></p>
            <div #printContent *ngIf = "currentClassFilter === ClassFilterId.OSSLT">
                <ng-container *ngIf = 'isFireFox()' class="page-break">&nbsp;</ng-container>
                <ng-container *ngFor="let report of studentReportsAfterDate let i = index"> 
                    <div class="ind-student-report page-break" [class.ind-student-report-v1]="getReportVersion(report)=='reportVersion1'" [class.report-version-1-firefox-page1]="isFireFox() && i == 0" [class.report-version-1-firefox]="isFireFox()">
                        <div [ngSwitch]="getReportVersion(report)">
                            <div *ngSwitchCase="'reportVersion0'">             
                                <div class="ind-student-report-header">
                                    <div>
                                        <img *ngIf="isEnglish()" style="max-width: 9em;" [src]="whitelabel.getSiteText('g9_sample_test_logo_en')">
                                        <img *ngIf="!isEnglish()" style="max-width: 9em;" [src]="whitelabel.getSiteText('g9_sample_test_logo_fr')">
                                    </div>
                                    <h1><tra slug="lbl_osslt_reports"></tra></h1>
                                    <!-- <h3>{{REPORT_YEAR}}</h3> -->
                                    <h3>{{getReportYear(report)}}</h3>
                                    <h3><tra slug="lbl_student_report"></tra></h3>
                                </div>
                                <div class="ind-student-report-section-container">
                                    <div class="ind-student-report-section-header">
                                        {{report.first_name}} {{report.last_name}}
                                    </div>
                                    <div class="space-between">
                                        <table>
                                            <tr>
                                                <th><tra-md slug="student_report_oen" [isCondensed]="true"></tra-md></th>
                                                <td>{{maskOENWithFourStars(report.student_oen)}}</td>
                                            </tr>
                                            <tr>
                                                <th><tra-md slug="student_report_school" [isCondensed]="true"></tra-md></th>
                                                <td>{{report.school_name}} ({{report.school_mident}})</td>
                                            </tr>
                                            <tr>
                                                <th><tra-md slug="student_report_school_board" [isCondensed]="true"></tra-md></th>
                                                <td>{{report.school_board_name}}</td>
                                            </tr>
                                        </table>
                                        <table>
                                            <tr>
                                                <th><tra-md slug="student_report_date" [isCondensed]="true"></tra-md></th>
                                                <td>{{renderAttemptDate(report.written_on)}}</td>
                                            </tr>
                                            <tr>
                                                <th><tra-md slug="student_report_generated" [isCondensed]="true"></tra-md></th>
                                                <td>{{renderAttemptDate(report.report_gen_on)}}</td>
                                            </tr>
                                        </table>
                                    </div>
                                    <!-- <div class="grey-text"><tra-md slug="txt_written_date"></tra-md></div> -->
                                </div>
                                <div class="ind-student-report-section-container">
                                    <div class="ind-student-report-section-header">
                                        <tra slug="student_report_result_osslt"></tra>
                                    </div>
                                    <ng-container [ngSwitch]="report.is_data_insufficient">
                                        <!-- <p *ngSwitchCase="1"><tra slug="student_report_insufficient>"></tra></p> -->
                                        <ng-container *ngSwitchDefault>
                                            <!-- <ng-container [ngSwitch]="reportResult(report)"> -->
                                            <ng-container [ngSwitch]="report.result_state">    
                                                <p *ngSwitchCase="0"  style="margin-left: 0.5em;"><tra slug="sa_report_state_0"></tra></p>
                                                <p *ngSwitchCase="1"  style="margin-left: 0.5em;"><tra slug="sa_report_state_1"></tra></p>
                                                <p *ngSwitchCase="2"  style="margin-left: 0.5em;"><tra slug="sa_report_state_2"></tra></p>
                                                <p *ngSwitchCase="3"  style="margin-left: 0.5em;"><tra slug="sa_report_state_3"></tra></p>
                                                <p *ngSwitchCase="4"  style="margin-left: 0.5em;"><tra slug="sa_report_state_4"></tra></p>
                                                <p *ngSwitchCase="5"  style="margin-left: 0.5em;"><tra slug="sa_report_state_5"></tra></p>
                                                <p *ngSwitchCase="6"  style="margin-left: 0.5em;"><tra slug="sa_report_state_6"></tra></p>
                                                <p *ngSwitchCase="10" style="margin-left: 0.5em;"><tra slug="sa_report_state_10"></tra></p>
                                                <p *ngSwitchCase="11"  style="margin-left: 0.5em;"><tra slug="sdc_const_osslt_succ_2"></tra></p>
                                            </ng-container>
                                            <!--
                                            <ng-container [ngSwitch]="report.overall_result">
                                                <p *ngSwitchCase="1" style="margin-left: 0.5em;"><tra slug="student_report_success"></tra></p>
                                                <p *ngSwitchCase="0" style="margin-left: 0.5em;"><tra slug="student_report_not_success"></tra></p>
                                            </ng-container>
                                            -->
                                        </ng-container>
                                    </ng-container>
                                </div>
                                <div class="grey-text"><tra-md slug="txt_privacy"></tra-md></div>            
                            </div>
                            <div *ngSwitchCase="'reportVersion1'">
                                <div class="report-version-1" [class.report-version-1-non-page1]="i!=0" [ngClass]="{'report-details-fr-alt' : isLinearFailReport(report) && lang.c() === 'fr', 'firefox': isFireFox()}">
                                    <!-- Header -->
                                    <div class="image-container">
                                        <img  [src]="lang.tra(whitelabel.getSiteText('sa_report_logo_link_2022'))" />
                                    </div>
                                    <div class="header-container">
                                        <h2 class="report-header report-header-1">
                                            <tra style="font: 700 18pt Arial;" slug="lbl_osslt_reports_2_title_1"></tra>
                                        </h2>
                                        <h2 class="report-header report-header-2">
                                            <tra style="font: 700 18pt Arial;" slug="lbl_osslt_reports_2_title_2"></tra>
                                        </h2>
                                        <div style="font: 400 1.2em Arial; margin-top: 0.25em;" >{{getReportYearVersion1(report)}}</div>
                                    </div>
                                    <!-- Student Name, OEN, School Info -->
                                    <div class="report-details" [class.report-details-en] = "lang.c() === 'en'" [class.report-details-fr] = "lang.c() === 'fr'">
                                        <div class="student-full-name">
                                            {{report.first_name}} {{report.last_name}}
                                        </div>
                                        <div>
                                            <table>
                                                <tr>
                                                    <th><tra slug="student_report_oen_2"></tra></th>
                                                    <td>{{maskOENWithFiveStars(report.student_oen)}}</td>
                                                </tr>
                                                <tr>
                                                    <th><tra slug="student_report_school_2"></tra></th>
                                                    <td>{{report.school_name}} ({{report.school_mident}})</td>
                                                </tr>
                                                <tr>
                                                    <th><tra slug="student_report_school_board_2"></tra></th>
                                                    <td>{{report.school_board_name}}</td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                    <!-- Student Result -->
                                    <div class="report-student-result">
                                        <!-- RESULTS -->
                                        <div class="result-title">
                                            <tra style="font: 400 1.2em Arial;" slug="student_report_result_osslt"></tra>
                                        </div>
                                        <ng-container [ngSwitch]="report.is_data_insufficient">
                                            <ng-container *ngSwitchDefault>
                                                <ng-container [ngSwitch]="report.result_state"> 
                                                    <!-- pending -->
                                                    <p *ngSwitchCase="0"><b><tra slug="sa_report_state_0"></tra></b></p>
                                                    <!-- successful -->
                                                    <div *ngSwitchCase="1" >
                                                        <p *ngIf="!showReportNumQ(report)" style = "font-weight: 700; padding-bottom: 2em;">{{lang.tra("sa_report_state_1_successful")}}</p>
                                                        <div *ngIf="showReportNumQ(report)">
                                                            <tra style = "margin-bottom: 0.25em; font-weight: 700; padding-bottom: 2em;" slug="sa_report_state_1_successful"></tra>
                                                            <table class="report-unsuccessful-details" [class.report-unsuccessful-details-en] = "lang.c() === 'en'" [class.report-unsuccessful-details-fr] = "lang.c() === 'fr'" [ngClass]="{'report-unsuccessful-details-fr-alt' : isLinearFailReport(report) && lang.c() === 'fr'}">
                                                                <tr>
                                                                    <th style = "font-weight: 700;">{{lang.tra("sa_report_unsuccessful_score")}}</th>
                                                                    <td style = "font-weight: 700;">{{getScaleScore(report)}}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th style = "font-weight: 700;">{{lang.tra("sa_report_unsuccessful_attempts")}}</th>
                                                                    <td style = "font-weight: 700;">{{report.questions_answered}}{{lang.tra("sa_report_unsuccessful_attempts_of")}}&nbsp;{{report.total_questions}}</td>
                                                                </tr>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <!-- not yet successful -->
                                                    <div *ngSwitchCase="2">
                                                        <tra style = "margin-bottom: 0.25em;" slug="sa_report_state_2_unsuccess"></tra>
                                                        <table class="report-unsuccessful-details" [class.report-unsuccessful-details-en] = "lang.c() === 'en'" [class.report-unsuccessful-details-fr] = "lang.c() === 'fr'" [ngClass]="{'report-unsuccessful-details-fr-alt' : isLinearFailReport(report) && lang.c() === 'fr'}">
                                                            <tr>
                                                                <th style = "font-weight: 700;">{{lang.tra("sa_report_unsuccessful_score")}}</th>
                                                                <td style = "font-weight: 700;">{{getScaleScore(report)}}</td>
                                                            </tr>
                                                            <tr *ngIf="showReportNumQ(report)">
                                                                <th style = "font-weight: 700;">{{lang.tra("sa_report_unsuccessful_attempts")}}</th>
                                                                <td style = "font-weight: 700;">{{report.questions_answered}}{{lang.tra("sa_report_unsuccessful_attempts_of")}}&nbsp;{{report.total_questions}}</td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                    <p *ngSwitchCase="3"><b><tra slug="sa_report_state_3"></tra></b></p>
                                                    <p *ngSwitchCase="4"><b><tra slug="sa_report_state_4"></tra></b></p>
                                                    <p *ngSwitchCase="5"><b><tra slug="sa_report_state_5"></tra></b></p>
                                                    <p *ngSwitchCase="6"><b><tra slug="sa_report_state_6"></tra></b></p>
                                                    <p *ngSwitchCase="10"><b><tra slug="sa_report_state_10"></tra></b></p>
                                                    <p *ngSwitchCase="11"><b><tra slug="sdc_const_osslt_succ_2"></tra></b></p>
                                                </ng-container>
                                            </ng-container>
                                        </ng-container>
                                        <div *ngIf = "showALtAsScaleScore(report)" style = "font: 400 8pt Arial; margin-top: 0.5rem;">{{lang.tra("sa_student_report_result_osslt_footer")}}</div>
                                    </div>
                                    <!-- Result Explaination -->
                                    <div class="result-explaination" 
                                        [class.result-explaination-1-fr] = "report.result_state == '2' && lang.c()=='fr'"
                                        [class.result-explaination-1-en] = "report.result_state == '2' && lang.c()=='en'"    
                                        [class.result-explaination-2] = "report.result_state != '2'" 
                                        [ngSwitch]="report.result_state">
                                        <div  *ngSwitchCase="1">
                                            <div class="result-explaination-caption">
                                                <tra-md style ='font: 400 1.2em Arial; margin-bottom: 0em;' slug="student_report_result_explaination_caption"></tra-md>
                                            </div>
                                            <div class="result-explaination-caption">
                                                <tra-md style="font: 400 0.9em Arial; margin-bottom: 0em; margin-top: 0em; word-spacing: 0.05em; line-height: 1.2em;" slug="student_report_result_explaination_subcaption"></tra-md>
                                            </div>
                                            <div class="result-1-explaination-body">
                                                <!-- Reading -->
                                                <div class="reading-container">
                                                    <div class="container-header"><tra style="font: 700 14px/20px Arial;" slug="student_report_result_content_title_reading"></tra></div>
                                                    <div class="container-body"><tra-md style="font: 400 9.3pt Arial; line-height: 1.3em;" slug="student_report_result_content_reading"></tra-md></div>
                                                </div>
                                                <!-- Writing -->
                                                <div class="writing-container">
                                                    <div class="container-header"><tra style="font: 700 14px/20px Arial;" slug="student_report_result_content_title_writing"></tra></div>
                                                    <div class="container-body"><tra-md style="font: 400 9.3pt Arial; line-height: 1.3em;" slug="student_report_result_content_writing"></tra-md></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div  *ngSwitchCase="2">
                                            <div class="result-explaination-caption">
                                                <tra-md style ='font: 400 1.2em Arial; margin-bottom: 0em; margin-top: 0.5em;' slug="student_report_result_explaination_caption_unsuccessful"></tra-md>
                                            </div>
                                            <div class="result-explaination-caption">
                                                <tra-md *ngIf = "!isLinearFailReport(report)" style="font: 400 0.9em Arial; margin-bottom: 0em; margin-top: 0em; word-spacing: 0.05em; line-height: 1.2em;" slug="student_report_result_explaination_subcaption_unsuccessful"></tra-md>
                                                <tra-md *ngIf = "isLinearFailReport(report)" style="font: 400 0.9em Arial; margin-bottom: 0em; margin-top: 0em; word-spacing: 0.05em; line-height: 1.2em;" slug="student_report_result_explaination_subcaption_unsuccessful_linear"></tra-md>
                                            </div>
                                            <div class="result-2-explaination-body">
                                                <!-- Reading -->
                                                <div class="reading-container">
                                                    <div><tra style="font: 700 14px/20px Arial;" slug="student_report_result_content_title_reading"></tra></div>
                                                    <div class="container-body"><tra-md style="font: 400 9.3pt Arial; line-height: 1.3em;" slug="student_report_result_content_reading_unsuccessful"></tra-md></div>
                                                </div>
                                                <!-- Writing -->
                                                <div class="writing-container">
                                                    <div><tra style="font: 700 14px/20px Arial;" slug="student_report_result_content_title_writing"></tra></div>
                                                    <div class="container-body"><tra-md style="font: 400 9.3pt Arial; line-height: 1.3em;" slug="student_report_result_content_writing_unsuccessful"></tra-md></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Report Footer -->
                                    <div class="report-footer">
                                        <tra-md style = "font: 400 8pt Arial;" slug="student_report_result_footer"></tra-md>
                                        <tra-md style = "font: 400 8pt Arial;" slug="txt_privacy"></tra-md>
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div> 
                </ng-container>    
            </div>
            <!-- PJ New Test Window -->
            <div *ngIf = "showPJReportByImage() && studentPJData && getPJReportGenerateOn() !== ''">
                <div *ngFor="let student of studentPJData let i = index">
                    <img [src]="student.image_url" style="border:1px solid black; margin-top: 1rem; max-width:50% !important; height:auto">
                </div> 
            </div>
            <!-- PJ Old Test Window -->
            <div *ngIf = "!showPJReportByImage() && studentPJData">
                <ng-container *ngFor="let report of studentPJData let i = index">
                    <div class="ind-student-report page-break" 
                         [class.pj-ind-student-report-en] = "lang.c() === 'en' && !isFireFox()" 
                         [class.pj-ind-student-report-fr] = "lang.c() === 'fr' && !isFireFox()"
                         [class.pj-ind-student-report-en-firefox] = "lang.c() === 'en' && isFireFox()" 
                         [class.pj-ind-student-report-fr-firefox] = "lang.c() === 'fr' && isFireFox()">
                        <div class="report-version-pj">
                            <!-- Header -->
                            <div class="pj-image-container">
                                <img  [src]="lang.tra(whitelabel.getSiteText('sa_report_logo_link_2022'))" />
                            </div>
                            <div class="pj-header-container">
                                <h2 class="report-header-pj report-header-pj-title-1">
                                    <tra class="report-header-pj-font" slug="lbl_pj_reports_title_1"></tra>
                                </h2>
                                <h2 class="report-header-pj report-header-pj-title-2">
                                    <tra-md *ngIf = "currentClassFilter === ClassFilterId.Primary" class="report-header-pj-font" slug="lbl_g3_reports_title"></tra-md>     <!-- Need to fix the slug FR -->
                                    <tra-md *ngIf = "currentClassFilter === ClassFilterId.Junior" class="report-header-pj-font" slug="lbl_g6_reports_title"></tra-md> 
                                </h2>
                                <div style="font: 400 1.2em Arial; margin-top: 0.5em;" >{{getReportYearVersion1(report)}}</div>
                            </div>
                            <!-- Student Name, OEN, School Info -->
                            <div class="pj-report-details">
                                <div class="pj-student-full-name">
                                    {{report.stu_name}}
                                </div>
                                <div>
                                    <table>
                                        <tr>
                                            <th [class.pj-report-details-en] = "lang.c() === 'en'" [class.pj-report-details-fr] = "lang.c() === 'fr'"><tra slug="pj_student_report_oen"></tra></th>
                                            <td>{{report.stu_oen}}</td>
                                        </tr>
                                        <tr>
                                            <th [class.pj-report-details-en] = "lang.c() === 'en'" [class.pj-report-details-fr] = "lang.c() === 'fr'"><tra slug="student_report_school_2"></tra></th>
                                            <td>{{report.school}}&nbsp;({{report.sch_midant}})</td>
                                        </tr>
                                        <tr>
                                            <th [class.pj-report-details-en] = "lang.c() === 'en'" [class.pj-report-details-fr] = "lang.c() === 'fr'"><tra slug="pj_student_report_school_board"></tra></th>
                                            <td>{{report.sch_board}}</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                            <!-- Student Result -->
                            <div class="pj-report-student-result">
                                <div style="margin-bottom:0.5em;"><tra class="pj-result-title" slug="student_report_result_pj_title"></tra></div>
                                <div *ngIf = "currentClassFilter === ClassFilterId.Primary">
                                    <tra class="pj-result-content-1" slug="student_report_result_g3"></tra>
                                </div>
                                <div *ngIf = "currentClassFilter === ClassFilterId.Junior">
                                    <tra class="pj-result-content-1" slug="student_report_result_g6"></tra>
                                </div>
                                <div>
                                    <table>
                                        <tr>
                                            <th class="pj-report-student-result-wider-col">
                                                <span>&nbsp;</span>
                                                <span>&nbsp;</span>
                                            </th>
                                            <td class="pj-report-student-result-reg-col">
                                                <span><tra slug="lbl_pj_reports_lvl_0"></tra></span>
                                                <span><tra class="pj-report-student-result-reg-col-font" slug="pj_reports_lvl_0"></tra></span>
                                            </td>
                                            <td class="pj-report-student-result-reg-col">
                                                <span><tra slug="lbl_pj_reports_lvl_1"></tra></span>
                                                <span><tra class="pj-report-student-result-reg-col-font" slug="pj_reports_lvl_1"></tra></span>
                                            </td>
                                            <td class="pj-report-student-result-reg-col">
                                                <span><tra slug="lbl_pj_reports_lvl_2"></tra></span>
                                                <span><tra class="pj-report-student-result-reg-col-font" slug="pj_reports_lvl_2"></tra></span>
                                            </td>
                                            <td class="pj-report-student-result-reg-col">
                                                <span><tra slug="lbl_pj_reports_lvl_3"></tra></span>
                                                <span><tra class="pj-report-student-result-reg-col-font" slug="pj_reports_lvl_3"></tra></span>
                                            </td>
                                            <td class="pj-report-student-result-reg-col">
                                                <span><tra slug="lbl_pj_reports_lvl_4"></tra></span>
                                                <span><tra class="pj-report-student-result-reg-col-font" slug="pj_reports_lvl_4"></tra></span>
                                            </td>
                                        </tr>
                                        <tr *ngFor="let row of getReportTableBody(report); trackBy: byID; let i = index" [id]="row.id" style="min-height: 60px;">
                                            <ng-container *ngFor="let col of row.columns; trackBy: byID; let j = index">
                                                <th *ngIf="j===0" class="pj-report-student-result-wider-col" [id]="col.id">
                                                    <span style="margin-top:0.4em; margin-bottom:0.4em">
                                                        <tra style="font-weight:bold; font-size:0.85em;" slug="{{col.title}}"></tra>
                                                        <span style="font-size:0.7em; line-height:0;">{{ col.content }}</span>
                                                    </span>
                                                </th>
                                                <td *ngIf="j!==0" [id]="col.id" class="pj-report-student-result-reg-col">{{col.content}}</td>
                                            </ng-container>
                                            {{addSquarePosition(row.reportID, i, row.layers.layer_1, row.layers.layer_2)}}
                                        </tr>
                                    </table>
                                </div>
                                <div><tra-md class="pj-result-content-2" slug="student_report_result_pj_2"></tra-md></div>
                            </div>
                            <!-- Result Explaination -->
                            <div class="pj-result-explaination">
                                <div class="pj-result-explaination-caption">
                                    <tra class="pj-result-explaination-caption-font" slug="pj_student_report_result_explaination_caption"></tra>
                                </div>
                                <div class="pj-result-explaination-body">
                                    <!-- Reading -->
                                    <div class="pj-reading-container">
                                        <div class="pj-container-header"><tra class="pj-container-header-font" slug="pj_student_report_explaination_title_reading"></tra></div>
                                        <div class="pj-container-body"><tra-md class="pj-container-body-font" slug="pj_student_report_explaination_content_reading"></tra-md></div>
                                    </div>
                                    <!-- Writing -->
                                    <div class="pj-writing-container">
                                        <div class="pj-container-header"><tra class="pj-container-header-font" slug="pj_student_report_explaination_title_writing"></tra></div>
                                        <div class="pj-container-body"><tra-md class="pj-container-body-font" slug="pj_student_report_explaination_content_writing"></tra-md></div>
                                    </div>
                                    <!-- Mathematics -->
                                    <div class="pj-math-container">
                                        <div class="pj-container-header"><tra class="pj-container-header-font" slug="pj_student_report_explaination_title_math"></tra></div>
                                        <div *ngIf = "currentClassFilter === ClassFilterId.Primary" class="pj-container-body"><tra-md class="pj-container-body-font" slug="g3_student_report_explaination_content_math"></tra-md></div>
                                        <div *ngIf = "currentClassFilter === ClassFilterId.Junior" class="pj-container-body"><tra-md class="pj-container-body-font" slug="g6_student_report_explaination_content_math"></tra-md></div>
                                    </div>
                                </div>
                                <div class="pj-result-explaination-note">
                                    <tra class="pj-result-explaination-note-font" slug="pj_result_explaination_note"></tra>
                                </div>
                                <div class="pj-result-detail-info">
                                    <tra-md *ngIf = "currentClassFilter === ClassFilterId.Primary" class="pj-result-detail-info-font" slug="g3_result_report_detail_info"></tra-md>
                                    <tra-md *ngIf = "currentClassFilter === ClassFilterId.Junior" class="pj-result-detail-info-font" slug="g6_result_report_detail_info"></tra-md>
                                </div>
                            </div>
                            <!-- Report Footer -->
                            <div class="pj-report-footer">
                                <tra-md class="pj-footer-font" slug="student_report_result_footer"></tra-md>
                                <tra-md class="pj-footer-font" slug="txt_privacy"></tra-md>
                            </div>
                        </div>
                    </div>                    
                </ng-container>
            </div>   
        </div>
    </ng-container>
    <ng-container *ngIf="isShowingG9Reports && !isIssueReviewer">
        <paginator 
            [model]="classroomsTable.getPaginatorCtrl()" 
            [page]="classroomsTable.getPage()" 
            [numEntries]="classroomsTable.numEntries()" 
            (pageChange)="pageChanged()">
        </paginator>
        <div class="classrooms-table-container">
            <table class="table is-hoverable">
              <tr class="header-row">
                <th class="flush"> <table-header id = "class_code"      caption = "sa_classrooms_col_class_code"  [ctrl] = "classroomsTable" [isSortEnabled]="true"></table-header><!--Class Code--> </th>
                <th *ngIf="currentClassFilter === ClassFilterId.G9" class="flush"> 
                  <div class="space-between">
                    <table-header id = "semester" caption = "sa_classrooms_col_term_format"    [ctrl] = "classroomsTable" [isSortEnabled]="true" [disableFilter]="true"></table-header>  
                  </div>
                </th>
                <th class="flush"> <table-header id = "educator"        caption = "sa_classrooms_col_teachers"    [ctrl] = "classroomsTable" [isSortEnabled]="true"></table-header><!--Educator(s)--> </th>
                <th class="flush"> <table-header id = "students"        caption = "sa_classrooms_col_students"    [ctrl] = "classroomsTable" [isSortEnabled]="true"></table-header><!--Students--> </th>
                <th class="flush"> <table-header id = "reportLink"      caption = "view_reports"  [ctrl] = "classroomsTable" [isSortEnabled]="false" [disableFilter]="true"></table-header><!--Students--> </th>
            </tr>
              <tr *ngFor="let classroom of classroomsTable.getCurrentPageData()">
                <td style="white-space: pre-wrap;"> {{classroom.class_code}}
                  <span *ngIf="classroom.is_fi == 1" class="tag fr-imm"><tra slug="sa_lbl_french_immi"></tra></span> 
                </td>
                <td  *ngIf="currentClassFilter === ClassFilterId.G9" > {{classroom.semester_label}} </td>
                <td> {{classroom.educator}} </td>
                <td> <span *ngIf="classroom.students">{{classroom.students}}</span><span *ngIf="!classroom.students">0</span> </td>
                <td><button style="margin-top: 0.5em;" class="button is-small is-success" (click)="onClickG9Report(classroom)"><tra slug="view_reports"></tra></button></td>
              </tr>
            </table>  
          </div>
    </ng-container>
</div>