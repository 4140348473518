import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Inject } from '@angular/core';
import { replaceS3Domains } from '../core/util/transform';

@Injectable({
  providedIn: 'root'
})
export class SrcDomainService {
  private renderer: Renderer2;

  constructor(rendererFactory: RendererFactory2, @Inject(DOCUMENT) private document: Document) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  applySrcDomain() {
    this.applyToElements('img', 'src');
    this.applyToElements('video', 'src');
    this.applyToElements('audio', 'src');
    this.applyToElements('a', 'href');
  }

  private applyToElements(tagName: string, attributeName: string) {
    const elements = this.document.querySelectorAll(tagName);
    elements.forEach(element => {
      const attrValue = element.getAttribute(attributeName); // <img>, <a> and sometime <audio> have url store as attribute value 
      if (attrValue) {
        const newAttrValue = replaceS3Domains(attrValue);
        if (attrValue !== newAttrValue) {
          this.renderer.setAttribute(element, attributeName, newAttrValue);
        }
      }
  
      if (tagName === 'video' || tagName === 'audio') { // <video> and <audio> store url in its child <source>
        const mediaElement = element as HTMLMediaElement;
        const sources = mediaElement.querySelectorAll('source');
        sources.forEach(source => {
          const srcValue = source.getAttribute('src');
          if (srcValue) {
            const newSrcValue = replaceS3Domains(srcValue);
            if (srcValue !== newSrcValue) {
              this.renderer.setAttribute(source, 'src', newSrcValue);
            }
          }
        });
        mediaElement.load();  // Reload the media element to reflect changes
      }
    });
  }
}
