<div class="bc-search-dropdown">
    <mat-form-field class="autocomplete-form-field" floatLabel="never">
        <mat-label>{{placeholder}}</mat-label>
        <input type="text" matInput [formControl]="autoCompleteFC" [matAutocomplete]="auto" autoSizeInput
            [minWidth]="200" [maxWidth]="620">
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="itemDisplayFn" panelWidth="fit-content"
            (optionSelected)="selectItem($event)" (opened)="searchPopupOpened()" class="autocomplete-dropdown"
            classList="autocomplete-dropdown">
            <mat-option *ngFor="let item of filteredItems | async" [value]="item">
                {{itemDisplayFn(item)}}
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
</div>