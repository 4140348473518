import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import * as Diff from 'diff';
import { ElementType, IContentElement } from '../../ui-testrunner/models';
import { AuthScopeSetting, AuthScopeSettingsService } from '../auth-scope-settings.service';
import { EditingDisabledService } from '../editing-disabled.service';
import { ItemBankSaveLoadCtrl } from '../item-set-editor/controllers/save-load';
import { EditType, ItemComponentEditService } from '../item-component-edit.service';
import { getElementChildren } from '../item-set-editor/models';
import { TextDiffService } from '../text-diff.service';
import { scoreMatrixElementTypes } from "./../config-score-matrix/models"


interface EditField {
  prop: string,
  content: any,
  timestamp?: any,
  path?: string[],
  textDiff?: any[]
}
@Component({
  selector: 'element-config',
  templateUrl: './element-config.component.html',
  styleUrls: ['./element-config.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ElementConfigComponent implements OnInit, OnDestroy {

  @Input() contentElement: IContentElement;
  @Input() isEditing: boolean;
  @Input() parentEditType: EditType;

  @Output() scoredMatrixConfirmed = new EventEmitter<boolean>();

  editType: EditType = EditType.NONE;
  inheritedEditType: boolean = false;
  timestamp: string;
  ElementType = ElementType;
  fieldSelected;
  isAdded = false;
  editStatus = false;
  displayEditFields:EditField[] = [];
  subElements = [];

  EditType = EditType;
  refreshAllChangesSub;
  scoreMatrixElementTypes = scoreMatrixElementTypes;

  constructor(public editingDisabled: EditingDisabledService,
    private itemComponentEditService: ItemComponentEditService,
    private textDiffService: TextDiffService,
    private authScopeSettings: AuthScopeSettingsService) { }


  ngOnInit() {
    if (this.isEditing) {
      this.editingInit();
    }
  }

  ngOnDestroy() {
    if(this.refreshAllChangesSub) {
      this.refreshAllChangesSub.unsubscribe();
    }
  }

  editingInit() {

    this.refreshAllChangesSub = this.itemComponentEditService.refreshAllChangesSub.subscribe(() => {
      this.refreshChanges();
    })
    this.refreshChanges();
    this.getSubElements();
  }

  getBorder() {
    return `2px solid ${this.getBorderColour()}`
  }

  getBorderColour() {
    switch(this.editType) {
      case EditType.ADDED:
        return '#71CE69'
      case EditType.DELETED:
        return '#cc0000'
      case EditType.EDITED:
        return '#FF9900'
      case EditType.NONE:
        if(this.itemComponentEditService.usingEditingMode() && this.displayEditFields?.length) {
          return '#000000';
        }
      default:
        return '#DCDCDC'
    }
  }

  getSubElements() {
    this.subElements = getElementChildren(this.contentElement);
  }

  select() {
    this.itemComponentEditService.selectedEntry = {
      id: this.contentElement.entryId,
      border: this.getBorder()
    }
  }

  isSelected() {
    return this.itemComponentEditService.selectedEntry?.id && this.itemComponentEditService.selectedEntry?.id === this.contentElement.entryId;
  }

  deselect() {
    this.itemComponentEditService.selectedEntry = null;
  }

  toggleSelect() {
    if(this.isSelected()) {
      this.deselect();
    } else {
      if (this.hasChanges()) {
        this.select();
      }
    }

    if(this.usingEditingMode()) {
      this.itemComponentEditService.refreshDiffSub.next();
      this.itemComponentEditService.refreshAllChangesSub.next();
    }
  }

  onClickValue(field) {
    if (this.hasChanges() && this.editType === EditType.EDITED) {
      this.editStatus = !this.editStatus;
      this.fieldSelected = field;
    }

    this.select();
  }

  // Accepts the diff by calling the correct service method
  onClickAccept() {

    if (this.editType === EditType.ADDED) {
      this.itemComponentEditService.acceptNewEl(this.contentElement);
    } else if (this.editType === EditType.DELETED) {
      this.itemComponentEditService.acceptDeleteEl(this.contentElement);
    } else {
      this.itemComponentEditService.acceptDiff(this.contentElement.entryId, this.fieldSelected)
      const newFilter = this.displayEditFields.filter((field) => field[0] !== this.fieldSelected);
      this.displayEditFields = newFilter;

    }

    // Reset editing highlight
    this.editStatus = !this.editStatus;
    this.deselect();
    this.fieldSelected = "";
    this.refreshChanges();
  }

  // Rejects  the diff by calling the correct service method
  onClickReject() {

    if (this.editType === EditType.ADDED) {
      this.itemComponentEditService.rejectNewEl(this.contentElement);
    } else if (this.editType === EditType.DELETED) {
      this.itemComponentEditService.rejectDeleteEl(this.contentElement);
    } else {
      this.itemComponentEditService.rejectDiff(this.contentElement, this.fieldSelected)
      const newFilter = this.displayEditFields.filter((field) => field[0] !== this.fieldSelected);
      this.displayEditFields = newFilter;
    }

    // Reset editing highlight
    this.editStatus = !this.editStatus;
    this.deselect();
    this.fieldSelected = "";
    this.refreshChanges();
  }

  isTextDiffProp(prop) {
    return this.itemComponentEditService.isTextDiffProp(prop, this.contentElement);
  }

  hasChanges() {
    if(this.itemComponentEditService.usingEditingMode()) {
      return this.displayEditFields?.length;
    }
    return this.editType !== EditType.NONE;
  }

  // Edit Type Checker
  // Checks for edits and sets the edit type for this instance of an edit
  refreshEditType() {
    this.inheritedEditType = false;
    if(this.parentEditType === EditType.ADDED || this.parentEditType === EditType.DELETED) {
      this.editType = this.parentEditType;
      this.inheritedEditType = true;
      return;
    }
  }

  // Styling functions
  // Adds styling based on the type of the edit
  getFieldColor() {
    return this.getBorderColour();
  }

  getValueColor() {
    if (this.editType === EditType.ADDED) {
      return  "#71CE69";
    } else if (this.editType === EditType.DELETED) {
      return  "#cc0000";
    } else {
      return  "transparent";
    }    
  }

  refreshChanges() {
    this.fieldChange();
    this.refreshEditType();
    this.updateChangeCounter(); //Proper rendering of some element types (e.g. grouping) requires a changeCounter increment from the config. Do it here 
  }

  updateChangeCounter() {
    if (!this.contentElement._changeCounter) {
      this.contentElement._changeCounter = 0;
    }
    this.contentElement._changeCounter ++;
  }

  getAllTextFields() {
    return Object.keys(this.contentElement).filter((prop) => {
      return this.isTextDiffProp(prop);
    }).map( prop => { 
      return {
        prop, 
        content: this.contentElement[prop]
      }});
  }

  getAllViewableFields() {
    return Object.keys(this.contentElement).filter((prop) => {
      return this.isViewableField(prop, this.contentElement);
    }).map( prop => {
      return {
        prop, 
        content: this.contentElement[prop]
      }
    });
  }

  processTextDiff(d, displayEditField) {
    // Map the changed text to the before text so we can use it later
    // Also each displayed text diff will have an index count for accepting and rejecting text diffs
    let textDiffs = [];
    if (d.rhs !== undefined && d.lhs !== undefined) {
      textDiffs =  Diff.diffWords(d.lhs, d.rhs);
    }
  
    // Add indices to each text diff
    let indexPtr = 0;
    textDiffs = textDiffs.map((diff, index) => {
      const newDiff = {...diff, index: indexPtr}
      if (!(index !== textDiffs.length - 1 && textDiffs[index + 1].added && diff.removed)) {
        indexPtr += diff.value.length;
      } 
      return newDiff;
    });

    // Filter out diffs that are removing text and being replaced with text
    // leaving only displayed diffs
    const textDisplayedDiffs = [];
    textDiffs.forEach((diff, index) => {
      if (index !== textDiffs.length - 1 && textDiffs[index + 1].added && diff.removed) {
        this.textDiffService.mapTextDiff(textDiffs[index + 1], diff);
      } else {
        if (diff.value === " ") {
          diff.value = "\u0020";
        }       
        textDisplayedDiffs.push(diff);
      }
    });


    const prop = this.getTextPropFromDiff(d);

    if(!displayEditField) {
      displayEditField = this.displayEditFields.filter((f) => f.prop === prop)[0];
    }
    displayEditField.textDiff = textDisplayedDiffs;
    displayEditField.path = d.path;
  }

  getTextPropFromDiff(d) {
    if(Array.isArray(d.path)) {
      return d.path[d.path.length - 1];
    } else {
      return d.path;
    }
  }

  genAuthorTextFromDiff(d) {
    return `by ${d.author}, ${d.dateEdited}`
  }
  // Get Fields to Display

  // If it is a caption/content field then it will use diffWords to generate textDiff (with indices for each change)
  // Then the resulting textDiffs are sent to a textDiff component through a variable

  // For any other field, if it is an edit then it will be put in fieldsChanged with the value it changed to and the timestamp
  // if it is an addition or deletion, I will add all displayable fields to the fieldsChanged array
  fieldChange() {
    this.editType = EditType.NONE;
    if(this.itemComponentEditService.usingEditingMode()) {
      this.displayEditFields = this.getAllTextFields();

      const diffs = this.itemComponentEditService.getDiffs(this.contentElement.entryId);
      for(const field of this.displayEditFields) {
        let relevantDiffs = diffs.filter( d => d.path[d.path.length-1] === field.prop);
        if(!relevantDiffs?.length) {
          //Include a dummy deep-diff object for each field we want to include that has no difference
          relevantDiffs = [{
            kind: 'E',
            path: [field.prop], //path doesn't need to be accurate since it is just used for accepting/rejecting which we do not do in editing mode
            lhs: field.content,
            rhs: field.content
          }];
        } else {
          field.timestamp = this.genAuthorTextFromDiff(relevantDiffs[0]);
          this.editType = EditType.EDITED;
        }
        this.processTextDiff(relevantDiffs[0], field);
      }

      return;
    }

    if(this.parentEditType === EditType.ADDED || this.parentEditType === EditType.DELETED) {
      this.displayEditFields = this.getAllViewableFields();
      return;
    }
    this.displayEditFields = []
    
    for (let d of this.itemComponentEditService.getDiffs(this.contentElement.entryId)) {
      // Get the latest value from the edit
      let previewChange = d.rhs || d.rhs === false ? d.rhs.toString() : "";

      let displayEditField;
      
      const authorText = this.genAuthorTextFromDiff(d);
      // Add the displayable field edits to the array
      if (d.kind === "A") {
        // Add all the fields in the new element and filtering out properties that are not needed
        this.displayEditFields = [...this.displayEditFields, ...this.getAllViewableFields()];
        if (d.kind === "A" && d.item.kind === "N") {
          this.editType = EditType.ADDED;
        } else if (d.kind === "A" && d.item.kind === "D") {
          this.editType = EditType.DELETED;
        }
        this.timestamp = authorText;
      } else if (d.kind !== "A") {
        if (this.isViewableField(d.path[d.path.length-1], this.contentElement)) {
          displayEditField = {
            prop: d.path[d.path.length-1], 
            content: previewChange,
            timestamp: authorText};
          this.displayEditFields.push(displayEditField);
            this.editType = EditType.EDITED; //Only set edit type to edited if it was viewable
        }
      }

      if (this.isTextDiffProp(d.path[d.path.length-1])) { //needs to come after modification of displayeditfields
        this.processTextDiff(d, displayEditField);
      }
    }
  }

  // Util

  // Determines if the field is viewable on the frontend
  isViewableField(field, element:IContentElement) {
    return this.itemComponentEditService.isViewableField(field, element);
  }

  isViewableElement(element: IContentElement) {
    return this.itemComponentEditService.isViewableElement(element);
  }

  getElementTypeDisplay() {
    const hiddenElementTypes = [ElementType.TEXT, ElementType.TABLE_TEXT, ElementType.TEXT_PARAGRAPH];
    if(this.itemComponentEditService.usingEditingMode() && hiddenElementTypes.includes(<any>this.contentElement.elementType)) {
      return "";
    }

    return this.contentElement.elementType !== "" ? this.contentElement.elementType.toUpperCase() : "BLANK"
  }

  usingEditingMode() {
    return this.itemComponentEditService.usingEditingMode();
  }

  showTextDiffForProp(prop) {
    return this.isTextDiffProp(prop) && (this.editType !== EditType.ADDED && this.editType !== EditType.DELETED)
  }
}
