export const replaceS3Domains = (url: string): string => {
    if (!url || typeof url !== "string") {
        return url
    }

    const domainsList = {
        'authoring.mathproficiencytest.ca': 'authoring',
        'd3azfb2wuqle4e.cloudfront.net': 'authoring',
        'storage.mathproficiencytest.ca': 'storage',
        'd3c1mbjtikq6ue.cloudfront.net': 'storage'
    }
    const replacementDomain = 'https://eqao.vretta.com';
    
    let newUrl = url;
    
    Object.keys(domainsList).forEach(domain => {
        if(url.includes(domain)){
            const urlSegArr = url.split('/')
            const subdirectoryIdx = urlSegArr.findIndex(seg => seg.includes(domain)) + 1
            if(subdirectoryIdx){
                newUrl = [replacementDomain, domainsList[domain]].concat(urlSegArr.slice(subdirectoryIdx)).join('/')
            }
        }
    });

    return newUrl;
}