import { Component, Input, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { AuthService } from '../../api/auth.service';
import { DataGuardService } from '../../core/data-guard.service';
import { getElementWeight, QuestionState, ScoringTypes } from '../models';
import { QuestionPubSub } from '../question-runner/pubsub/question-pubsub';
import { IContentElementMic, IEntryStateMic } from './model';


try {
  const audioContext =  new (window.AudioContext || window['webkitAudioContext'])();
} catch(err){
  console.log(err);
}
// const audioContext =  new (window.AudioContext || window['webkitAudioContext'])();  
const SCORING_TYPE = ScoringTypes.MANUAL;
declare var MediaRecorder: any;
declare var WaveSurfer: any;

export const timeLeadingZero = (num:number) => {
  let str = ''+num;
  if (!str){
    str = '00';
  }
  if (str.length < 2){
    str = '0'+str;
  }
  return str.substr(0, 2)
}
@Component({
  selector: 'element-render-mic',
  templateUrl: './element-render-mic.component.html',
  styleUrls: ['./element-render-mic.component.scss']
})
export class ElementRenderMicComponent implements OnInit, OnDestroy {

  @Input() element:IContentElementMic;
  @Input() isLocked:boolean;
  @Input() isShowSolution:boolean;
  @Input() questionState:QuestionState;
  @Input() changeCounter:number;
  @Input() isCountdownEnabled:number;
  @Input() questionPubSub?: QuestionPubSub;

  
  constructor(
    private sanitizer: DomSanitizer,
    private auth: AuthService,
    private dataGuard: DataGuardService,
  ) { }
  
  capture:Partial<IEntryStateMic> = {};
  time:number;
  isRecordingSim:boolean;
  isRecording:boolean;
  
  timer;
  private wavesurfer:any;
  
  warningForAuto = {
    url: 'https://eqao.vretta.com/authoring/user_uploads/21/authoring/beep/1613053268449/beep.mp3',
  }
  standardBeep = {
    url: 'https://eqao.vretta.com/authoring/user_uploads/21/authoring/beep/1613495776328/beep.mp3',
  }
  msg = 'Prêt';
  hasMicStarted:boolean;
  hasRecordingStored:boolean;
  private mediaRecorder:any;
  currentRecordingTime: string;
  audioUrl: SafeResourceUrl;
  recordingStart:number;
  recordingTimerInterval:any;
  isCountdownInProgress:boolean;
  audioTrigger = new Subject();
  warningForAutoTrigger = new Subject();
  standardBeepTrigger = new Subject();
  isDeviceError:boolean;
  isRecorded: boolean;
  isTransferred: boolean;
  isFinalCountdown: boolean;


  ngOnInit() {
    this.ensureState(false, true);
    this.resetTimer();
    this.iPadCheck();
  }

  ngOnDestroy(){
    if (this.wavesurfer && this.wavesurfer.microphone) {
      this.wavesurfer.microphone.destroy();
    }
    try { clearInterval(this.recordingTimerInterval) }catch(e){}
    try { clearInterval(this.timer) }catch(e){}
  }

  initWaveForm(){
    const WaveSurfer = window['WaveSurfer'];
    if (!WaveSurfer.microphone){
      WaveSurfer.microphone = window['Microphone']; 
    }
    this.wavesurfer = WaveSurfer.create({
      container     : '#waveform',
      waveColor     : 'black',
      height        : 40,
      barHeight     : 3,
      interact      : false,
      cursorWidth   : 0,
      hideScrollbar : true,
      plugins: [
        WaveSurfer.microphone.create()
      ]
    }); 
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.changeCounter){
      this.resetTimer();
    }
  }

  stopRec(){
    if (this.isRecorded || !this.isRecording){
      return;
    }
    this.isRecording = false;
    this.isRecordingSim = false;
    this.isRecorded = true;
    this.hasRecordingStored = false;
    this.msg = 'Terminé';
    if (this.isChrome()){
      this.stopRecChrome()
    }
    else{
      this.stopRecSimple()
      // this.stopRecOther()
    }
    
    if (this.wavesurfer && this.wavesurfer.microphone){
      this.wavesurfer.microphone.pause();
    }
  }

  stopRecChrome(){
    this.mediaRecorder.stop();
  }
  stopRecSimple(){
    this.triggerStopRec.next(true)
  }
  stopRecOther(){
    console.log("stopButton clicked");

    //tell the recorder to stop the recording
    this.recorder.stop();

    //stop microphone access
    this.gumStream.getAudioTracks()[0].stop();

    //create the wav blob and pass it on to createDownloadLink
    this.recorder.exportWAV(blob => this.onRecorded(blob));
  }

  onRecorded(blob) {
    const url = (window.URL || window.webkitURL).createObjectURL(blob);
    this.capture.url = url;
    this.updateAudioUrl();
    this.isUploading = false;
    this.upgradeToRemoteLink(url)
    return;
  }

  upgradeToRemoteLink(wavDataUri:string){
    console.log('upgradeToRemoteLink', wavDataUri)
    var processorUrl = "https://wkowskfxl8.execute-api.ca-central-1.amazonaws.com/transcodeToMp3";
    var xhr = new XMLHttpRequest();
    xhr.open("POST", processorUrl);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.onreadystatechange =  () => {
       if (xhr.readyState === 4) {
          console.log(xhr.status);
          console.log(xhr.responseText);
          this.capture['urlBackup'] = xhr.responseText;
          this.updateAudioUrl();
       }};
    const filename = 'rec'+(new Date()).valueOf()+Math.random()+'.wav';
    var data = JSON.stringify({filename, wavDataUri})
    xhr.send(data);
  }

  iPadCheck(){
    const userAgent = window.navigator.userAgent;
    if (userAgent.match(/iPad/i) && 
        userAgent.indexOf("Safari") === -1
    ){
      alert('Le navigateur que vous utilisez ne soutien pas la possibilité d\'enregistrer la voix. Veuillez utilisé Safari afin de compléter cette composante de l\'évaluation.')
    }
  }
  isChrome(){
    const userAgent = window.navigator.userAgent;
    return (userAgent.indexOf("Chrome") > -1 ||
           userAgent.indexOf("Edg") > -1) && // e is left out intentionally, this is how MSFt signals newer version
           userAgent.indexOf("Edge") === -1 // do not treat older versions of Edge like Chrome
  }
  
  isCountdownEarlyEnd:boolean;
  startRec(isAuto?:boolean){
    if (this.isFinalCountdown){
      return;
    }
    if (this.isCountdownInProgress){
      // this.isCountdownEarlyEnd = true;
      this.onCountdownTimerEnd();
    }
    if (this.isRecorded || this.isTransferred){
      return;
    }
    if (!this.isRecordingSim){
      this.standardBeepTrigger.next(true);
      let isConfirmed = true;
      this.capture.isStarted = true;
      this.capture.isResponded = true;
      if (this.hasRecordingStored){
        isConfirmed = confirm('Cela effacera votre enregistrement précédent pour cette question et en commencera un nouveau. Continuer?')
      }
      if (isConfirmed){
        this.isRecordingSim = true;
        this.startRecording();
        
      }
    }
  }

  isSaving:boolean;
  saveRec(){
    if (this.isTransferred || this.isSaving){
      return;
    }
    this.isSaving = true;
    this.dataGuard.triggerForceSave();
    setTimeout(()=>{
      this.confirmUpload();
      this.isSaving = false;
    }, 5000)
  }

  updateCurrentRecordingTime(){
    if (this.isRecordingSim){
      const now = (new Date()).valueOf();
      const seconds = Math.floor((now - this.recordingStart)/1000);
      const minutesRaw = seconds/60;
      const minutes = Math.floor(minutesRaw);
      const secondsDisplayed = Math.floor(seconds - minutes*60);
      this.currentRecordingTime = timeLeadingZero(minutes)+':'+timeLeadingZero(secondsDisplayed);
      if (this.element.maxDuration && minutesRaw >= this.element.maxDuration){
        this.stopRec();
      }
    }
  }

  isWarningPlayed:boolean
  resetTimer() {
    const timerState = this.capture.time > 0 ? this.capture.time : 0
    this.time = timerState ? timerState : this.element.time;
    // console.log('resetTimer', this.capture)
    if (this.capture.url || this.capture.isFilled){
      this.isCountdownInProgress = false;
      return;
    }
    if (this.timer) {
      clearInterval(this.timer)
    }
    if (!this.element.setTimer) return;
    this.isCountdownInProgress = true;
    this.timer = setInterval(
      () => {
        if (!this.isCountdownInProgress){
          return;
        }
        if (this.time>0) {
          this.time--
        };
        if (!this.isWarningPlayed && Math.floor(this.time) < 11) {
          this.isFinalCountdown = true;
          this.warningForAutoTrigger.next(true);
          this.isWarningPlayed = true;
        }
        if (Math.floor(this.time) === 5) {
          const el = document.getElementById('microphone');
          el.scrollIntoView({behavior:'smooth'})
        }
        this.capture.time = this.time
        if (this.time<=0) {
          this.capture.time = 0
          this.onCountdownTimerEnd();
          setTimeout(()=>{
            this.startRec(true);
          }, 1000);
        } 
      },
      1000
    );
  }

  onCountdownTimerEnd(isFastForward?:boolean){
    if (this.isCountdownInProgress || isFastForward){
      this.isFinalCountdown = false;
      this.isCountdownInProgress = false;
      if (this.element.onCountdownEnd){
        this.element.onCountdownEnd.forEach(pub => {
          this.questionPubSub.elementPub(+pub.entryId, pub.type, pub.data);
        })
      }
    }
  }

  playRecording(){
    this.msg = 'Écoute...';
    this.audioTrigger.next(true);
  }

  startRecording = () => {
    if (this.isRecording){
      return;
    }
    this.isRecording = true;
    if (this.isChrome()){
      this.startRecordingChrome();
    }
    else{
      this.startRecordingSimple();
    }
    // this.notifyRecordingStart.emit();
  };  
  
  startRecordingChrome(){
    // if (!this.isRecorderInited){
      navigator
        .mediaDevices
        .getUserMedia({ audio: true, video: false })
        .then(this.startStreamChrome)
        .catch(e =>{
          //enable the record button if getUserMedia() fails
           this.onDeviceError(true)
          })
    // }
  }

  //stream from getUserMedia()
  gumStream = null;

  //MediaStreamAudioSourceNode we'll be recording	
  input = null;

  //audio context to help us record
  audioContext = null

  //Recorder.js object				
  recorder: any;


  isRecorderInitializing:boolean;


  triggerStartRec:Subject<boolean> = new Subject();
  triggerStopRec: Subject<boolean> = new Subject();
  startRecordingSimple(){
    this.isRecording = true;
    this.isRecordingSim = true;
    // this.isRecorderInitializing = true;
    this.triggerStartRec.next(true);
    this.isRecorderInitializing = true;
    setTimeout(()=>{
      this.isRecorderInitializing = false;
      this.trackRecordingStart();
    }, 1000)
    
  }

  startRecordingOther(){
    this.isRecording = false;
    this.isRecordingSim = false;
    this.isRecorderInitializing = true;
    const constraints = { audio: true, video: false }

    
    navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
      this.isRecording = true;
      this.isRecordingSim = true;

      this.trackRecordingStart();

      // console.log("getUserMedia() success, stream created, initializing Recorder.js ...");

      this.onDeviceError(false);
      // create an audio context after getUserMedia is called
      // sampleRate might change after getUserMedia is called, like it does on macOS when recording through AirPods
      // the sampleRate defaults to the one set in your OS for your playback device
      this.audioContext = new (window.AudioContext || window['webkitAudioContext'])();

      // assign to gumStream for later use 
      this.gumStream = stream;

      // use the stream
      this.input = this.audioContext.createMediaStreamSource(stream);

      // Create the Recorder object and configure to record mono sound (1 channel)
      // Recording 2 channels will double the file size
      this.recorder = new window['Recorder'](this.input, { numChannels: 1 })

      // start the recording process
      this.recorder.record()

      setTimeout(()=>{ this.isRecorderInitializing = false; }, 800)

      console.log("Recording started");
      this.isRecorderInited = true

      // this.initWaveForm();
      // this.wavesurfer.microphone.start();

    }).catch((err) => {
      //enable the record button if getUserMedia() fails
      this.onDeviceError(true)
    });
  }
  
  isUploadErrored:boolean = false
  confirmUpload(){
    if (this.capture && !this.isUploading){
      if (this.capture.url){
        this.capture.isFilled = true;
        this.isTransferred = true;
        alert('Votre enregistrement a été envoyé.');
        // reset
      }
    }
    else{
      // alert('ERREUR !')
      this.isUploadErrored = true
    }
  }

  resetRecording(){
    if (this.element.isPractice){
      this.isRecorded = false;
      this.hasRecordingStored = false;
      this.isTransferred = false;
      this.ensureState();
      if (this.wavesurfer && this.wavesurfer.microphone) {
        this.wavesurfer.microphone.destroy();
      }
      this.isRecorderInited = false;
    }
  }

  onDeviceError(reset){
    if(!reset) {
      this.isDeviceError = false
      return;
    }
    this.isDeviceError = true
    this.isRecording = false;
    this.isRecordingSim = false;
    this.capture.isStarted = false;
  }


  ensureState(forceReset:boolean=false, isFirst?:boolean){
    if (this.questionState){
      // console.log('mic entry id', this.element.entryId)
      const entryId = this.element.entryId;
      if (!this.questionState[entryId] || forceReset){
        let entryState:IEntryStateMic = {
          type: 'mic',
          isCorrect: null,
          isStarted: false,
          isFilled: false,
          isResponded: false,
          url: '',
          time: this.time,
          score: 0,
          weight: getElementWeight(this.element),
          scoring_type: SCORING_TYPE, 
        }
        this.questionState[entryId] = entryState;
      }
      this.capture = this.questionState[entryId];
      if (isFirst && this.capture.url){
        this.isRecorded = true;
        this.isTransferred = true;
        this.capture.isFilled = true;
        this.capture.isResponded = true;
        this.onCountdownTimerEnd(true);
      }
    }
  }



  voiceCaptureStarted() {
    this.isRecording = true;
    clearInterval(this.timer)
    this.time = 0;
    this.capture.isResponded = true;
  }

  updateAudioUrl(){
    if (this.element && this.capture.url){
      this.audioUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.capture.url);
    }
    else{
      this.audioUrl = null;
    }
    // this.urlUpdate.emit({url: this.element.url});
  }

  onUrlGenerated(url:string){
    this.capture.url = url;
  }

  trackRecordingStart(){
    this.recordingStart = (new Date()).valueOf()
    this.msg = 'Enregistrement';
    this.updateCurrentRecordingTime();
    this.recordingTimerInterval = setInterval(() => {
      this.updateCurrentRecordingTime();
    }, 500)
  }


  isUploading:boolean;
  audioSource:any;
  isRecorderInited:boolean;
  startStreamChrome = (stream:MediaStream) => {
    this.onDeviceError(false);
    // console.log('startStream', stream)
    
    if (!this.isRecorderInited){
      this.initWaveForm();
      this.wavesurfer.microphone.start();
      this.isRecorderInited = true;
    }
    this.wavesurfer.microphone.play();
    
    const options = {mimeType: 'audio/webm'};
    const recordedChunks = [];
    if (this.mediaRecorder){
      this.mediaRecorder.stop;
    }
    this.mediaRecorder = new MediaRecorder(stream, options);
    this.mediaRecorder.addEventListener('dataavailable', (e) => {
      if (e.data.size > 0) {
        recordedChunks.push(e.data);
      }
    });
    this.mediaRecorder.addEventListener('stop', () => {
      this.isUploading = true;
      const blob = new Blob(recordedChunks, { 'type' : 'audio/webm' });
      // locally store
      const reader = new FileReader();
      reader.onload = (e) => {
          const srcUrl = <string> e.target.result;
          this.capture.url = srcUrl;
          this.updateAudioUrl();
          this.isUploading = false;
      };
      reader.readAsDataURL(blob);
    });
    this.mediaRecorder.start();

    this.trackRecordingStart();

    this.isRecorderInited = true;

  };


  startStreamOther = (stream:MediaStream) => {
    this.onDeviceError(false);
    this.isRecorderInited = true;
    // console.log('startStream', stream)

    this.recorder.init(stream).then(()=>{
      // console.log('recorder inited')
      this.recorder.start()
        .then(()=> {} )
        .catch(()=> {
          this.isDeviceError = false;
          console.error('failed to start recording') 
        })
      this.initWaveForm();
      this.wavesurfer.microphone.start();
      this.trackRecordingStart();
    })
    .catch(e => {
      console.error('Failed to init recording', e)
    });
  };

  isShowAudioInfoPanel = () => {
    return (this.isRecordingSim || this.isRecorded) && (!this.isTransferred || this.element.isPractice) && !this.isDeviceError
  }

  isInitialState = () => {
    return !this.isRecordingSim && !this.isRecorded
  }

  isRecordingState = () => {
    return this.isRecordingSim
  }

  getSavingState = () => {
    if ((!this.isSaving && !this.isTransferred) || this.isUploadErrored) return "save"
    if (this.isSaving) return "saving"
    if (!this.isSaving && this.isTransferred) return "uploaded"
  }

  isTimeDisplayed = () => {
    return !this.isResetButtonShown() && (this.isRecordingSim || this.isRecorded) && !this.isTransferred
  }

  isResetButtonShown = () => {
    return this.element.isPractice && this.isRecorded
  }

  progressBarWidthEm = 10;
  progressBarHeightEm = 1;
  borderThicknessEm = 0.1;
  barColor = "#3298DC"
  getProgressBarFillEm() {
    const style = {};
    const fillWidth = (this.progressBarWidthEm - this.borderThicknessEm*2)
    const fillHeight = (this.progressBarHeightEm - this.borderThicknessEm*2)
    style["height"] = fillHeight + "em"
    style["width"] = fillWidth*this.time/this.element.time + "em"
    style["background-color"]=this.barColor
    return style;
  }

  getProgressBarBorder() {
    const style = {};
    style["height"] = this.progressBarHeightEm +"em";
    style["width"] = this.progressBarWidthEm +"em";
    style["border"] = this.borderThicknessEm +"em";
    style["border-style"]="solid";
    style["border-color"]=this.barColor
    return style;
  }

}
