<div class="layout-bg">
    <div class="layout-container">
        <div class="header-container">
            <img src="https://eqao.vretta.com/authoring/user_uploads/6263/authoring/HELIX/1594929184731/HELIX.png">
            <img src="https://eqao.vretta.com/authoring/user_uploads/6263/authoring/ENS/1594929007313/ENS.png">
            <hr class="solid">
        </div>

        <div style="display:flex; flex-direction:column; align-items:center">
            <div class="topics-layout-container" *ngIf="categoriesTop && categoriesTop.length>0">
                <div class="strongest-header topics-header">Your Strongest Topics</div>
                <div class="strongest-body topics-body">
                    <ul class="topics-list">
                        <li *ngFor="let topic of categoriesTop"><i class="fas fa-check-square" style="color:#60a5d8"></i> {{topic.name}}</li>
                    </ul>
                </div>
            </div>
            
            <div class="topics-layout-container" *ngIf="categoriesBott && categoriesBott.length>0">
                <div class="weakest-header topics-header">Areas For Improvement</div>
                <div class="weakest-body topics-body">
                    <ul class="topics-list">
                        <li *ngFor="let topic of categoriesBott"><i class="fas fa-exclamation-triangle" style="color:#f5bb59"></i> {{topic.name}}</li>
                    </ul>
                </div>
            </div>
            <div class="button-continue-container"><button class="button-continue" (click)="diagnosticContinue.emit()"><span>CONTINUE</span></button></div>
        </div>
        <div class="logo-container"><img class="logo" src="https://eqao.vretta.com/authoring/user_uploads/21/authoring/vretta-logo/1596159474108/vretta-logo.png"></div>
    </div>
</div>
