import { Component, Input, OnInit } from '@angular/core';
import { coinFlip } from '../../ui-testadmin/demo-data.service';
import * as moment from 'moment-timezone';
import { LangService } from "../../core/lang.service";
import {ISession, SessionModal, SessionStatus} from "../../ui-schooladmin/data/types";
import { MemDataPaginated } from "../../ui-partial/paginator/helpers/mem-data-paginated";
import { LoginGuardService } from '../../api/login-guard.service';
import { ListSelectService } from '../../ui-partial/list-select.service';
import {PageModalController, PageModalService} from "../../ui-partial/page-modal.service";
import { AuthService } from '../../api/auth.service';
import { MyBoardService } from '../my-board.service';
import { RoutesService } from '../../api/routes.service';
import { ClassFilterId } from '../../ui-schooladmin/my-school.service';
import {FilterSettingMode} from "../../ui-partial/capture-filter-range/capture-filter-range.component";
import { downloadFromExportData, IExportColumn } from 'src/app/ui-testctrl/tc-table-common/tc-table-common.component';
import { APIBoardSessionColumnExportList } from './export/config';

@Component({
  selector: 'sb-sessions',
  templateUrl: './sb-sessions.component.html',
  styleUrls: ['./sb-sessions.component.scss']
})
export class SbSessionsComponent implements OnInit {
  @Input() boardInfo;
  @Input() account_type;

  constructor(
    public lang: LangService,
    public loginGuard: LoginGuardService,
    private listSelectService:ListSelectService,
    private pageModalService: PageModalService,
    private auth: AuthService,
    public myBoard: MyBoardService,
    private routes: RoutesService,
  ) { }

  public sessionsTable: MemDataPaginated<ISession>;
  public sessions: ISession[] = [];
  public isAllSelected = false;
  public isAnySelected = false;
  public isLoaded: boolean;
  public isInited: boolean;
  public columnLabels;
  public columnStatusLabel;
  private initalSessionStatus = SessionStatus.PENDING; 
  pageModal:PageModalController;
  SessionModal = SessionModal;
  ClassFilterId = ClassFilterId;
  classFilterToggles

  ngOnInit() {
    this.initRouteView();
    this.pageModal = this.pageModalService.defineNewPageModal();
    this.classFilterToggles = this.myBoard.classFilterToggles
    if(this.account_type){
      switch(this.account_type){
        case 'school_district_curr':
          break
        case 'schl_disct_curr_ele':
          this.classFilterToggles = this.classFilterToggles.filter( cft => cft.id == ClassFilterId.Primary || cft.id == ClassFilterId.Junior)
          break
        case 'schl_disct_curr_sec':
          this.classFilterToggles = this.classFilterToggles.filter( cft => cft.id == ClassFilterId.G9 || cft.id == ClassFilterId.OSSLT)
          break    
        case 'None':
        default:
          this.classFilterToggles = [];
          break; 
      }
    }  
  }

  initRouteView() {
    this.columnLabels = {
      id: this.lang.tra('sa_sessions_col_id'),
      school: this.lang.tra('lbl_school'),
      invigilators: this.lang.tra('sa_sessions_col_invig'),
      classroom: this.lang.tra('sa_sessions_col_class'),
      description: this.lang.tra('sa_sessions_col_desc'),
      students: this.lang.tra('sa_sessions_col_stud'),
      times: this.lang.tra('sa_sessions_col_times'),
      status: this.lang.tra('sa_sessions_col_stat'),
      submissions: this.lang.tra('lbl_submissions')
    };
    this.columnStatusLabel = {
      [SessionStatus.PENDING]: this.lang.tra('sa_sessions_status_pend'),
      [SessionStatus.ACTIVE]: this.lang.tra('lbl_active'),
      // [SessionStatus.COMPLETED]: this.lang.tra('sa_sessions_status_comp')
    };
    
    
    if (!this.isInited) {
      this.isInited = true;
      this.loadSessions();
    }
  }

  currentClassFilter:ClassFilterId;
  setClassFilter(filterId){
    this.currentClassFilter = filterId;
    if (this.currentClassFilter && this.sessionsTable) {
      this.sessionsTable.activeFilters['slug'] = null;
      if(this.currentClassFilter === ClassFilterId.Primary) {
        this.sessionsTable.activeFilters['slug'] = { mode: FilterSettingMode.VALUE, config: { value: ClassFilterId.Primary } }
      }
      else if (this.currentClassFilter === ClassFilterId.Junior) {
        this.sessionsTable.activeFilters['slug'] = { mode: FilterSettingMode.VALUE, config: { value: ClassFilterId.Junior } }
      }
      else if(this.currentClassFilter === ClassFilterId.G9) {
        this.sessionsTable.activeFilters['slug'] = { mode: FilterSettingMode.VALUE, config: { value: ClassFilterId.G9 } }
      }
      else if (this.currentClassFilter === ClassFilterId.OSSLT) {
        this.sessionsTable.activeFilters['slug'] = { mode: FilterSettingMode.VALUE, config: { value: ClassFilterId.OSSLT } }
      }
      this.sessionsTable.refreshFilters();
    }
  }

  toggleSelectAll() {
    this.setSelectAll(this.isAllSelected);
  }
  setSelectAll(state: boolean) {
    this.isAllSelected = state;
    this.sessions.forEach(session => session.__isSelected = state);
    this.isAnySelected = state;
  }
  checkSelection() {
    this.isAnySelected = false;
    this.isAllSelected = false
    this.sessions.forEach(session => {
      if (session.__isSelected) {
        this.isAnySelected = true;
      }
    });
  }
  
  loadSessions() {
    this.myBoard
    .getSessions(this.boardInfo.group_id)
      .then(sessions => {
        sessions.forEach(session => {
           //this.getSubmissionStatus(res, session);
          this.sessions.push({
            id: session.test_session_id,
            school_name:session.school_name,
            invigilator: `${session.first_name} ${session.last_name}`,
            classroom_id: session.school_class_id,
            firstName: session.first_name,
            lastName: session.last_name,
            description: '',
            classCode: session.name,
            students: session.num_students,
            startTime: this.getDate(session.date_time_start),
            endTime: '',
            slug:session.slug,
            status: this.returnSessionStatus(session.date_time_start) ? 'active' : 'pending',
            submissions: session.submissions,
            isConfirmed: coinFlip()
          })
        })
        this.sessionsTable = new MemDataPaginated({
          data: this.sessions,
          pageSize: 5,
          filterSettings: {
            slug: (session: ISession, val: string) => {
              if (this.currentClassFilter === ClassFilterId.Primary) {
                return session.slug.indexOf('PRIMARY_') === 0
              }
              else if (this.currentClassFilter === ClassFilterId.Junior) {
                return session.slug.indexOf('JUNIOR_') === 0
              }
              if (this.currentClassFilter === ClassFilterId.G9) {
                return session.slug.indexOf('G9_') === 0
              }
              else if (this.currentClassFilter === ClassFilterId.OSSLT) {
                return session.slug.indexOf('OSSLT_') === 0
              }
            }
          },
          sortSettings: {}
        });
        if(this.currentClassFilter) {
          this.setClassFilter(this.currentClassFilter);
        }
        this.isLoaded = true;
      })
  }
  getDate(dateTime) {
    const mStart = moment.tz(dateTime, moment.tz.guess());
    let timezone = mStart.zoneAbbr();
    timezone = timezone == 'EST' ? this.lang.tra('est_timezone_label') : this.lang.tra('edt_timezone_label')
    return `${mStart.format(this.lang.tra('datefmt_sentence'))} ${timezone}`
  }
  returnSessionStatus(dateTime) {
    let currDate = new Date();
    let sessionDate = new Date(dateTime)
    if (currDate.getTime() > sessionDate.getTime()) {
      return true;
    }
    return false;
  }

  cModal() { return this.pageModal.getCurrentModal(); }
  cmc() {    return this.cModal().config; }
  
  visibleSessions() {
    return this.sessionsTable.getCurrentPageData();
  }

  exportMonitoringViewModal() {
    const sessions = this.sessionsTable.getFilteredData();
    const columns = this.getSessionExportColumns();
    const exportData: any[] = this.getExportData(sessions, columns);
    downloadFromExportData(exportData, columns, 'sessions', this.auth);
  }

  private getExportData(sessions: Partial<ISession>[], columns: IExportColumn[]): any[] {
    return sessions.map((session): Partial<ISession> => {
      let entry: Partial<ISession> = {};
      columns.forEach(col => {
        const prop = col.prop;
        let val = session[prop];
        if(val == SessionStatus.ACTIVE) {
          val = this.lang.tra('lbl_active');
        }
        if(val == SessionStatus.PENDING) {
          val = this.lang.tra('sa_sessions_status_pend');
        }
        entry[prop] = val;
      });
      return entry;
    });
  }

  private getSessionExportColumns = (): IExportColumn[] => {
    let columns: IExportColumn[] = [];
    let modifiedLabels = {
      status: 'Status',
      school_name: 'SchoolName',
      firstName: 'TeacherFirstName',
      lastName: 'TeacherLastName',
      classCode: this.currentClassFilter === ClassFilterId.G9 ? 'ClassCode' : 'Grouping',
      students: 'Students',
      startTime: 'StartTime',
      submissions: 'Submissions',
    }
    const exportList = [...APIBoardSessionColumnExportList];

    exportList.forEach(source => {
      columns.push({
        prop: source,
        caption: modifiedLabels[source],
        isClickable: false,
      });
    });
    return columns;
  }
}


