import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, getFrontendDomain } from 'src/app/api/auth.service';
import { IMenuTabConfig } from '../../ui-partial/menu-bar/menu-bar.component';
import { LoginGuardService } from 'src/app/api/login-guard.service';
import { RoutesService } from 'src/app/api/routes.service';
import { BreadcrumbsService } from 'src/app/core/breadcrumbs.service';
import { LangService } from 'src/app/core/lang.service';
import { SidepanelService } from 'src/app/core/sidepanel.service';
import { AccountType } from '../../constants/account-types';
import { SchoolBoardView, SCHOOL_BOARD_VIEWS } from './data/views';
import { downloadFromExportData, downloadCSVFromExportData, IExportColumn } from "../../ui-testctrl/tc-table-common/tc-table-common.component";
import { formatDate } from '@angular/common';
import { NtfMessageComponent } from 'src/app/ui-notifications/ntf-message/ntf-message.component';
import { ActivatedRoute } from '@angular/router';
import { combineLatest } from 'rxjs';
import { tap } from 'rxjs/operators';
import { WhitelabelService } from 'src/app/domain/whitelabel.service';

interface IView<T> extends IMenuTabConfig<T> {
  imgUrl: string,
  description: string,
  hasIndicator?: boolean,
}
export const OSSLTResultColumns =  [ 
        "SchMident", "SchName", "Grouping", "StudentOEN", "SASN"
        , "FirstName", "LastName", "EligibilityStatus", "HasStarted", "HasSubmitted", "StartedOn"
        , "SubmittedOn", "HasReport", "Result", "OSSLTScaleScore", 'ScaleScoreDescription', "Note"
];
@Component({
  selector: 'sb-board',
  templateUrl: './sb-board.component.html',
  styleUrls: ['./sb-board.component.scss']
})
export class SbBoardComponent implements OnInit {
  
  breadcrumb = [];
  
  constructor(
    public lang: LangService,
    private auth: AuthService,
    private routes: RoutesService,
    private route: ActivatedRoute,
    private loginGuard: LoginGuardService,
    private sidePanel: SidepanelService,
    private breadcrumbsService: BreadcrumbsService,
    private router: Router,
    public whitelabel: WhitelabelService
  ) { }

  isShowSebPass: boolean;
  isShowKioskPass: boolean;
  hasAccess: boolean;
  schoolBoardInfo;
  SchoolBoardView = SchoolBoardView;
  selectedView: SchoolBoardView;
  views: IView<SchoolBoardView>[] = [];
  adminWindows = [];
  currentAdminWindow: any;
  schlDistGroupId: string;
  isSecreteUser;

  ngOnInit(): void {
    this.loginGuard.activate([]);
    this.sidePanel.activate();
    this.sidePanel.unexpand();

    this.route.queryParams.subscribe((queryParams) => {
      if (queryParams.isSecreteUser) {
        this.isSecreteUser = queryParams.isSecreteUser 
      }
      const distGroupId  = queryParams['district'];
      if (distGroupId){
        this.schlDistGroupId = distGroupId
      }
    });

    if(!this.schlDistGroupId){
      this.router.navigate([this.lang.c(), 'login-educator']);
      return
    }

    this.createSchoolBoardBreadcrumb()

    this.selectedView = this.SchoolBoardView.TECH_READI;
    // The API called should be trigger when both user and district group id are fetched
    const combinedObservable = combineLatest([this.auth.user().asObservable(), this.route.queryParams]);
    combinedObservable.pipe(
      tap(() => {
        const user = this.auth.user().value;
        if (!user) {
          return;
        }
        this.auth
        .apiGet(this.routes.DIST_ADMIN_SUMMARY, user.uid, { query: {clientDomain: getFrontendDomain(), schlDistGroupId: this.schlDistGroupId, isSecreteUser:this.isSecreteUser}})
          .then(res => {
            this.schoolBoardInfo = res;
            this.hasAccess = true;
            this.createAdminWindows(res.csvTestWindows)
        })
      })
    ).subscribe();
    this.views = [];
    SCHOOL_BOARD_VIEWS.forEach(view => {
      if(view.id!=SchoolBoardView.REPORTS || this.isAllowBypassDomain()){
        this.views.push( Object({
          ...view,
          caption: this.lang.tra(view.caption),
          description: this.lang.tra(view.description),
        }))
      }
    })
  }

  createSchoolBoardBreadcrumb() { 
    const urlTree = this.router.parseUrl(this.router.url);
    const params = urlTree.queryParams; 
    
    urlTree.queryParams = {}; 
    urlTree.fragment = null; // optional
    const route = urlTree.toString()

    this.breadcrumb = [
      this.breadcrumbsService._CURRENT(
        this.lang.tra('eqao_sample'),
        route,
        params
      )   
    ] 
  }

  selectView(id: SchoolBoardView) {
    //console.log('selectView', id)
    //this.router.navigate([this.getViewRoute(id)]);
    // this.updateBreadcrumb();
    this.selectedView = id;
    if(this.selectedView == SchoolBoardView.REPORTS && this.schoolBoardInfo){
      const boardLang = this.schoolBoardInfo["brd_lang"];
      this.lang.setCurrentLanguage(boardLang);
    }  
  }
  getBaseRoute() {
    return `/${this.lang.c()}/${AccountType.DIST_ADMIN}`;
  }
  getViewRoute(viewSlug: SchoolBoardView) {
    return this.getBaseRoute() + '/' + viewSlug
  }

  exportOSSLTResult(){
    const user = this.auth.user().value;
    this.auth
    .apiGet(this.routes.DIST_ADMIN_OSSLT_REPORT, user.uid, this.configureQueryParams()
    ).then(res =>{
      const columns = this.getOSSLTResultColumns();
      if(res.length > 0){
        const exportData = this.getOSSLTResultData(res)
        downloadCSVFromExportData('osslt-report.csv',exportData, columns)
      }  
    })
  }

  configureQueryParams(){
    return { query: {schl_dist_group_id: this.schoolBoardInfo.group_id, clientDomain: getFrontendDomain(), testWindowId: this.currentAdminWindow.tw_id, isSecreteUser:this.isSecreteUser }}
  }

  getOSSLTResultColumns(){
    const columnsName = OSSLTResultColumns;
    let columns: IExportColumn[] = [];
    columnsName.forEach(column =>{
      columns.push({
        prop: column,
        caption: column,
        isClickable: false
      })
    })
    return columns;
  }

  /**
   * show "ALT" on the score column in the OSSLT Export
   * @param record
   * @returns boolean 
   */
  showALtScaleScoreInExport(record){
    const isLinearReport = record.isLinear && record.isLinear =='1'
    const showALTAsScore = isLinearReport && !record.show_isr_alt_score  // if show_isr_alt_score
    return showALTAsScore
  }

  getOSSLTResultData(data:any){
    let records = [];
    data.forEach(record => {
      //Modify SASN
      if(record.SASN === null){
        record.SASN = '#'
      }

      //Modify EligibilityStatus
      if(record.EligibilityStatus){
        if(record.EligibilityStatus == '1'){
          record.EligibilityStatus = this.lang.tra("sdc_osslt_eligstat_1")
        }
        else if (record.EligibilityStatus == '2'){
          record.EligibilityStatus = this.lang.tra("sdc_osslt_eligstat_2")
        }else{
          record.EligibilityStatus = ''
        } 
      }else{
        record.EligibilityStatus = ''
      }

      //Modify HasStarted
      if(record.HasStarted){
        record.HasStarted = this.lang.tra("lbl_yes")
      }else{
        record.HasStarted = this.lang.tra("lbl_no")
      }

      //Modify HasSubmitted
      if((record.HasSubmitted && record.HasSubmitted == 1)||(record.HasReport && record.ta_closed_on)){
        record.HasSubmitted = this.lang.tra("lbl_yes")
      }else{
        record.HasSubmitted = this.lang.tra("lbl_no")
      }

      if(record.HasSubmitted && record.HasSubmitted == this.lang.tra("lbl_yes") && record.ta_closed_on ){
        record.SubmittedOn = formatDate(new Date(record.ta_closed_on), 'yyyyMMdd', 'en_US')
      }else{
        record.SubmittedOn = ''
      }
      delete record.ta_closed_on;

      //Modify StartedOn
      if(record.StartedOn){
        record.StartedOn = formatDate(new Date(record.StartedOn), 'yyyyMMdd', 'en_US')
      }else{
        record.StartedOn= ''
      }

      //Modify HasReport
      // if(record.HasReport){
      //   record.HasReport = this.lang.tra("lbl_yes")
      // }else{
      //   record.HasReport = this.lang.tra("lbl_no")
      // }

      //Modify Result, Scalescore and HasReport
      if(record.Result){
        switch(record.Result){
          case "0":{
            record.Result = this.lang.tra("sa_report_state_0");
            record.OSSLTScaleScore = ""
            record.HasReport = this.lang.tra("lbl_no")
            break;
          }  
          case "1":{
            record.Result = this.lang.tra("sa_report_state_1");
            //record.OSSLTScaleScore = 0
            record.ScaleScoreDescription = this.lang.tra("osslt_report_scalescore_1_succ")
            record.HasReport = this.lang.tra("lbl_yes")
            if(this.showALtScaleScoreInExport(record)){
              record.OSSLTScaleScore = "ALT"
              record.ScaleScoreDescription = this.lang.tra("osslt_report_scalescore_3")
            }
            break;
          }
          case "2":{
            record.Result = this.lang.tra("sa_report_state_2");
            record.ScaleScoreDescription = this.lang.tra("osslt_report_scalescore_2")
            record.HasReport = this.lang.tra("lbl_yes")
            if(this.showALtScaleScoreInExport(record)){
              record.OSSLTScaleScore = "ALT"
              record.ScaleScoreDescription = this.lang.tra("osslt_report_scalescore_3")
            }
            break;
          }
          case "3":{
            record.Result = this.lang.tra("sa_report_state_3");
            record.OSSLTScaleScore = ""
            record.HasReport = this.lang.tra("lbl_no")
            break;
          }
          case "4":{
            record.Result = this.lang.tra("sa_report_state_4");
            record.OSSLTScaleScore = ""
            record.HasReport = this.lang.tra("lbl_no")
            break;
          }
          case "5":{
            record.Result = this.lang.tra("sa_report_state_5");
            record.OSSLTScaleScore = ""
            record.HasReport = this.lang.tra("lbl_no")
            break;
          }
          case "6":{
            record.Result = this.lang.tra("sa_report_state_6");
            record.OSSLTScaleScore = ""
            record.HasReport = this.lang.tra("lbl_no")
            break;
          }
          case "10":{
            record.Result = this.lang.tra("sa_report_state_10");
            record.OSSLTScaleScore = ""
            record.HasReport = this.lang.tra("lbl_no")
            break;
          }
          case "11":{
            record.Result = this.lang.tra("sdc_const_osslt_succ_2");
            record.OSSLTScaleScore = 0
            record.ScaleScoreDescription = this.lang.tra("osslt_report_scalescore_1_na")
            record.HasReport = this.lang.tra("lbl_no")
            break;
          }          
          default:{
            record.Result = this.lang.tra("sa_report_state_2");
            record.OSSLTScaleScore = ""
            record.HasReport = this.lang.tra("lbl_no")
            break;
          }  
        }
      }else{
        record.Result = this.lang.tra("sa_report_state_2");
        record.OSSLTScaleScore = ""
        record.HasReport = this.lang.tra("lbl_no")
      }

      //Modify Note
      if(record.Note === null){
        record.Note = ''
      }
      records.push(record)
    });

    return records
  }

  isAllowBypassDomain(){
    // const clientDomain = getFrontendDomain()
    // const allowBypassDomain = ["http://localhost:4200/","http://localhost:4401/", "https://d3h4m0g2lmrmq6.cloudfront.net/"]
    // //const allowBypassDomain = ["https://d3h4m0g2lmrmq6.cloudfront.net/"]
    // const isBypassDomain = allowBypassDomain.indexOf(clientDomain) > -1
    // return isBypassDomain;
    return true;
  }

  createAdminWindows(csvTestWindows){
    csvTestWindows.forEach( csvTestWindow => {
      const endOn = new Date(csvTestWindow.date_end)
      const endOnYear = endOn.getFullYear()
      const endOnMonth = endOn.getMonth()+1
      let schoolYear
      if(endOnMonth < 7){
        schoolYear = ''+endOnYear+' '+this.lang.tra('lbl_osslt_report_term_spring')
      }else{
        schoolYear = ''+endOnYear+' '+this.lang.tra('lbl_osslt_report_term_fall')
      }
      const haveAdminWindow = this.adminWindows.find(sy => sy.tw_id == csvTestWindow.id)
      if(!haveAdminWindow){
        this.adminWindows.push({tw_id:csvTestWindow.id, stringValue:schoolYear})
      }
    })
    if(this.adminWindows.length > 0){
      this.currentAdminWindow = this.adminWindows[0]
    }  
  }

  onAdminWindowChanged(schoolYearIndex){
    this.currentAdminWindow = this.adminWindows[schoolYearIndex];
  }

  currentAdminWindowIndex(){
    return this.adminWindows.indexOf(this.currentAdminWindow);
  }

  getAdminWindowSlug(sy){
    return sy.stringValue
  }
}
