<div class="layout-bg">
    <div class="layout-container">
        <div class="header-container">
            <img src="https://eqao.vretta.com/authoring/user_uploads/6263/authoring/HELIX/1594929184731/HELIX.png">
            <img src="https://eqao.vretta.com/authoring/user_uploads/6263/authoring/ENS/1594929007313/ENS.png">
            <hr class="solid">
        </div>

        <div>
            <div class="certificate-message">Your personalized</div>
            <div class="certificate-message">Certificate of Achievement</div>
            <div class="certificate-message">has been e-mailed to you</div>
        </div>

        <canvas class="certificate" #canvasRef width="{{certificateWidth}}" height="{{certificateHeight}}"></canvas>
        
        <div>
            <button (click)="openSocial('fb')" class="social fb"></button>
            <button (click)="openSocial('twitter')" class="social twitter"></button>
            <button (click)="openSocial('linkedin')" class="social linked-in"></button>
            <button (click)="openSocial('ig')" class="social ig"></button>
        </div>

        <div class="button-end-container"><button class="button-end" (click)="certificateEnd.emit()"><span>END</span></button></div>
        <div class="logo-container"><img class="logo" src="https://eqao.vretta.com/authoring/user_uploads/21/authoring/vretta-logo/1596159474108/vretta-logo.png"></div>
    </div>
</div>