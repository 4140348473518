import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { QuestionState } from '../models';
import { QuestionPubSub } from '../question-runner/pubsub/question-pubsub';
import { StyleprofileService } from 'src/app/core/styleprofile.service';
import { AuthScopeSettingsService } from 'src/app/ui-item-maker/auth-scope-settings.service';
import { LangService } from 'src/app/core/lang.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TextToSpeechService } from '../text-to-speech.service';
import { IContentElementStaticDiagram } from './model';

@Component({
  selector: 'element-render-static-diagram',
  templateUrl: './element-render-static-diagram.component.html',
  styleUrls: ['./element-render-static-diagram.component.scss']
})
export class ElementRenderStaticDiagramComponent implements OnInit, AfterViewInit {

  @Input() element:IContentElementStaticDiagram;
  @Input() isLocked:boolean;
  @Input() isShowSolution:boolean;
  @Input() questionState:QuestionState;
  @Input() changeCounter:number;
  @Input() questionPubSub?: QuestionPubSub;

  constructor(
    private lang:LangService,
    private styleProfile:StyleprofileService,
    private authScope: AuthScopeSettingsService,
    private sanitizer: DomSanitizer,
    public textToSpeech: TextToSpeechService,
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
  }

  ngOnDestroy(): void {
    // if(this.styleProfileChangeSub) {
    //   this.styleProfileChangeSub.unsubscribe();
    // }
  }
}
