<div *ngIf="element.isShowAdvancedOptions" class="simple-form-header">Header</div>
<element-config-text *ngIf="element.isShowAdvancedOptions" [element]="element.header"></element-config-text>
<div *ngIf="element.isShowAdvancedOptions && element.headerImage" class="simple-form-header">Header Image</div>
<element-config-image  *ngIf="element.isShowAdvancedOptions && element.headerImage" [element]="element.headerImage"></element-config-image>

<div class="simple-form-header">Passage</div>
<p>
    Enter your passage below. Lines will flow based on number on the left (line returns must be made explicitly). 
</p>

<div *ngIf="problematicLines && problematicLines.length>0">
    The following lines are too long
    <code *ngFor="let problem of problematicLines">{{problem.lineNumber}}</code>
    <button (click)="autoReflow()">Auto-Fix</button>
</div>

<div class="code-mirror-sizer">
    <ngx-codemirror
      #codeeditor
      [(ngModel)]="element.text"
      [options]="editorConfig"
      (drop)="onImageDrop($event)"
    ></ngx-codemirror>
</div>

<p>
    The following tags are supported: 
    <code>&lt;b&gt;&lt;/b&gt;</code>,
    <code>&lt;i&gt;&lt;/i&gt;</code>,
    <code>&lt;sc&gt;&lt;/sc&gt;</code> (for small caps),
    <code>&lt;skip/&gt;</code> (to skip line count),
    <code>&lt;bookmark id="x"&gt;&lt;/bookmark&gt;</code> (so that other items can reference the passage).
    <code>&lt;a tooltip="x" align="x"&gt; &lt;/a&gt;</code>
</p>

<div *ngIf="element.isShowAdvancedOptions" class="simple-form-header">Footer</div>
<element-config-text *ngIf="element.isShowAdvancedOptions" [element]="element.footer"></element-config-text>

<div class="simple-form-header">Passage Config</div>
<div>
    Count by 
    <select [(ngModel)]="element.counterType" (change)="updateChangeCounter()">
        <option *ngFor="let counterType of counterTypes" [value]="counterType">{{counterType}}</option>
    </select>
    <div *ngIf="element.counterType == 'LINE'">
        every <input type="number" [(ngModel)]="element.lineCountInterval" (change)="updateChangeCounter()" min="1"> lines
        <label style="font-size:0.8em">
            (<input type="checkbox" [(ngModel)]="element.isLineCountSkipBlank" (change)="updateChangeCounter()"> skip blank lines?)
        </label>
    </div>
    <div *ngIf="!(element.counterType == 'NONE')">
        Align: <select [(ngModel)]="element.counterAlignment" (change)="updateChangeCounter()">
            <option>(default)</option>
            <option value="left">left</option>
            <option value="right">right</option>
        </select>
    </div>
</div>

<div style="margin-top:1em; padding:0.5em; border: 1px solid #ccc;">
    <button class="button" (click)="insertParagraphStyle()">Define Paragraph Style</button>
    <div *ngIf="element.paragraphStyles">
        <div *ngFor="let paragraphStyle of element.paragraphStyles">
            <hr/>
            <span class="paragraph-style-header">            
                <code>&lt;pstyle id="{{paragraphStyle.id}}"/&gt;&lt;t/&gt;</code>
                <button (click)="removeParagraphStyle(paragraphStyle.id)">
                    <i class="fas fa-trash"></i> Remove
                </button>
            </span>
            <ul>
                tab spacing <input type="number" [(ngModel)]="paragraphStyle.tabs" (change)="updateChangeCounter()"/>
            </ul>
        </div>
    </div>
</div>


<div style="margin-top:1em; padding:0.5em; border: 1px solid #ccc;">
    <!-- <button class="button" (click)="insertImage()">Apply Bookmark</button> -->
    <button class="button" (click)="insertImage()">Insert Image</button>
    <div *ngIf="element.images">
        <div *ngFor="let image of element.images">
            <hr/>
            <span class="image-header">            
                <code>&lt;img id="{{image.id}}"&gt;</code>
                <button (click)="removeImage(image.id)">
                    <i class="fas fa-trash"></i> Remove
                </button>
            </span>
            <div>Align: <select [(ngModel)]="image.alignment" (change)="updateChangeCounter()">
                <option></option>
                <option>left</option>
                <option>right</option>
                <option>center</option>
                <option>none</option>
            </select></div>
            <label for="borderPadding">Inline Padding: <input type="number" min="0.0" [(ngModel)]="image.padding" (change)="updateChangeCounter()"></label>
            <element-config-image [element] = "image.el" (change)="updateChangeCounter()"></element-config-image>
        </div>
    </div>
</div>

<div *ngIf="this.element.isShowAdvancedOptions">
    <div class="simple-form-header">Border</div>
    <div class="simple-form-container">
        <label for="borderColor">Color: <input type="color" [(ngModel)]="element.border.color"></label>
        <label for="borderThickness">Thickness (in pixels): <input type="number" min="0" [(ngModel)]="element.border.width"></label>
        <label for="borderPadding">Padding: <input type="number" min="0" [(ngModel)]="element.border.padding"></label>
        <label for="isAdjustForPadding">Adjust width for padding: <input type="checkbox" [(ngModel)]="element.border.isWidthAdjustedForPadding"></label>
    </div>    
</div>



<label class="checkbox">
    <input type="checkbox" [(ngModel)]="element.isShowAdvancedOptions">
    <tra slug="auth_show_advanced_options"></tra>
</label>
