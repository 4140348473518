import { API_ADDRESS_LOCAL, API_ADDRESS_LOCALB } from "../../api/api-addr/l";
import { IContextData } from "./common";
import { context } from '../../../context.json';

const footer = [
  [
    {"route":"/contact", "caption": "title_contactus"},
    // {"route":"/news", "caption": "title_news"},
  ],
  [
    {"route":"/privacy", "caption": "title_privacy"},
  ],
  [
    {"route":"/legal", "caption": "title_legal"}
  ],
]

const apiAddress = (hostname:string) => {
// <<<<<<< HEAD
//   switch(hostname){
//     case 'localhost':
//       // return 'https://eqao2-stress-06032022-api.vretta.com';
//       return API_ADDRESS_LOCALB;
//       // return 'https://eqao2-api.vretta.com';
//       // return 'https://qc7-api.vretta.com';
//         // return API_ADDRESS_LOCAL;
//       //return 'https://apiqc.mathproficiencytest.ca'
//       //return 'https://api-eassessment.vretta.com';
//     case 'eqao-xqc.vretta.com':
//       return 'https://api-eqao-xqc.vretta.com';
//     case 'eqao-main-qc.vretta.com':
//       return 'https://apiqc.mathproficiencytest.ca';
//     case 'qc7.vretta.com':
//     case 'd3k4cvve5iw7mr.cloudfront.net':
//       return 'https://qc7-api.vretta.com';
//     case 'eqao.vretta.com':
//     case 'd3h4m0g2lmrmq6.cloudfront.net':
//     case 'private.eqao.vretta.com':
//     case 'eqao-lit.vretta.com':
//     case 'd12o445rx6gi3b.cloudfront.net':
//     case 'dwod99k06nyqh.cloudfront.net':
//       return 'https://eqao2-api.vretta.com';
//       // return 'https://eqao-api.vretta.com';
//     case 'drkpaddt3nk24.cloudfront.net':
//       return 'https://eqao-api.vretta.com';
//     case 'd24a8vriqswfok.cloudfront.net':
//       return 'https://apiqc.mathproficiencytest.ca';
//     case 'd1w433s63hot3w.cloudfront.net':
//     case 'd2r9nuffi6xllr.cloudfront.net':
//       return 'https://eqao-qc-api.vretta.com'
//     case 'qc1.mathproficiencytest.ca':
//     case 'qc4.mathproficiencytest.ca':
//       return 'https://apiqc.mathproficiencytest.ca';
//     default:
//       return 'https://api.mathproficiencytest.ca';
// =======
  if (hostname) {
    return context[hostname];
  } else {
    return context['*'];
  }
}
  
export const EQAO:IContextData = {
    id: 'EQAO',
    homepageRoute: '/en/login-router-st',
    brandName: 'EQAO E-Assessment',
    defaultTimezone: 'America/Toronto',
    logo: 'eqao_context_logo',
    footer: null,
    apiAddress,
    siteFlags: {
      BREADCRUMB_MENU: false,
      BOOKING_POLICY_STATS: true,
      IS_EQAO: true,
      EQAO_LOGIN: true,
      'INST_MNG_TT': true,
      'TEST_RUNNER_WIDTH_CONSTRAINT': true,
      'PREVENT_AUTO_EDIT': true,
      'IS_TC_SUMM': true,
      'IS_TC_DATA': false,
    },
    siteText: {
        copyright: 'title_copyright',
        supportEmail: 'support_email',
        supportPhone: 'eqao_support_phone_number',
        login_admins: 'teacher_administrator',
        my_inst: 'My School',
        my_inst_admin: 'You are the **administrator** of this school.',
        my_inst_manage_acct: 'Manage Teachers+',
        student_ident: 'oen',
        student_ident_2: 'lbl_sasn',
        asmt_logo_en: 'https://eqao.vretta.com/authoring/user_uploads/6276/authoring/EQAO_Logo_BW_sml_trnctd_clear_space-cropped/1631817152201/EQAO_Logo_BW_sml_trnctd_clear_space-cropped.svg',
        asmt_logo_fr: 'https://eqao.vretta.com/authoring/user_uploads/6276/authoring/OQRE_Logo_BW_sml_trnctd_clear_space-cropped/1631817437150/OQRE_Logo_BW_sml_trnctd_clear_space-cropped.svg',
        header_logo_en: 'https://eqao.vretta.com/authoring/user_uploads/21/authoring/g18/1602729003686/g18.png',
        header_logo_fr: 'https://eqao.vretta.com/authoring/user_uploads/21/authoring/OQRE_Logo_White_sml%201/1602729029336/OQRE_Logo_White_sml%201.png',
        marking_chat:'Scoring Chat',
        marking:'scoring',
        mark:'score',
        marker:'scorer',
        markers:'scorers',
        marks:'scores',
        marked:'scored',
        reliability:'validity',
        exemplar:'validity response',
        exemplars:'validity responses',
        TnR:'T&V',
        sb_board_logo_en: 'https://eqao.vretta.com/authoring/user_uploads/21/authoring/EQAO_Logo_SVG/1609173725758/EQAO_Logo_SVG.svg',
        sb_board_logo_fr: 'https://eqao.vretta.com/authoring/user_uploads/6526/authoring/image/1606866913334/image.png',
        g9_sample_test_logo_en: 'https://eqao.vretta.com/authoring/user_uploads/21/authoring/EQAO_Logo_SVG/1609173725758/EQAO_Logo_SVG.svg',
        g9_sample_test_logo_fr: 'https://eqao.vretta.com/authoring/user_uploads/21/authoring/OQRE_Logo_SVG/1609173780871/OQRE_Logo_SVG.svg',
        sample_test_welcome_logo: 'eqao_welcome_logo',
        sa_report_logo_link_2022: 'sa_report_logo_link_2022',
        payment_invoice_logo_en: 'https://eqao.vretta.com/authoring/user_uploads/517206/authoring/payment_invoice_eqao_logo/1661888407083/payment_invoice_eqao_logo.png',
        payment_invoice_logo_fr: 'https://eqao.vretta.com/authoring/user_uploads/517206/authoring/payment_invoice_oqre_logo/1661888532568/payment_invoice_oqre_logo.png',
        logo_bw_en: 'https://eqao.vretta.com/authoring/user_uploads/21/authoring/Picture2/1634073112739/EQAO_Logo_BW_sml_trnctd_clear+space.svg',
        logo_bw_fr: 'https://eqao.vretta.com/authoring/user_uploads/21/authoring/Picture2/1634073112739/OQRE_Logo_BW_sml_trnctd_clear+space.svg',
        loginTopBannerS3Link: 'https://eqao.vretta.com/authoring/eqao_top_banner/eqao_top_banner.json'
      },
    siteData: {
      'SCORING_SUPPORT': {
        text: 'lbl_technical_support_scor',
        email: 'support_email',
        phone:'eqao_support_phone_number',
      }
    },
    hosts: [
        // 'localhost:4200',
        // 'localhost:4401',
        'd2r9nuffi6xllr.cloudfront.net',
        'd12o445rx6gi3b.cloudfront.net',
        'd1c1qqn86e6v14.cloudfront.net',
        'd1w433s63hot3w.cloudfront.net',
        'd24a8vriqswfok.cloudfront.net',
        'dwod99k06nyqh.cloudfront.net',
        'drkpaddt3nk24.cloudfront.net',
        'eqao.vretta.com',
        'eqao-lit.vretta.com',
        'eqao-lit-qc1.vretta.com',
        'eqao-xqc.vretta.com',
        'qc4.mathproficiencytest.ca',
    ]
}
