import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { IContentElement } from '../../../ui-testrunner/models';
import { elementIconById, createDefaultElement, frameElement} from '../models';

export class ItemBankUtilCtrl {

  constructor(){}

  replaceProp(obj, prop: string, forceReplace: boolean= false, onlyForLang?: string) {

    let displayVal = obj[prop]
    if(obj[prop]?.onlyForLang) {
      displayVal = obj[prop].val;
    }

    let str = prompt('New ' + prop, displayVal);
    if(str == null){return;}
    let val;
    if (str || (forceReplace && str === '')) {
      val = str;
    }
    if(onlyForLang) {
      obj[prop] = {
        onlyForLang,
        val
      }
    } else {
      obj[prop] = val;
    }
  }
  replaceRangeProp(obj, prop: string, forceReplace: boolean= false, onlyForLang?: string) {
    let currFrom = '';
    let currTo = '';
    let val;
    if (obj[prop]) {
      const curr: string[] = obj[prop].split('->');
      currFrom = curr.length ? curr[0] : '';
      currTo = curr.length === 2 ? curr[1] : '';
    }
    let from = prompt(`New ${prop} min:`, currFrom);
    if (from || (forceReplace && from === '')) {
      val = `${from}->${currTo}`;
      let to = prompt(`New ${prop} max:`, currTo);
      if (to || (forceReplace && to === '')) {
        val = `${from}->${to}`;
      }
      if (!from && !to) {
        delete obj[prop]
      }
    }

    if(obj[prop]) {
      if(onlyForLang) {
        obj[prop] = {
          onlyForLang,
          val
        }
      } else {
        obj[prop] = val;
      }
    }
  }
  getIconByElementTypeId(elementTypeId: string) {
    return elementIconById.get(elementTypeId);
  }
  removeArrEl(arr: any[], el: any, message:string='Remove?') {
    if (confirm(message)) {
      const i = arr.indexOf(el);
      if (i !== -1) {
        arr.splice(i, 1);
      }
    }
  }
  removeElement(content: IContentElement[], element: IContentElement) {
    this.removeArrEl(content, element, 'Remove this block?');
  }
  moveArrElDown(el: any, arr: any[]) {
    let i = arr.indexOf(el);
    if (i < arr.length-1) {
      arr.splice(i, 1);
      arr.splice(i+1, 0, el);
    }
  }
  
  moveArrElUp(el: any, arr: any[]) {
    let i = arr.indexOf(el);
    if (i > 0) {
      arr.splice(i, 1);
      arr.splice(i-1, 0, el);
    }
  }
  numStrToBool(str) {
    if (str === undefined) {
      return false;
    }
    if (str === '0' || str === 0) {
      return false;
    }
    if (str === '1' || str === 1) {
      return true;
    }
    return str;
  }
  nextId(arr: {id: number}[]) {
    let nextId = 0;
    arr.forEach(entry => nextId = Math.max(nextId, +entry.id));
    return Math.floor(nextId + 1);
  }
  drop(arr: any, event: CdkDragDrop<string[]>) {
    moveItemInArray(arr, event.previousIndex, event.currentIndex);
  }
  dropBtwn(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } 
    else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
      }
    }
  }