export const indexOf = (arr:any[], t:any) => {
    let i = -1;
    arr.forEach((_t, _i) => {
      if (_t === t){
        i = _i;
      }
    });
    return i;
  }
  

export const mapToJson = (map) =>  {
    return JSON.stringify([...map]);
}

/**
 * Inside the input object, find a sub-object with some key-value pair
 * @param obj A neted object
 * @param targetKey The key some sub-object should have, e.g. `entryId`
 * @param targetVal The value of the jey some sub-object should have, e.g. `3`
 * @returns The sub-object that fits the criteria, i.e. has entryId = 3
 */
export const deepFind = (obj, targetKey, targetVal) => {
  if (!obj || typeof obj !== 'object') return null;
  if (obj[targetKey] === targetVal) return obj;
  return Object.values(obj).reduce((acc, val) => acc || deepFind(val, targetKey, targetVal), null);
}

/**
 * Recursively collects values of matching keys within an object up to a specified depth.
 * @param obj The object to search through.
 * @param keyToFind The key name to look for in the object.
 * @param depth The maximum depth to search within the object (default is Infinity).
 * @param unique Whether to return only unique values (default is false).
 * @param currentDepth The current depth of recursion (internal use, default is 0).
 * @returns A one-dimensional array of values where the key matches the specified key name.
 */
export const deepCollect = (
  obj: Record<string, any>,
  keyToFind: string,
  unique: boolean = false,
  depth: number = Infinity,
  currentDepth: number = 0
): any[] => {
  let results: any[] = [];

  if (currentDepth > depth) return results;

  for (const key in obj) {
    if (key === keyToFind) {
      results.push(obj[key]);
    }

    if (typeof obj[key] === 'object' && obj[key] !== null) {
      results.push(
        ...deepCollect(obj[key], keyToFind, unique, depth, currentDepth + 1)
      );
    }
  }

  if (unique) {
    results = uniqueValues(results);
  }

  return results;
}

/**
 * Removes duplicates from an array of objects by performing a deep comparison.
 * @param array The array to process.
 * @returns A new array with unique values based on deep comparison.
 */
const uniqueValues = (array: any[]): any[] => {
  const seenValues: Set<string> = new Set();
  return array.filter(item => {
    const itemString = JSON.stringify(item);
    return seenValues.has(itemString) ? false : (seenValues.add(itemString), true);
  });
}

