<mat-accordion [class.size-minimize]="isNgContent">
  <mat-expansion-panel [ngStyle]="style || {}" [expanded]="isExpanded" (opened)="panelOpened()" (closed)="panelClosed()">
    <mat-expansion-panel-header>
      <mat-panel-title>
          <div class="panel-title" style="font-weight: bold;">{{title}}</div>
          <div class="panel-subtitle" *ngIf="subTitle" style="margin-left: 0.5em;">({{subTitle}})</div>
      </mat-panel-title>
      <mat-panel-description>
        {{description}}
      </mat-panel-description>
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>

      <ng-container *ngIf="!isNgContent">
        <section *ngFor="let c of content" class="content-block">
          <ng-container [ngSwitch]="c.type">
            <div *ngSwitchCase="ExpansionPanelContentType.JSON_VIEW">
              <label class="content-label">{{c.label}} </label>
              <div style="margin-left: 0.5em;">
                <ngx-json-viewer [json]="c.data" [expanded]="false" ></ngx-json-viewer>
              </div>
            </div>
            <div *ngSwitchCase="ExpansionPanelContentType.TEXT_AREA">
              <div class="flex-gap">
                <span class="text-area-middle">
                  <label class="content-label">{{c.label}} </label>
                  <textarea style="margin-left:0.5em" [(ngModel)]="c.data" [readonly]="readOnly"></textarea>
                </span>
              </div>
            </div>
            <div *ngSwitchCase="ExpansionPanelContentType.SPAN_LIST">
              <label class="content-label">{{c.label}} </label>
              <div>
                <ng-container *ngFor="let span of c.data">
                  <span [ngStyle]="span.style">{{ span.text }}</span>
                </ng-container>
              </div>
            </div>
          </ng-container>
        </section>
      </ng-container>

      <ng-container *ngIf="isNgContent">
        <ng-content></ng-content>
      </ng-container>

    </ng-template>
  </mat-expansion-panel>
</mat-accordion>