<div class="image-holder">
    <img
        src="https://eqao.vretta.com/authoring/user_uploads/2329038/authoring/test-window-icon/1666377674742/test-window-icon.png">
</div>
<div class="table-holder">
    <div class="lead">Administration Windows</div>
    <div class="space-between">
        <div>Select an administration window to access further details</div>
        <div>
            <mat-slide-toggle [(ngModel)]="isShowQA" (change)="qaToggleChanged()">
                Show QA? ({{isShowQA ? 'Yes' : 'No'}})
            </mat-slide-toggle>
        </div>
        <div class="select">
            <select [(ngModel)]="selectedAcademicYear" (ngModelChange)="testWindowsChanged()">
                <option [ngValue]="SELECT_OPTIONS.ALL">
                    All Windows
                </option>
                <option *ngFor="let academicYear of academicYears" [ngValue]="academicYear">
                    {{renderAcademicYear(academicYear)}}
                </option>
                <option [ngValue]="SELECT_OPTIONS.OTHER">Other</option>
            </select>
        </div>
    </div>
    <table style="width: auto;">
        <tr>
            <th>ID</th>
            <th>Window Title</th>
            <th>Dates</th>
            <!-- <th>Status</th> -->
            <th *ngIf="!isTwSelectDisabled">Select</th>
            <th>Scoring Window Ids</th>
        </tr>
        <ng-container *ngFor="let window of visibleTestWindows">
            <tr *ngIf="true">
                <td>{{window.id}}</td>
                <td>
                    <div>
                        <strong>{{renderTwTitle(window.title)}}</strong>
                    </div>
                    <div class="space-between">
                        <div>
                            <code>{{window.type_slug}}</code>
                            <code style="color: blue">{{window.test_ctrl_group_id}}</code>
                            <span *ngIf="window.is_qa==1" class="tag is-danger">QA</span>
                        </div>
                        <div style="font-size: 0.8em;">
                            <a [routerLink]="getTestWindowRoute(window)" *ngIf="!hideEdits">
                                Edit
                            </a>
                        </div>
                    </div>
                </td>
                <td>{{renderDate(window.date_start)}} to {{renderDate(window.date_end)}}</td>
                <!-- <td>{{window.status}}</td> -->
                <td *ngIf="!isTwSelectDisabled">
                    <ng-container [ngSwitch]="!!checkActiveWindow(window)">
                        <button *ngSwitchCase="true" class="button is-small is-info">
                            Selected
                        </button>
                        <button *ngSwitchCase="false" class="button is-small" (click)="selectTestWindowFocus(window)">
                            Not Selected
                        </button>
                    </ng-container>
                </td>
                <td>
                    {{window.marking_window_id}}
                </td>
            </tr>
        </ng-container>
    </table>
    <ng-content></ng-content>
</div>