<fieldset [disabled]="isReadOnly()">
    <label>
        Shadow-x:
        <input type="number" [style.width.em]="4" [(ngModel)]="element.dropShadowX"/>
    </label><br>
    <label>
        Shadow-y:
        <input type="number" [style.width.em]="4" [(ngModel)]="element.dropShadowY"/>
    </label><br>
    <label>
        Shadow Blur Radius:
        <input type="number" [style.width.em]="4" [(ngModel)]="element.blurRadius"/>
    </label><br>
    <label>
        Shadow Colour:
        <input type="color" [style.width.em]="4" [(ngModel)]="element.shadowColor"/>
    </label><br>
    <label>
        Padding:
        <input type="number" [style.width.em]="4" [(ngModel)]="element.padding"/>
    </label><br>
    <label>
        <tra slug="auth_bckg_colour"></tra>
        <input type="color" [(ngModel)]="element.backgroundColor"/>
    </label><br>
</fieldset>