import { IContentElement, IScoredResponse } from "src/app/ui-testrunner/models";
import { IContentElementDynamicImage, IContentElementImage } from "../element-render-image/model";
import { IContentElementText } from "../element-render-text/model";

export const passageEditInfo = {
    editExcludeFields: []
}
 

export const PASSAGE_LIN_COUNT_INTERVAL_DEFAULT_SYS = 5;

export enum PassageCounterType { 
    LINE = 'LINE',
    PARAGRAPH = 'PARAGRAPH',
    NONE = 'NONE',
}

export type ImageData = {
    image:IContentElementDynamicImage, 
    padding: number, 
    alignment?: 'left' | 'right' | 'center' | 'none'
}

export interface IContentElementPassage extends IContentElement {
    text: string,
    images?: {
        id:number,
        el:IContentElementDynamicImage,
        alignment?:'left' | 'right' | 'center' | 'none',
        padding: number
    }[],
    paragraphStyles?: {
        id:number, 
        tabs: number
    }[],
    counterType: PassageCounterType,
    counterAlignment?: 'left' | 'right',
    lineCountInterval: number,
    isLineCountSkipBlank?: boolean,
    header?: IContentElementText,
    footer?: IContentElementText,
    border?:{
        width: number;
        color: string;
        padding: number;
        isWidthAdjustedForPadding: boolean;
    },
    headerImage?: IContentElementImage
}
