import { ITestDef } from '../../../ui-testrunner/sample-questions/data/sections';

export enum QuestionView {
    QUESTION_BANK = 'QUESTION_BANK',
    MSCAT_MODULES = 'MSCAT_MODULES',
    MSCAT_PANELS = 'MSCAT_PANELS',
    QUADRANTS = 'QUADRANTS',
    ASSEMBLED_FORMS = 'ASSEMBLED_FORMS',
    TESTLETS = 'TESTLETS',
    FORM_CONSTRUCTION = 'FORM_CONSTRUCTION',
    AUDITS = 'AUDITS',
    SAMPLE_FORMS = 'SAMPLE_FORMS',
    STYLE_PROFILE = 'STYLE_PROFILE'
  }
  
  export interface ISampleTestForm {
    currentTestDesign: ITestDef,
    questionSrcDb: Map<string, any>,
    questionStates: {[key: string]: any},
    testLang: string,
    isTestletPreview?: boolean
  };

  export enum EditViewMode {
    BEFORE = 'BEFORE',
    AFTER = 'AFTER'
  }