import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { IContentElementPassage, ImageData } from './model';
import { QuestionState } from '../models';
import { QuestionPubSub } from '../question-runner/pubsub/question-pubsub';
import { StyleprofileService } from 'src/app/core/styleprofile.service';
import { AuthScopeSettingsService } from 'src/app/ui-item-maker/auth-scope-settings.service';
import { LangService } from 'src/app/core/lang.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { TextToSpeechService } from '../text-to-speech.service';
import { IContentElementDynamicImage } from '../element-render-image/model';
import { TEST_RUNNER_WIDTH_EM } from '../element-render/element-render.component';
import { getWidth } from 'src/app/ui-item-maker/element-config-passage/element-config-passage.component';

const PARARGRAPH_SPACING = 1;
type ILine = {
  // identSpans:{
  //   str: string,
  //   width: number,
  // }[]
}
type ISegment = {
  // lines:ILine[], 
  str:string,
  isComplete?:boolean
  lineCount?: number
  isSkipLineCount?: boolean
}

type IDisplaySegment = {
  contentList?: ILineContent[], 
  isComplete?:boolean, 
  lineCount?:number, 
  isSkipLineCount?: boolean
  inLine?: boolean
}

type ILineContent = {
  content: SafeHtml | {image: IContentElementDynamicImage, styling: {} }, 
  isText: boolean
}
@Component({
  selector: 'element-render-passage',
  templateUrl: './element-render-passage.component.html',
  styleUrls: ['./element-render-passage.component.scss']
})
export class ElementRenderPassageComponent implements OnInit, AfterViewInit {

  @Input() element:IContentElementPassage;
  @Input() isLocked:boolean;
  @Input() isShowSolution:boolean;
  @Input() questionState:QuestionState;
  @Input() changeCounter:number;
  @Input() questionPubSub?: QuestionPubSub;

  images: {
    id: string, 
    imageData: ImageData
  }[] = [];
  lastTextSnapshot:string;

  segments:IDisplaySegment[];

  constructor(
    private lang:LangService,
    private styleProfile:StyleprofileService,
    private authScope: AuthScopeSettingsService,
    private sanitizer: DomSanitizer,
    public textToSpeech: TextToSpeechService,
  ) { }

  ngOnInit() {
    // this.updateRender();
    // this.profile.getStyleProfileChanges().subscribe((hasStyleProfile) => {
    //   if(hasStyleProfile) {
    //     this.updateRender();
    //   }
    // })
  }

  ngAfterViewInit(): void {
    this.images.forEach((img) => {
      this.addImgListener(img.id, img.imageData);
    })
  }

  ngOnDestroy(): void {
    // if(this.styleProfileChangeSub) {
    //   this.styleProfileChangeSub.unsubscribe();
    // }
  }

  getImageRefs(): Map<number, ImageData>{
    return new Map(
      this.element.images?.map((image) => [
        +image.id,
        {
          image: image.el,
          padding: image.padding,
          alignment: image.alignment,
        } as ImageData,
      ])
    );
  }

  processPStyle(htmlContent: string): string {
    const htmlContentSplit = htmlContent.split('\n')

    return htmlContentSplit.map(line => {
      let tabs:number;
      line = line.replace(/<pstyle id="(\d+)"\/?>/g, (match:string, id:string) => {
        for (let ps of this.element.paragraphStyles){
          if (+ps.id == +id){
            tabs = ps.tabs;
          }
        }
        return '';
      });
      if (tabs){
        line = line.replace('<t/>', () =>{
          return `<span style="display:inline-block;width:${tabs}em"></span>`
        })
      }
      return line;
    }).join('\n');
  }

  voiceover = null;
  getCurrentVoiceoverUrl() {
    return this.voiceover?.url || null;
  }

  clickTrigger = new Subject<boolean>();
  addImgListener(id: string, imageData: any){
    document.getElementById(id).addEventListener('click', () => {
      this.voiceover = imageData.voiceover
      this.clickTrigger.next(true);
    })
  }

  getheaderEnabled(){
    return this.element.isShowAdvancedOptions && this.element.header && this.element.header.caption.trim() != ""
  }

  getfooterEnabled(){
    return this.element.isShowAdvancedOptions && this.element.footer && this.element.footer.caption.trim() != ""
  }
  /**
   * 
   * @returns the passage styling based on element config
   */
  getPassageStyling(){
    let styles = {}
    if(this.element.border){
      const {width, color, padding} = this.element.border
      
      if(padding > 0){
        styles['padding'] = `${padding}em`
        if(this.isCounterAlignRight()){
          styles['padding-right'] = `${2+padding}em`
        }else{
          styles['padding-left'] = `${2+padding}em`
        }
      }
    }
  
    return styles
  }

  getBorderStyling() {
    let styles = {}
    if(this.element.border){
      const {width, color, padding} = this.element.border
      styles['border'] = `solid ${width}px ${color}`
    }
  
    return styles
  }
  
  getWidth() {
    return getWidth(this.element);
  }

  renderTextSegments(){
    const getBookmarkTag = (i, body: string, isParagraph?: boolean): string => {
      return `<div class="bookmark id-${isParagraph ? 'paragraph' : 'line'}_${i+1}">${body}</div>`
    }
    const snapshot = this.element._changeCounter+this.element.text
    if (this.lastTextSnapshot != snapshot){
      this.lastTextSnapshot = snapshot
      let htmlContent = this.element.text || '';
      // use custom small caps
      htmlContent = htmlContent.replace(/<sc\b[^>]*>(.*?)<\/sc>/g, '<span class="small-caps">$1</span>');
      // apply bookmark tags (and other style profile transforms)
      htmlContent = this.processPStyle(htmlContent);
      htmlContent = this.processBookmark(htmlContent);
      htmlContent = this.styleProfile.processBookmark(htmlContent);
      htmlContent = this.styleProfile.processBoldedMarkup(htmlContent);
      htmlContent = this.styleProfile.processItalicsMarkup(htmlContent);
      htmlContent = this.styleProfile.processTooltip(htmlContent);
      
      const segments:ISegment[] = [];
      let chunks:string[];
      const resetChunks = () => chunks = [];
      resetChunks();

      const htmlContentSplit = htmlContent.split('\n')
      let isSegmentCountSkip = false

      if (this.element.counterType === 'LINE'){
        let lineCount = 0
        for (let line of htmlContentSplit){
          // strippedLine holds the version of the line without bookmark tags to check for emptyness
          let strippedLine = line.replace(/<div class="bookmark id-[^>]+>/g, '');
          strippedLine = strippedLine.replace(/<\/div>/g, '');
          // bookmarkTag holds the original bookmark tag before it was replaced.
          const bookmarkTag = line.match(/<div class="bookmark id-[^>]+>/g)

          const isLineFilled = ((strippedLine || ' ').trim() != '');
          isSegmentCountSkip = !(isLineFilled || !this.element.isLineCountSkipBlank);
          line = line.replace(/<skip\/>/g, (match:string, id:string) => {
            isSegmentCountSkip = true
            return '';
          })

          // If the line is empty, an empty space needs to be added to be parsed by the HTML
          if(!isLineFilled && bookmarkTag.length > 0) {
            chunks.push(`${bookmarkTag[0]} </div>`)
          } else {
            chunks.push(line)
          }
          
          lineCount = this.getLineCount(isSegmentCountSkip, undefined, lineCount)

          if (lineCount % this.element.lineCountInterval == 0){
            segments.push({ 
              str: chunks.join('\n'), 
              isComplete:true, 
              isSkipLineCount: isSegmentCountSkip,
              lineCount: lineCount
            })
            resetChunks();
          }
        }
      }
      else if (this.element.counterType === 'PARAGRAPH'){
        for (let line of htmlContentSplit){
          let strippedLine = line.replace(/<div class="bookmark id-[^>]+>/g, '');
          strippedLine = strippedLine.replace(/<\/div>/g, '');
          
          line = line.replace(/<skip\/>/g, (match:string, id:string) => {
            isSegmentCountSkip = true
            return '';
          })

          const isBlankLine = strippedLine.trim() == ''
          
          if (!isBlankLine){
            chunks.push(line);
          }
          if (isBlankLine && chunks.length > 0){
              segments.push({
                str: chunks.join('\n'), 
                isComplete: true, 
                lineCount: this.getLineCount(isSegmentCountSkip, segments),
                isSkipLineCount: isSegmentCountSkip
              });
              resetChunks();
              isSegmentCountSkip = false
          }
        }
      }
      else if (this.element.counterType === 'NONE'){
        segments.push( {str:htmlContentSplit.join('\n')} )
      }
      // push whatever is left
      if (chunks.length){
        segments.push( {
          str: chunks.join('\n'),
          isComplete: (this.element.counterType === 'PARAGRAPH'), // if paragraph mode, then whereever the text ends is considered the end of the paragraph (dont need another set of spaces afterwards)
          isSkipLineCount: isSegmentCountSkip,
          lineCount: this.getLineCount(isSegmentCountSkip, segments)
        })
      }

      this.segments = this.processImages(segments)

    }
    return this.segments
  }

  /**
   * 
   * @param htmlContent html content as 1 single string
   * @returns returns a list of lines in the format of a list containing html or images.
   * @description splits up each line of of text when there is an image into separate segments and replaces image tag with image data to be used for populating an image block
   */
  processImages(segments: ISegment[]) {
    const bookmarkRegex = /<div class="bookmark id-[^>]+>/g
    const closingBookmarkTag = /<\/div>/g;

    const imageRef = this.getImageRefs()
    let openTags = []

    const result:IDisplaySegment[] = [];
    

    segments.forEach(segment => {
      const line = segment.str
      let remainingLine = line;
      const splits: ILineContent[] = [];
      let inLine = true
      while (true) {
        const match = /<img id="(\d+)">/.exec(remainingLine);

        if (match && imageRef.get(+match[1])) {
          const parts = remainingLine.split(match[0]);
          const image = imageRef.get(+match[1]) //get the image based off image id
          const imageStyle = this.getImageStyling(image)

          if(image.alignment !== 'none') inLine = false

          let stringToAdd = parts[0]
          if(openTags.length> 0){
            stringToAdd =openTags.join("") + stringToAdd 
          }

          const openMatches = parts[0].match(bookmarkRegex)
          if (openMatches) openTags.push(...openMatches)
          const closingTags = parts[0].match(closingBookmarkTag)
          if (closingTags) {
            // Needed to close the line div
            closingTags.push('</div>')
          }
      
          // remove the number of tags that are being closed
          for(const _tag in closingTags){
            if(openTags.length > 0){
              openTags.pop()
            }
          }
          // close any open tags
          stringToAdd = stringToAdd + "</div>".repeat(openTags.length)
          
          splits.push({content: this.sanitizer.bypassSecurityTrustHtml(stringToAdd), isText: true}, {content: {image: image.image, styling: imageStyle}, isText: false});
          remainingLine = parts[1];
        } else {
          const stringToAdd = openTags.join("") + remainingLine
          openTags = []

          splits.push({content: this.sanitizer.bypassSecurityTrustHtml(stringToAdd), isText: true} );
          break;
        }
      }

      result.push({
        contentList: splits,  
        isComplete: segment.isComplete,
        lineCount: segment.lineCount,
        isSkipLineCount: segment.isSkipLineCount,
        inLine: inLine
      });
    });

    return result;
  }

  /**
   * 
   * @param image the image to get styling for
   * @returns the css styles needed to be applied for that image
   */
  getImageStyling(image: ImageData): {}{
    let imageStyle = {}

    if(image.padding > 0) {
      if(image.alignment && image.alignment === 'right'){
        imageStyle['padding-left'] = `${image.padding}em`
      }else{
        imageStyle['padding-right'] = `${image.padding}em`
      }
    }

    if(image.alignment) {
      if(image.alignment === 'right'){
        imageStyle['float'] = 'right'
      }else if(image.alignment === 'left'){
        imageStyle['float'] = 'left'
      } else if(image.alignment === 'center'){
        imageStyle['justify-content'] = 'center'
        imageStyle['display'] = 'flex'
      }
    }

    return imageStyle
  }

  paragraphSpacing(){
    if (this.element.counterType == 'PARAGRAPH'){
      return PARARGRAPH_SPACING;
    }
    return 0
  }

  // default is right align
  isCounterAlignRight(){
    return !this.isCounterAlignLeft()
  }
  isCounterAlignLeft(){
    return (this.element.counterAlignment === 'left')
  }

  isLinesMode(){
    return (this.element.counterType == 'LINE')
  }
  isParagraphMode(){
    return (this.element.counterType == 'PARAGRAPH')
  }

  isShowCounter(){
    return (this.element.counterType !== 'NONE') && (+this.element.lineCountInterval > 0);
  }
  

  /**
   * 
   * @param isSegmentCountSkip boolean value indicating if we want to skip counting this line/paragraph
   * @param segments Used for paragraph counter type
   * @param lineCount Used for line counter type
   * @returns lineCount to be applied to line/paragraph
   * @description determines the line count that needs to be applied for 
   */
  getLineCount(isSegmentCountSkip:boolean, segments?:ISegment[], lineCount?:number) : number{
    if (this.element.counterType == 'PARAGRAPH'){
      if(isSegmentCountSkip){
        if(segments.length<1) return 0
        
        return segments[segments.length-1].lineCount
      }
      if(segments.length<1) return 1
      return segments[segments.length-1].lineCount + 1
    }
    else if (this.element.counterType == 'LINE'){
      if (!isSegmentCountSkip){
        lineCount += 1
      }
      return lineCount
    }
  }

  processBookmark(html: string): string {
    const passage = html.split('\n')
    let processedPassage = passage.map((line, i) => {
      return `<bookmark id="line_${i+1}">${this.openBookmarkTags(this.closeBookmarkTags(line))}</bookmark>`;
    });
    return processedPassage.join('\n');
  }

  lastTags: string[] = [];
  closeBookmarkTags(html: string): string {
    const closedTags = /<\/bookmark>/gs;
    const openTags = /<bookmark(.*?)>/gs;
    const htmlClosedTags = html.match(closedTags);
    const htmlOpenTags = html.match(openTags);
    if ((!htmlClosedTags && !htmlOpenTags) || (htmlClosedTags && !htmlOpenTags)) {
        return html;
    } else if(htmlOpenTags) {
        const closedArray = htmlClosedTags ? Array.from(htmlClosedTags) : [];
        const openArray = Array.from(htmlOpenTags);
        const openTagCount = openArray.length - closedArray.length;
        if(openTagCount <= 0) {
            return html;
        }

        this.lastTags.push(...openArray);
        const closeTags = Array.from({ length: openTagCount }, (_, index) => `</bookmark>`);
      
        return html + closeTags.join('');
    }

    return html;
  }

  openBookmarkTags(html: string): string { //need to account for closing tags
    const closedTags = /<\/bookmark>/gs;
    const openTags = /<bookmark(.*?)>/gs;
    const htmlClosedTags = html.match(closedTags);
    const htmlOpenTags = html.match(openTags);
    if (htmlOpenTags && !htmlClosedTags) {
        return html;
    } else if(!htmlClosedTags && !htmlOpenTags && this.lastTags.length > 0) {
        const openTags = this.lastTags.join('');
        const closeTags = Array.from({ length: this.lastTags.length }, (_, index) => `</bookmark>`).join('');
        return openTags + html + closeTags;
    } else if(htmlClosedTags) {
        const openArray = htmlOpenTags ? Array.from(htmlOpenTags) : [];
        const closedArray = Array.from(htmlClosedTags);
        const closeTagCount = closedArray.length - openArray.length;

        if(closeTagCount <= 0) {
            return html;
        }

        const openTags = [];
        for(let i=0;i<closeTagCount; i++) {
            openTags.push(this.lastTags.shift())
        }
        
        return openTags.join('') + html;
    }

    return html;
  }

}
