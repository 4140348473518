import { Injectable, OnDestroy } from '@angular/core';
import {AuthService} from '../api/auth.service';
import Sockette from 'sockette';
import { AccountType } from '../constants/account-types';
import { BehaviorSubject, fromEvent, Observable, Subject, Subscription } from 'rxjs';
import { RoutesService } from '../api/routes.service';
import { LoginGuardService } from '../api/login-guard.service';
import { LangService } from '../core/lang.service';
import { Router } from '@angular/router';
import { StudentAssistiveTechService } from './student-assistive-tech.service';
import { WhitelabelService } from '../domain/whitelabel.service';
import { ASSESSMENT } from '../ui-teacher/data/types';
import { STUDENT_LOGIN_VERIFICATION_TEACHER_ACTION } from '../ui-teacher/view-invigilate/view-invigilate.component';

const EDUCATOR_PING_INTERVAL = 30 * 1e3;
const HEARTBEAT_PING_INTERVAL  = 15 * 1e3;
const RECONNECT_TIMEOUT = 5 * 1e3;
const HEALTHCHECK_INTERVAL = 90 * 1e3;
const PING_TIMEOUT = 35 * 1e3;
const PING_TIMEOUT_MSG = 'ping-timeout';

enum WS_DISCONNECT_CODE {
  NORMAL_DISCONNECT = 1000,
  AWS_GONE_AWAY= 1001,
  DISCONNECT = 1005,
  ABNORMAL_DISCONNECT = 1006,
  PING_TIMEOUT = 3001
}

export interface IStudentPositionUpdate {
  uid: number,
  stageIndex?: number,
  questionIndex?: number,
  questionCaption?: string,
  submitConfig?: {
    submitted: boolean, 
    subSessionIndex: number
  },
  softLock?: number, // boolean // CLOSER_LOOK_20210807 incoming was number
  numTimesOffScreen?: number,
  testSessionId?: number
}
export interface ISubSessionStateInfo {
  available: boolean,
  caption: string,
  asmtSlug: ASSESSMENT,
  subSessionSlug: string,
  isSubmitting?: boolean,
  isPjPausingOtherSession?: boolean
}
export interface IStudentLoginIssue {
  uid: number,
  lastNameInput: string
}

export interface IStudentLoginIssueResolved {  //inform invigilator student login issue resolve
  uid: number,
}

export interface ITheStudentLoginIssueResolved { //inform student his/her login issue resolve
  uid: number,
  action: STUDENT_LOGIN_VERIFICATION_TEACHER_ACTION,
}

interface SocketQueueItem {
  action: string,
  data: any
}

@Injectable({
  providedIn: 'root'
})

export class StudentG9ConnectionService implements OnDestroy {
  private ws: Sockette;
  private classId;
  public userConnectionId: number // connection id to communicate through websocket of online user
  private userType: string;
  private apiHost;

  private wsURL: string ;

  private socketReady: boolean = false;
  private socketQueue: Array<SocketQueueItem> = [];

  public connectedStudentsSub = new BehaviorSubject<any[]>([]);
  public updateStudentPositionSub = new BehaviorSubject<IStudentPositionUpdate>(null);
  public disconnectedStudentsSub = new BehaviorSubject<any[]>([]);
  public autoSubmissionTeacherPopupSub = new Subject<any>();
  public studentLoginIssueSub = new BehaviorSubject<IStudentLoginIssue>(null);
  public studentLoginIssueResolveSub = new BehaviorSubject<IStudentLoginIssueResolved>(null); //inform invigilator student login issue resolve
  public theStudentLoginIssueResolveSub = new BehaviorSubject<ITheStudentLoginIssueResolved>(null); //inform student his/her login issue resolve
  public testSubmitted = new BehaviorSubject<boolean>(false);
  public subSessionStateChange = new BehaviorSubject<ISubSessionStateInfo>(null);
  public networkOfflineSub: Subscription = null;
  private networkOnlineSub: Subscription = null;
  private offlineEvent: Observable<Event>;
  private onlineEvent: Observable<Event>;
  pingInterval: NodeJS.Timeout;
  communicationTimeout: NodeJS.Timeout;
  educatorPingInterval: NodeJS.Timeout;
  healthCheckInterval: NodeJS.Timeout;
  
  constructor(
    private routes: RoutesService,
    private auth: AuthService,
    private loginGuard: LoginGuardService,
    private lang: LangService,
    private assisTech: StudentAssistiveTechService,
    private router: Router,
    private whiteLabel: WhitelabelService,
  ) {
    this.apiHost = this.whiteLabel.getApiAddress();
    // Constructor is called on other pages apart from invigilation and testrunner,
    // Don't put any logic here that may connect to the websocket on other pages
  }

  /**
   * Initializes the subscriptions for the network events
   * - If the network goes offline, the websocket is closed
   * - If the network comes back online, the websocket is reconnected
   * - Intializes the health check interval
   * @important should clear up the previous subscriptions before initializing new ones
   */
  private initSubscriptions() {
    if(this.networkOfflineSub) {
      this.networkOfflineSub.unsubscribe();
    }
    this.offlineEvent = fromEvent(window, 'offline');
    this.networkOfflineSub = this.offlineEvent.subscribe(e => {
      this.ws.close();
      this.ws = undefined;
      this.socketReady = false;
      this.clearWsIntervals();
      this.resetCommunicationTimeout();

      if (this.auth.u().uid && this.userConnectionId && this.classId) {
        this.logOffUser();
      }
    })

    if(this.networkOnlineSub) {
      this.networkOnlineSub.unsubscribe();
    }
    this.onlineEvent = fromEvent(window, 'online');
    this.networkOnlineSub = this.onlineEvent.subscribe(e => {
      this.connect();
      this.resetCommunicationTimeout();
    })

    this.auth.user().subscribe(user => {
      if (user === null || user === undefined)
        this.testSubmitted = new BehaviorSubject<boolean>(false); 
    });

    this.setHealthCheckInterval();
  }

  ngOnDestroy(): void{
    console.log('ws', 'ngOnDestroy')
    clearInterval(this.healthCheckInterval); // should be here and not in clearWsIntervals()
    this.disconnect();
  }

  resetCommunicationTimeout(pingInterval = PING_TIMEOUT) {
    if(this.communicationTimeout) {
      clearTimeout(this.communicationTimeout);
    }
    this.communicationTimeout = setTimeout(this.didLoseCommunication, pingInterval);
  }

  didLoseCommunication = () => {
    // If we have not heard from the WS server in 35 seconds, disconnect.
    if (this.ws) {
      console.log('ws', 'student close ws')
      this.ws.close(WS_DISCONNECT_CODE.PING_TIMEOUT, PING_TIMEOUT_MSG);
      this.ws = undefined;
      this.clearWsIntervals();
    }

    this.socketReady = false;
    this.resetCommunicationTimeout();
  }

  onTestSubmitted(){
    this.testSubmitted.next(true);
  }

  onUserInfoChange(info) {
    console.log('ws', 'onUserInfoChange', info)
    if(info?.accountType !== AccountType.STUDENT){
      return;
    }
    if(info) {
      const newClassId = this.auth.u().sch_class_group_id || info.sch_class_group_id;
      if (newClassId !== this.classId && this.isConnected()) {
        console.log('ws', 'disconnecting because classId changed')
        this.disconnect();
      }
      this.setClassId(newClassId);
      if(!this.isConnected()){
        this.connect();
      }
    } else if(!info && this.isConnected()){
      console.log('ws', 'disconnecting because user logged out')
      this.disconnect()
    }
  }

  setClassId(classId) {
    this.classId = classId;
  }

  async connect() {
    console.log('ws', 'connect()')
    this.initSubscriptions();

    this.userType = this.auth.user().value.accountType;
    if(this.userType != AccountType.STUDENT) {
      this.userType = AccountType.EDUCATOR;
    }

    if(this.isConnected()) {
      console.log('ws', 'disconnecting before connecting')
      this.disconnect(); 
    }
    this.setHealthCheckInterval();

    this.resetCommunicationTimeout();

    if(!this.wsURL){
      try {
        await this.getWebsocketUrl();
      } catch (err) {
        console.error('ws', err);
        return setTimeout(() => this.connect(), RECONNECT_TIMEOUT)
      }
    }

    this.ws = new Sockette(this.wsURL, {
      timeout: 10e3,
      onopen: async (ev:Event) => {
        this.onUserConnect()
      },
      onmessage: (ev:MessageEvent) => { this.onUserMessage(ev) },
      onreconnect: (ev:Event | CloseEvent) => {
        this.socketReady = false
        console.debug('ws', 'websocket trying to reconnect...', ev);
      },
      onmaximum: (ev: CloseEvent) => {
        console.debug('ws', 'websocket max connection attempts reached, giving up', ev);
        this.socketReady = false;
      },
      onclose: (ev: CloseEvent) => {
        this.logWebsocketInfo('WS_CLOSED', {
          code: ev.code,
          reason: ev.reason,
          wasClean: ev.wasClean,
          isTrusted: ev.isTrusted
        });

        this.socketReady = false
        this.onUserDisconnect(ev)
      },
      onerror: (ev: Event) => {
        this.logWebsocketInfo('WS_ERROR', {
          type: ev.type,
          timeStamp: ev.timeStamp,
          isTrusted: ev.isTrusted
        });
        this.socketReady = false
      }
    });

    if(this.userType == AccountType.EDUCATOR) {
      this.setEducatorPingInterval();
    }

    this.pingInterval = setInterval(() => {
      this.wsSend('ping',{
        ping: "Ping!",
        uid: this.auth.u().uid,
        userType: this.userType,
        classId: this.classId,
        apiHost: this.apiHost
      });
    }, HEARTBEAT_PING_INTERVAL);
  }

  /**
   * Pings every student in the class to see if they are still connected
   * If they are not, they are removed from the list of connected students
   * Chained, so that it will ping again after the timeout
   */
  private setEducatorPingInterval() {
    if(this.educatorPingInterval) {
      return; // Already set
    }
    this.educatorPingInterval = setInterval(() => {
      console.log('ws', 'educatorPing()')
      this.pingStudents();
    }, EDUCATOR_PING_INTERVAL)
  }

  private async pingStudents() {
    console.log('ws', `pingStudents(${this.classId})`)
    return await this.auth.apiUpdate(this.routes.EDUCATOR_WEBSOCKET_CONNECTED_USERS, this.classId, {});
  }

  private wsSend (action:string, data:any, forceSend? : boolean):void {
    if (this.socketReady || forceSend) {
      this.ws.json({ action, data });
      return;
    }
    this.socketQueue.push({ action, data });
  }

  private onSocketReady() {
    this.socketReady = true;

    while (this.socketQueue.length) {
      const { action, data } = this.socketQueue.shift();
      this.wsSend(action, data);
    }
  }

  disconnect() {
    console.log('ws', 'disconnect()')
    if(this.ws) {
      this.ws.close();
      this.ws = undefined;
      this.logOffUser();
    }
    this.clearWsIntervals();
    this.socketReady = false;
  }

  /**
   * Clears all intervals and timeouts related to the service
   */
  private clearWsIntervals() {
    clearInterval(this.pingInterval);
    clearTimeout(this.communicationTimeout);
    clearInterval(this.educatorPingInterval);
  }

  public logOffUser() {
    // shows user as offline
    const uid = this.auth.u().uid;
    this.auth.apiRemove(this.routes.EDUCATOR_WEBSOCKET_CONNECTED_USERS, uid, {query: {
      userType: this.userType,
      connectionId: this.userConnectionId,
      classId: this.classId
    }});
  }

  updateStudentPosition(data: Partial<IStudentPositionUpdate>) {
    // For ease of testing and deploying, alternating between dev and non-dev endpoints each time we release an update to the AWS code.
    // this.wsSend('studentUpdatePositionDev', data);
    const apiHost = this.apiHost;
    const classId = this.classId;

    const fullData = {...data, apiHost, classId}
    this.auth.apiCreate(this.routes.EDUCATOR_WEBSOCKET_STUDENT_SOFTLOCK, fullData);
    // this.ws.json({action: "studentUpdatePosition", data});
  }

  updateStudentSoftLock(data:{softLock:number}){
    this.wsSend('updateStudentSoftLock', data)
  }

  // updateStudentSoftLock(data:{softLock:boolean}){
  //   this.wsSend('updateStudentSoftLock',data)
  // }

  notifyStudentsOfSubsession(uids: number[], available: boolean, caption: string, asmtSlug: ASSESSMENT, subSessionSlug: string, isSubmitting?: boolean, isPjPausingOtherSession?:boolean) {
    // this.wsSend('teacherNotifyStudents', { uids, available, caption, asmtSlug, subSessionSlug, isSubmitting });
    this.auth.apiCreate(this.routes.EDUCATOR_WEBSOCKET_STUDENT_SUBSESSION_NOTIFICATION, {
      uids,
      available,
      caption,
      asmtSlug,
      subSessionSlug,
      isSubmitting,
      isPjPausingOtherSession,
      classId: this.classId,
      apiHost: this.apiHost
    })

    this.resetStudentPosition(uids)
  }

   onUserConnect() {
    console.log('ws', 'onUserConnect()')
    this.wsSend('studentConnectDev', {
      uid: this.auth.u().uid,
      userType: this.userType,
      classId: this.classId,
      apiHost: this.apiHost,
    }, true);
    this.socketReady = true;
    this.pingStudents();
  }

  private onUserDisconnect(ev:CloseEvent) {
    const validDisconnectCodes = [WS_DISCONNECT_CODE.PING_TIMEOUT, WS_DISCONNECT_CODE.AWS_GONE_AWAY, WS_DISCONNECT_CODE.ABNORMAL_DISCONNECT, WS_DISCONNECT_CODE.DISCONNECT];
    if (validDisconnectCodes.includes(ev.code)) {
      setTimeout(() => this.connect(), RECONNECT_TIMEOUT); // throttle reconnect attempts
    }
  }

  private onUserMessage(e) {
    if(e.data === `User ${this.auth.u().uid} Connected.`) {
      this.onSocketReady();
    }
    let eObj;

    try {
      eObj = JSON.parse(e.data);
    } catch(e) {
      //Don't set eObj
    }
    if(!eObj) {
      return;
    }

    if (Object.keys(eObj).includes('connectionId')) {
      this.userConnectionId = eObj.connectionId;
    }

    this.resetCommunicationTimeout();
    switch (this.userType) {
      case AccountType.STUDENT:
        this.onStudentMessage(eObj);
        break;
      case AccountType.EDUCATOR:
        this.onTeacherMessage(eObj);
        break;
    }
  }

  private resetStudentPosition(uids) {
    for(let uid of uids) {
      this.updateStudentPositionSub.next({
        uid: uid,
      });
    }
  }

  private onTeacherMessage(e) {
    switch(e.eventType) {
      case 'updateConnectedStudents':
        this.connectedStudentsSub.next(e.connectedStudents);
        break;
      case 'updateStudentPosition':
        this.updateStudentPositionSub.next({
          uid: e.uid,
          softLock: e.softLock,
          stageIndex: e.stageIndex,
          questionIndex: e.questionIndex,
          questionCaption: e.questionCaption,
          submitConfig: e.submitConfig,
          numTimesOffScreen: e.numTimesOffScreen,
          testSessionId: e.testSessionId
        });
        break;
      case 'updateDisconnectedStudents':
        this.disconnectedStudentsSub.next(e.msg)
        break;
      case 'autoCloseWarning':
        this.autoSubmissionTeacherPopupSub.next({testWindow:e.testWindow, testSession:e.testSession})
        break
      case 'refreshPage':
        window.location.reload();
        break;
      case 'studentLoginIssue':
        this.studentLoginIssueSub.next({
          uid: e.uid,
          lastNameInput: e.lastNameInput
        });
        break;
      case 'studentLoginIssueResolved':
        this.studentLoginIssueResolveSub.next({
          uid: e.student_uid,
        });
        break;  
    }
  }

  private onStudentMessage(e) {
    switch(e.eventType) {
      case 'notifyAssessmentAvailable':
        if (!e.isSubmitting) {
          this.showAssessmentPopup(e.available, e.caption, e.asmtSlug, e.subSessionSlug, e.isPjPausingOtherSession);
        }
        this.subSessionStateChange.next({
          available: e.available,
          caption: e.caption,
          asmtSlug: e.asmtSlug,
          subSessionSlug: e.subSessionSlug,
          isSubmitting: e.isSubmitting,
          isPjPausingOtherSession: e.isPjPausingOtherSession
        })
        break;
      case 'ping':
        break;
      case 'forceLogout':
        this.forceLogout();
        break;
      case 'studentLoginIssueResolved':
        this.theStudentLoginIssueResolveSub.next({
          uid: e.student_uid,
          action: e.action
        })
        break;  
    }
  }

  forceLogout() {
    this.auth.logout().then(() => {
      this.loginGuard.confirmationReqActivate({caption: this.lang.tra('lbl_logged_in_on_other_device'),
      btnCancelConfig: {hide: true}, confirm: () => {
        if (this.whiteLabel.getSiteFlag('IS_BCED')){
          this.router.navigate(['/en/bced-landing/admin']);
        }
        else{
          this.router.navigate(['/en/login-router-st']);
        }
      } });
    });
  }

  showAssessmentPopup(available: boolean, caption: string, asmtSlug?: ASSESSMENT, subSessionSlug?: string, isPjPausingOtherSession?: boolean) {
    const sessionState = this.lang.tra(available ? (caption === 'Q' ? "questionnaire_opened" : 'session_opened') : (caption === 'Q' ? "questionnaire_closed" : 'session_closed'));
    let messageSlug;
    if(asmtSlug === ASSESSMENT.G9_SAMPLE) {
      messageSlug = 'sample_test_opened_closed';
    } else if(asmtSlug === ASSESSMENT.OSSLT_SAMPLE) {
      messageSlug = 'practice_test_opened_closed';
    } else if(asmtSlug === ASSESSMENT.G9_OPERATIONAL && caption !== 'Q') {
      messageSlug = 'g9_session_opened_closed';
    } else if(asmtSlug === ASSESSMENT.PRIMARY_SAMPLE || asmtSlug === ASSESSMENT.PRIMARY_OPERATIONAL || asmtSlug === ASSESSMENT.JUNIOR_SAMPLE || asmtSlug === ASSESSMENT.JUNIOR_OPERATIONAL) {
      if(subSessionSlug && subSessionSlug.startsWith("lang")) {
        messageSlug = 'pj_lang_session_opened_closed';
      } else if(subSessionSlug && subSessionSlug.startsWith("math")) {
        messageSlug = 'pj_math_stage_opened_closed';
      } else if (caption === 'Q') {
        messageSlug = 'test_session_Q_opened_closed';
      } 
      if (isPjPausingOtherSession){
        const isSampleAssessment = asmtSlug === ASSESSMENT.PRIMARY_SAMPLE || asmtSlug === ASSESSMENT.JUNIOR_SAMPLE
        messageSlug = isSampleAssessment ? 'pj_session_paused_operational' : 'pj_session_paused_sample'
      }
    } else if (caption === 'Q') {
      messageSlug = 'test_session_Q_opened_closed';
    } else {
      messageSlug = 'test_session_opened_closed';
    }

    if (!available && this.auth.u()?.sch_class_group_type === 'EQAO_G9' || this.auth.u()?.sch_class_group_type === 'EQAO_G10') {
      // if(!this.assisTech.studentAssistiveTechStatus){ // possibly removed in CLOSER_LOOK_20210807 or the change was just not here yet
        // this.router.navigateByUrl(`${this.auth.getDashboardRoute(this.lang.c())}/main`)
      // }
      this.router.navigateByUrl(`${this.auth.getDashboardRoute(this.lang.c())}/main`)
    }

    const message = this.lang.tra(messageSlug, undefined, {caption, sessionState})
    this.loginGuard.quickPopup(message);

    //Acknowledge that the notification was received
    this.wsSend('studentAcknowledge', { uid: this.auth.u().uid })
  }

  isConnected():boolean {
    return !!this.ws;
  }

  private async getWebsocketUrl() {
    return this.auth.apiFind(this.routes.EDUCATOR_WEBSOCKET_CONNECTED_USERS, {}).then((res) => {
      console.log('ws', 'getWebsocketUrl()', res.websocketUrl)
      this.wsURL = 'wss://' + res.websocketUrl;
    })
  }

  public async logWebsocketInfo(slug, event) {
    console.log('ws', slug, event)

    return await this.auth.apiCreate(this.routes.LOG, {
      slug,
      data: {
        event,
        classId: this.classId,
        apiHost: this.apiHost,
        uid: this.auth.u().uid,
        userType: this.userType,
        userConnectionId: this.userConnectionId,
        wsURL: this.wsURL,
        connected: this.isConnected(),
      }
    })
  }

  public async setHealthCheckInterval() {
    if(this.healthCheckInterval) {
      return;
    }

    this.healthCheckInterval = setInterval(async ()=> {
      if(!this.isConnected()){
        await this.logWebsocketInfo('WS_HEALTHCHECK_FAIL', {});
        this.connect();
      }
    }, HEALTHCHECK_INTERVAL)
  }
}
