import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { IContentElementFrameStateStyle } from 'src/app/ui-testrunner/element-render-frame/model';
import { EditingDisabledService } from '../editing-disabled.service';

@Component({
  selector: 'frame-config',
  templateUrl: './frame-config.component.html',
  styleUrls: ['./frame-config.component.scss']
})
export class FrameConfigComponent implements OnInit {

  @Input() element: IContentElementFrameStateStyle;

  constructor(private editingDisabled: EditingDisabledService) { }

  elementTypeForInsertion = new FormControl('0');

  ngOnInit() {
    
  }

  isReadOnly() {
    return this.editingDisabled.isReadOnly();
  }

}