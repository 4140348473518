import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { IAudioSrcInfo } from './render-audio/render-audio.component';

@Injectable({
  providedIn: 'root'
})
export class TextToSpeechService {

  public isActive:boolean = false;
  private _isHiContrast: boolean = false;
  public isHighContrastSub = new BehaviorSubject<boolean>(this._isHiContrast);
  public onPlay: Subject<IAudioSrcInfo> = new Subject();


  public get isHiContrast() : boolean {
    return this._isHiContrast;
  }

  constructor() { }

  toggle(){
    this.isActive = !this.isActive;
  }

  hi_contrast_toggle(){
    this._isHiContrast = !this._isHiContrast
    this.isHighContrastSub.next(this._isHiContrast)
  }

  hi_contrast_off() {
    this._isHiContrast = false;
    this.isHighContrastSub.next(this._isHiContrast)
  }

}
