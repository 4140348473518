<div>
  <div class="control is-expanded" *ngIf="baseElement && displayImageControls && isThisOrInverseImage()">
    <div class="select is-fullwidth" >
      <select [(ngModel)]="baseElement.mode" (ngModelChange)="modelChange()">
        <option *ngFor="let m of modes; let index = index" [value]="m.id">
          {{m.caption}}
        </option>
      </select>
    </div>
    <label>
      <input type="checkbox" [(ngModel)]="baseElement.isHiContrastSensitive" />
      &nbsp;<tra slug="auth_change_pic_high_contrast"></tra>
    </label><br>
    <label>
      <input type="checkbox" [(ngModel)]="baseElement.isNoInvertOnHiContrast"/>
      &nbsp;<tra slug="auth_avoid_inversion_on_contrast"></tra>
    </label>
  </div>
  <div *ngIf="getUrl()">
    <div class="simple-form-container" style="margin-bottom:1em;" *ngIf="!isRequestingReupload && !isNoScale">
      <fieldset [disabled]="isReadOnly()">
        <label >   Scale  <num-scrub [el]="element" prop="scale" [step]="1" [max]="100" (change)="change.emit();"></num-scrub></label>
      </fieldset>
    </div>
    <div [ngSwitch]="fileType">
      <div *ngSwitchCase="'image'" style="position:relative;">
        <!-- [ngStyle]="{'max-width.%': element.scale}" -->
        <img [class.no-pointer-events]="isReadOnly()" [src]="getImageUrl()"  (click)="isRequestingReupload = !isRequestingReupload" class="uploaded-image" [class.is-shrunken]="isRequestingReupload"/>
        <div class="hide-on-hover" style="position: absolute; top:0em; left:0em; margin-bottom: 0.5em; font-size:0.8em; padding:0.2em 0.5em; background-color:rgba(255,255,255,0.8); font-weight:600;">
          Image Type: {{getFileType()}}
        </div>
      </div>
      <div *ngSwitchCase="'video'">
        <video #player id="player" playsinline controls> 
          <source [src]="getImageUrl()" [type]="currentElement.fileType">
        </video>
      </div>
      <div *ngSwitchCase="'audio'">
        <audio controls>
          <source [src]="getImageUrl()" [type]="currentElement.fileType">
        </audio>
      </div>
      <div *ngSwitchCase="'Graphics'">
        <img [src]="getImageUrl()" [ngStyle]="{'max-width.%': currentElement.scale}" (click)="isRequestingReupload = !isRequestingReupload" class="uploaded-image" [class.is-shrunken]="isRequestingReupload"/>
      </div>
      <div *ngSwitchDefault>
        <a [class.is-disabled]="isReadOnly()" [href]="getUrl()" target="_blank">{{getUploadedMeta().filename}}</a>
        <a *ngIf="!this.currentElement.assetId" [class.is-disabled]="isReadOnly()" (click)="clearUrl()" style="color:#f00; margin-left:0.5em; font-size:0.85em;"><tra slug="ie_delete_param"></tra></a><br>
        <a *ngIf="fileType=='vtt'" target="_blank" [href]="'https://nikse.dk/SubtitleEdit/'"> Subtitle Editor</a>
      </div>
    </div>
    <fieldset [disabled]="isReadOnly()">
      <div *ngIf="fileType === 'image' || fileType === 'Graphics'">
        <div *ngIf="displayAltText">
          <textarea *ngIf="!currentElement.assetId" placeholder="Alt-text for the visually impaired" rows="1" class="textarea is-small" [formControl]="altText"></textarea>
          <textarea *ngIf="currentElement.assetId" placeholder="Alt-text for the visually impaired" rows="1" class="textarea is-small" [formControl]="altText" disabled></textarea>
          <button *ngIf="currentElement.assetId" (click)="editAltText()">Edit Alt Text</button>
        </div>
        <button (click)="clearUrl()">Clear</button>
      </div>
    </fieldset>
  </div>
    
  <!-- <div *ngIf="isWaitingForUpload() || allowReupload">
    <div *ngIf="isUploadsDisabled">
      <input type="text" placeholder="Upload URL (external)" class="input" [formControl]="url">
    </div>
    <div *ngIf="!isUploadsDisabled">
      <div *ngIf="isCondensed">
          <div class="file" > -->
  <fieldset [disabled]="isReadOnly()">
    <div *ngIf="isWaitingForUpload() || allowReupload">
      <!-- CLOSER_LOOK_20210807 bulk exporter disabled? -->
      <div *ngIf="isUploadsDisabled"> 
        <input type="text" placeholder="Upload URL (external)" class="input" [formControl]="url">
      </div>
      <div *ngIf="!isUploadsDisabled">
        <div *ngIf="isCondensed">
            <div class="file" >
                <label class="file-label" [class.no-pointer-events]="isReadOnly()">
                  <input [disabled]="isReadOnly()" class="file-input" type="file" (change)="startUpload($event.target.files)">
                  <span class="file-cta" [class.is-disabled]="isReadOnly()">
                    <span class="file-icon">
                      <i class="fa fa-upload"></i>
                    </span>
                    <span class="file-label">
                      {{uploadCaption}}
                    </span>
                  </span>
                </label>
            </div>
        </div>
        
        <div *ngIf="!isCondensed">
          <div [class.no-pointer-events]="isReadOnly()" class="dropzone" dropZone (hovered)="toggleHover($event)" (dropped)="startUpload($event)" [class.hovering]="isHovering">
            <p><tra slug="auth_dnd_file"></tra></p>
            <div class="file">
              <label class="file-label">
                <input [disabled]="isReadOnly()" class="file-input" type="file" (change)="startUpload($event.target.files)">
                <span class="file-cta">
                  <span class="file-icon">
                    <i class="fa fa-upload"></i>
                  </span>
                  <span class="file-label">
                    <tra slug="auth_or_choose_file"></tra>
                  </span>
                </span>
              </label>
            </div>
          </div>
          <div>
            <button (click)="promptImgUrl()" class="button is-small">or paste image link</button>
          </div>
        </div>
        <div *ngIf="percentage | async as pct">
          <progress class="progress is-info" [value]="pct" max="100">
          </progress>
          {{ pct | number }}%
        </div>
      </div>
    </div>  
  </fieldset>
</div>
