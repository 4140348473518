import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { HyperlinkService } from '../hyperlink.service';
import { ScoringTypes } from '../models';
import { QuestionPubSub } from '../question-runner/pubsub/question-pubsub';
import { IContentElementTextLink } from './model';



const SCORING_TYPE = ScoringTypes.AUTO;

@Component({
  selector: 'element-render-text-link',
  templateUrl: './element-render-text-link.component.html',
  styleUrls: ['./element-render-text-link.component.scss']
})
export class ElementRenderTextLinkComponent implements OnInit, OnChanges {

  @Input() element:IContentElementTextLink;
  @Input() isLocked:boolean;
  @Input() isShowSolution:boolean;
  @Input() changeCounter:number;
  @Input() questionState:any;
  @Input() questionPubSub?: QuestionPubSub;
  @Input() isHighContrast: boolean = false;
  @Input() isMainUI:boolean;
  @Output() isLinkToggled = new EventEmitter();

  textLinkCaption?: string;
  
  constructor(
    private hyperLinkService: HyperlinkService
    ) { }

  ngOnInit() {
    // console.log(this.element)
    this.textLinkCaption = this.getTextLinkCaption();
  }
 
  getTextLinkCaption = () => this.element.readingSelectionCaption ? this.element.readingSelectionCaption : this.element.caption;

  openLink(){
      const link = this.hyperLinkService.linkRequest.getValue();
      this.isLinkToggled.emit(true);
      const isAlreadyActive = (link.readerId === this.element.readerId && link.readerId && this.element.readerElementId===link.readerElementId) 
        || (link.itemLabel === this.element.itemLabel && this.element.itemLabel)
      if (isAlreadyActive) {
        this.hyperLinkService.linkRequest.next({
          isClick: true,
          readerElementId: undefined,
          readerId: undefined,
          itemLabel: undefined,
          isMainUI: this.isMainUI,
          linkCaption: this.textLinkCaption,
        })
      } 
      else if (this.element.itemLabel) {
        this.hyperLinkService.linkRequest.next({
          isClick: true,
          readerElementId: undefined,
          readerId: undefined,
          itemLabel: this.element.itemLabel,
          isMainUI: this.isMainUI,
          linkCaption: this.textLinkCaption,
        })
      }  else {
        this.hyperLinkService.linkRequest.next({
          isClick: true,
          readerElementId: this.element.readerElementId,
          readerId: this.element.readerId,
          itemLabel: undefined,
          isMainUI: this.isMainUI,
          linkCaption: this.textLinkCaption,
        })
      }
  }

  ngOnChanges(changes:SimpleChanges){
    this.textLinkCaption = this.getTextLinkCaption();
  }

}
