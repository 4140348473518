<div style="display:flex; flex-direction:row; align-items:center;">
    <span *ngIf="state.length>0" style="margin-right:1em;">
        <tra [slug]="caption"></tra>
    </span>
    <button 
        *ngFor="let toggle of state"
        class="button"
        (click)="selectToggle(toggle)"
        [class.is-info]="isSelected(toggle)"
    >
        <tra [slug]="toggle.caption"></tra>
    </button>
</div>