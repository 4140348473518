export enum KNOWN_TEST_RUNNER_TAGS {
    NO_LEFT_COLLAPSE = 'NO_LEFT_COLLAPSE',
    ALLOW_LEFT_COLLAPSE = 'ALLOW_LEFT_COLLAPSE',
    QUESTIONS_FILLED_PROGRESS_BAR = 'QUESTIONS_FILLED_PROGRESS_BAR',
    OSSLT = 'OSSLT',
    KEEP_READING_OPEN = 'KEEP_READING_OPEN',
    NO_SUBMIT_CONF = 'NO_SUBMIT_CONF',
    NO_FLAGGING = 'NO_FLAGGING',
    PJ_LANG = 'PJ_LANG',
    PJ_MATH = 'PJ_MATH',
    SHOW_AMBLE_TOOLBAR = 'SHOW_AMBLE_TOOLBAR',
    NO_HELP_PAGE = 'NO_HELP_PAGE',
    G9 = 'G9',
    OSSLT_TOOLS = 'OSSLT_TOOLS',

}