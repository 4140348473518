import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LangService } from 'src/app/core/lang.service';
import { WhitelabelService } from 'src/app/domain/whitelabel.service';
import { etz, tzLangAbbrs } from 'src/app/core/util/moment';
import { AuthService } from 'src/app/api/auth.service';
import { RoutesService } from 'src/app/api/routes.service';

@Component({
  selector: 'top-banner',
  templateUrl: './top-banner.component.html',
  styleUrls: ['./top-banner.component.scss']
})
export class TopBannerComponent implements OnInit {
  
  showDowntimeBanner:boolean = false;
  startDateTime:string; 
  endDateTime:string;
  supportEmail:string;
  supportPhoneNum:string;
  
  constructor(
    private http: HttpClient,
    public lang: LangService,
    private auth: AuthService,
    private routes: RoutesService,
    private whitelabel: WhitelabelService
  ) { }

  ngOnInit(): void {
    this.getBennerInfo()
  }

  /**
   * Get banner info to be displayed
   * banner_on: ON/OFF info - this control the display of the top banner
   * start_on: in UTC format YYYY-MM-DDThh:mm:ssZ
   * end_on: in UTC format YYYY-MM-DDThh:mm:ssZ
   * phone: support phone number
   * email: support email
   */ 
  getBennerInfo(){
    const s3link = this.whitelabel.getSiteText('loginTopBannerS3Link')
    this.http.get(s3link)
    .subscribe((data:any) =>{
      if(data.banner_on){
        this.showDowntimeBanner = data.banner_on
        this.startDateTime = data.start_on
        this.endDateTime = data.end_on
        this.supportEmail = data.email
        this.supportPhoneNum = data.phone
      }
    }, error => {
      console.log(error.message)
      this.showDowntimeBanner = false

      // Log the failed fetch event
      this.auth.apiCreate(this.routes.LOG, {
        slug: 'CLIENT_FETCH_ERROR_TOP_BANNER',
        data: {
          requestURL: s3link,
          error: error.message
        }
      })
    })
  }


  /**
   * Construct banner message and convert datetime into eastern timezone
   * @return {string} landing page top banner downtime message
   */
  getBannerMsg(){
    let startDate = etz(this.startDateTime).format(this.lang.tra('datefmt_login_top_banner'));
    let endDate = etz(this.endDateTime).format(this.lang.tra('datefmt_login_top_banner'));
    if(this.lang.c() === 'fr'){
      startDate = tzLangAbbrs(startDate, 'fr')
      endDate = tzLangAbbrs(endDate, 'fr')
    }
    return this.lang.tra('banner_downtime_msg', "", {
      startDateTime: startDate, 
      endDateTime: endDate, 
      supportEmail: this.supportEmail, 
      supportPhone: this.supportPhoneNum
    })
  }
}
