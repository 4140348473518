import {Directive, ElementRef, HostListener, Input, OnDestroy } from '@angular/core'
import {AudioBufferService, VoiceSound} from '../ui-testrunner/audio-buffer.service';
import { LangService } from '../core/lang.service';
import { TextToSpeechService } from '../ui-testrunner/text-to-speech.service';
import { replaceS3Domains } from '../core/util/transform';

@Directive({
    selector: "[audio-slug]"
})

export class AudioDirective implements OnDestroy  {

    @HostListener('mouseout') @HostListener('click') @HostListener('blur') onBlur = () => {
        if(this.sound){
            this.sound.stop()
        }
    }

    @HostListener('mouseover') @HostListener('focus') onFocus = () => {
        this.activate()
    }

    @Input() slug:string
    @Input() url:string
    @Input() autorun:boolean

    constructor(
        private el: ElementRef,
        private audioBuffer: AudioBufferService,
        private lang: LangService,
        private textToSpeech: TextToSpeechService
    ){
        this.el.nativeElement.tabIndex = 0;
        try {
            this.directSlug = this.el.nativeElement.getAttribute('slug');
        }
        catch(e){}
        setTimeout(()=>{
            if (this.autorun) {
                this.activate();
            }
        }, 0)
    }

    private sound: VoiceSound;
    directSlug = this.el.nativeElement.getAttribute('slug')
    NEW_TRA_DB = require('../../../data/translations.json');



    private renderAudioUrl(){ // excluded on CLOSER_LOOK_20210807 inbound
        let slug = this.directSlug || this.slug;
        if (this.url){
            return replaceS3Domains(this.url);
        }
        else if (slug) {
            return this.lang.traVoice(slug);
        } 
        else {
            const targetElement = this.el.nativeElement.children[0];
            if (targetElement.getAttribute('ng-reflect-slug')) {
                this.slug = targetElement.getAttribute('ng-reflect-slug')
            } 
            else {
                this.slug = targetElement.getAttribute('slug')
            }
            return this.lang.traVoice(this.slug);
        }
    }

    // CLOSER_LOOK_20210807
    // CA: I restructured a while ago to make it easier to read what it is doing, but this does not look like a good idea to me (overriding the translation database).
    private overrideTranslationEntry(){   // excluded on CLOSER_LOOK_20210807 inbound
        let slug = this.directSlug || this.slug;
        const key = slug + '#script';
        ['en', 'fr'].forEach(langCode => {
            const str = this.lang.tra(slug, langCode);
            return this.lang.overrideLangEntry(key, str, langCode);
        })

        // if (this.directSlug || this.slug) {
        //     if (this.lang.c() === 'en') {
        //         if (this.directSlug) {
        //             audioPlayer.src = this.NEW_TRA_DB.en_voice[`${this.directSlug}`]
        //         } else {
        //             audioPlayer.src = this.NEW_TRA_DB.en_voice[`${this.slug}`]
        //         }
        //     } else {
        //         if (this.directSlug) {
        //             audioPlayer.src = this.NEW_TRA_DB.fr_voice[`${this.directSlug}`]
        //         } else {
        //             audioPlayer.src = this.NEW_TRA_DB.fr_voice[`${this.slug}`]
        //         }
        //     }
        // } else {
        //     if (this.el.nativeElement.children[0].getAttribute('ng-reflect-slug')) {
        //         this.slug = this.el.nativeElement.children[0].getAttribute('ng-reflect-slug')
        //     } else {
        //         this.slug = this.el.nativeElement.children[0].getAttribute('slug')
        //     }
        //     if (this.lang.c() === 'en') {
        //         audioPlayer.src = this.NEW_TRA_DB.en_voice[`${this.slug}`]
        //     } else {
        //         audioPlayer.src = this.NEW_TRA_DB.fr_voice[`${this.slug}`]
        //     }
        // }
        // document.getElementById('top-level').appendChild(audioPlayer);
        // this.sound.play();

        // if (this.directSlug) {
        //     this.NEW_TRA_DB.en[`${this.directSlug + '#script'}`] = this.lang.tra(this.directSlug)
        //     this.NEW_TRA_DB.fr[`${this.directSlug + '#script'}`] = this.lang.tra(this.directSlug, 'fr')
        //     // console.log(this.NEW_TRA_DB.fr[`${this.directSlug + '#script'}`], 'scriptslug')
        //     // console.log(this.NEW_TRA_DB.fr[`${this.directSlug}`], 'ogslug')
        // } else {
        //     this.NEW_TRA_DB.en[`${this.slug + '#script'}`] = this.lang.tra(this.slug)
        //     this.NEW_TRA_DB.fr[`${this.slug + '#script'}`] = this.lang.tra(this.slug, 'fr')
        // }
    }

    ngOnDestroy() {
        this.removeCurrentAudioElement();
    }

    removeCurrentAudioElement(){
        const containerNode = document.getElementById('top-level');
        try { 
            if (this.sound){
                const prevEl = this.sound.getElement();
                if (prevEl){
                    containerNode.removeChild(prevEl);
                }
            }
        }
        catch (e){}
    }

    private activate() {
        if(!this.textToSpeech.isActive){
            return
        }
        this.removeCurrentAudioElement();
        const containerNode = document.getElementById('top-level');
        let audioPlayer = document.createElement('audio');
        this.sound = new VoiceSound(audioPlayer, this.audioBuffer, false);
        audioPlayer.id       = 'audio-player';
        audioPlayer.controls = false;
        audioPlayer.src = this.renderAudioUrl();
        containerNode.appendChild(audioPlayer);
        this.sound.play();

        this.overrideTranslationEntry(); // CA: not sure why this is being done...
    }
}