import { IMenuTabConfig } from "../../../ui-partial/menu-bar/menu-bar.component";

export interface IView<T> extends IMenuTabConfig<T> {
    imgUrl: string,
    description: string,
    hasIndicator?: boolean,
    disabled?: boolean
}

export enum BCGRADMinistryAdminView {
    DASHBOARD = 'dashboard',
    ASSESSMENTS = 'assessments',
    ASSESSMENT_MATERIALS = 'assessment_materials',
    STUDENT_ENROLLMENT_INFO_AND_ACCOUNTS = 'accounts',
    SESSION_PROGRESS_REPORTS = 'session_reports',
    AEGROTAT_DISQUALIFICATION = 'aegrotat_disqualification',
    SCANNING = 'scanning',
    MARKING = 'marking',
    FINAL_RESULTS = 'final_results'
}

export const BC_GRAD_MINISTRY_ADMIN_VIEWS = [
    {
        id: BCGRADMinistryAdminView.ASSESSMENTS,
        caption: ('Assessments'), //'Assesements',
        imgUrl: 'https://eqao.vretta.com/authoring/user_uploads/6388/authoring/assessments/1610134215488/assessments.svg',
        description: ('Use this page to review past and current sessions and schedule upcoming sessions.'),
        disabled: false,
    },
    {
        id: BCGRADMinistryAdminView.ASSESSMENT_MATERIALS,
        caption: ('Assessment Materials'), //'Assesement Materials',
        imgUrl: 'https://eqao.vretta.com/authoring/user_uploads/6388/authoring/assessment_materials/1610134259593/assessment_materials.svg',
        description: ('Use this page to review materials such as the assessment key, administration manual, parent brochures, and more.'),
        disabled: true
    },
    {
        id: BCGRADMinistryAdminView.STUDENT_ENROLLMENT_INFO_AND_ACCOUNTS,
        caption: ('Student Registrations & District/School Accounts'), //'Student Enrollment Information & District/School Accounts',
        imgUrl: 'https://eqao.vretta.com/authoring/user_uploads/6388/authoring/student_info/1610134278044/student_info.svg',
        description: ('Use this page to review and edit Student information, as well as send invitations to school administrators and district administrators for account access.'),
        disabled: false
    },
    {
        id: BCGRADMinistryAdminView.SESSION_PROGRESS_REPORTS,
        caption: ('Session Reports'), //'Session Progress Report',
        imgUrl: 'https://eqao.vretta.com/authoring/user_uploads/6388/authoring/session_progress_reports/1610134294563/session_progress_reports.svg',
        description: ('Use this page to review session reports.')
    },
    {
        id: BCGRADMinistryAdminView.AEGROTAT_DISQUALIFICATION,
        caption: ('Add Aegrotat or Disqualification'), //'Add Aegrotat or Disqualification',
        imgUrl: 'https://eqao.vretta.com/authoring/user_uploads/6388/authoring/aegrotat/1625675265719/aegrotat.svg',
        description: ('Use this page to add aegrotat or disqualification.')
    },
    {
        id: BCGRADMinistryAdminView.SCANNING,
        caption: ('Printing & Uploading Response Sheets'), //'Scanning',
        imgUrl: 'https://eqao.vretta.com/authoring/user_uploads/6388/authoring/scanning/1610134310674/scanning.svg',
        description: ('Use this page to upload, download, and review student response sheets.'),
        disabled: false
    },
    {
        id: BCGRADMinistryAdminView.MARKING,
        caption: ('Marking'), //'Marking',
        imgUrl: 'https://eqao.vretta.com/authoring/user_uploads/6388/authoring/marking/1610134324629/marking.svg',
        description: ('Use this page to review and validate the Marking session.'),
        disabled: true
    },
    {
        id: BCGRADMinistryAdminView.FINAL_RESULTS,
        caption: ('Final Results'), //'Final Results',
        imgUrl: 'https://eqao.vretta.com/authoring/user_uploads/6388/authoring/final_results/1610134339358/final_results.svg',
        description: ('Use this page to review the final results of the secure assessments.'),
        disabled: false,
    }
]
