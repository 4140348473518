import { IPanelLink } from "./type";

export const teacherPanels:IPanelLink[] = [
    {
        caption: 'lbl_classes_groups', // Class Sections
        routerLink: '/en/educator/classrooms',
        requiresClassroom: false,
        iconUrl: '../../../../assets/icons/icons-classroom.png',
    },
    {
        caption: 'g9_edu_Assess', // Assessments
        routerLink: '/en/educator/classrooms/:classroomId',
        requiresClassroom: true,
        iconUrl: '../../../../assets/icons/icons-assessment.png',
    },
    // {
    //     caption: 'Booster Packs',
    //     routerLink: '/teacher/classroom/:classroomId/boosterpacks',
    //     requiresClassroom: true,
    //     iconUrl: 'https://firebasestorage.googleapis.com/v0/b/calculating-cats.appspot.com/o/website%2Ficons%2Ficons-boosterpacks.png?alt=media&token=2142f93e-c2a5-4eaf-9465-4af843253153'
    // },
    {
        caption: 'g9_edu_stu', // Students
        routerLink: '/en/educator/students/:classroomId',
        requiresClassroom: true,
        iconUrl: '../../../../assets/icons/icons-students.png',
    },
    // {
    //     caption: 'g9_edu_reports',
    //     routerLink: '/en/educator/classrooms/:classroomId',
    //     requiresClassroom: true,
    //     iconUrl: 'https://firebasestorage.googleapis.com/v0/b/calculating-cats.appspot.com/o/website%2Ficons%2Ficons-reports.png?alt=media&token=1d6305b0-ad8a-4f4d-a1e7-957ac05801ce'
    // },
]