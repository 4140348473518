import { API_ADDRESS_LOCAL } from "../../api/api-addr/l";
import { IContextData } from "./common";

export const VEA:IContextData = {
  id: 'VEA',
  // homepageRoute: '/en/general/login/false',
  loginRoute: '/en/login-educator',
  homepageRoute: '/en/login-router-st',
  brandName: 'Vretta eAssessment',
  logo: '',
  footer: null,
  apiAddress: (hostname:string) => {
    console.log(hostname)
    switch(hostname){
      case 'localhost2':
      case 'localhost':
        // return API_ADDRESS_LOCAL;
         return "https://bced-qc.vretta.com"
      // case 'bced.vretta.com': // field test
      // case 'bced-qc.vretta.com': // field test
      case 'bced-feat2.vretta.com':
      case 'bced-feat1.vretta.com':
        return "https://bced-qc.vretta.com"
        // return 'https://eqao-api.vretta.com';
      default:
        //return 'https://api-eassessment.vretta.com';
        return "https://bced-qc.vretta.com"
    }
  },
  siteFlags: {
    'INST_MNG_TT': true,
    'IS_BCED': true,
    'TEST_CTRL_REPORT_EXPLR': true,
    'TEST_CTRL_DATA_QUERY': true,
    'TEST_RUNNER_WIDTH_CONSTRAINT': false,
    'IS_BC_FIELD_TEST_2021': true,
    'IS_BC': true,
    'BC_STUDENT': true,
    'QUESTION_DEBUG_LOGS': false,
  },
  siteText: {
    copyright: '', //  © Vretta Inc. 2021
    supportEmail: 'bced-support@vretta.com',
    supportPhone: '',
    login_admins: 'lbl_administrators',
    my_inst: 'My School',
    my_inst_admin: 'You are the **administrator** of this school.',
    my_inst_manage_acct: 'Manage Teachers+',
    // asmt_logo_en: 'https://eqao.vretta.com/authoring/user_uploads/21/authoring/bc-logo/1610467447537/bc-logo.png',
    // asmt_logo_fr: 'https://eqao.vretta.com/authoring/user_uploads/21/authoring/bc-logo/1610467447537/bc-logo.png',
    header_bg_color: '#003366',
    header_logo_en: 'https://eqao.vretta.com/authoring/user_uploads/21/authoring/bc-logo/1610467447537/bc-logo.png',
    header_logo_fr: 'https://eqao.vretta.com/authoring/user_uploads/38543/authoring/fr-BC_V_pos-v2/1613521513099/fr-BC_V_pos-v2.png',
    student_ident: 'PEN',
    marking_chat:'Scorings Chat',
    marking:'marking',
    mark:'mark',
    marker:'marker',
    markers:'markers',
    marks:'marks',
    marked:'marked',
    reliability:'reliability',
    exemplar:'exemplar',
    exemplars:'exemplars',
    TnR:'T&R'

  },
  hosts: [
      'eassessment.vretta.com',
      // 'localhost:4200',
      // 'localhost2:4200',
      // '26dc5ff374e3.ngrok.io',
      // 'd1c1qqn86e6v14.cloudfront.net',
  ],
  defaultTimezone: 'America/Vancouver',
}
