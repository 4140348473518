import * as moment from 'moment-timezone';

const tzGuess = moment.tz.guess();
export const mtz = (dateStr?:string | Date) => moment.tz(dateStr, tzGuess);

/***
 * Parse UTC time to Eastern timezone
 * @param {Date} dateStr - UTC date time string
 */
export const etz = (dateStr?:string | Date) => moment.tz(dateStr, "America/Toronto");

/**
 * Modify time zone abbreviation for French language users
 * @param dateStr 
 * @param lang 
 * @returns date string with French time zone abbreviation 
 */
export const tzLangAbbrs = (dateStr: string, lang: string): string => {
    const abbrs = {
        fr: {
            'PST': 'HNP',
            'PDT': 'HAP',
            'MST': 'HNR',
            'MDT': 'NAR',
            'CST': 'HNC',
            'CDT': 'HAC',
            'EST': 'HNE',
            'EDT': 'HAE',
        }
    };
    const regex = new RegExp(`\\b(${Object.keys(abbrs[lang]).join('|')})\\b`, 'g');
    
    return dateStr.replace(regex, (match) => {
        return abbrs[lang][match] || match; // Replace with the abbreviation if found, otherwise keep the original match
    });
    
}