import { API_ADDRESS_LOCAL, API_ADDRESS_LOCALB } from "../../api/api-addr/l";
import { IContextData } from "./common";
import { context } from '../../../context.json';

const footer = [
  [
    {"route":"/contact", "caption": "title_contactus"},
    // {"route":"/news", "caption": "title_news"},
  ],
  [
    {"route":"/privacy", "caption": "title_privacy"},
  ],
  [
    {"route":"/legal", "caption": "title_legal"}
  ],
]

const apiAddress = (hostname:string) => {
// <<<<<<< HEAD
//   switch(hostname){
//     case 'localhost':
//       // return 'https://eqao2-stress-06032022-api.vretta.com';
//       return API_ADDRESS_LOCALB;
//       // return 'https://eqao2-api.vretta.com';
//       // return 'https://qc7-api.vretta.com';
//         // return API_ADDRESS_LOCAL;
//       //return 'https://apiqc.mathproficiencytest.ca'
//       //return 'https://api-eassessment.vretta.com';
//     case 'eqao-xqc.vretta.com':
//       return 'https://api-eqao-xqc.vretta.com';
//     case 'eqao-main-qc.vretta.com':
//       return 'https://apiqc.mathproficiencytest.ca';
//     case 'qc7.vretta.com':
//     case 'd3k4cvve5iw7mr.cloudfront.net':
//       return 'https://qc7-api.vretta.com';
//     case 'eqao.vretta.com':
//     case 'd3h4m0g2lmrmq6.cloudfront.net':
//     case 'private.eqao.vretta.com':
//     case 'eqao-lit.vretta.com':
//     case 'd12o445rx6gi3b.cloudfront.net':
//     case 'dwod99k06nyqh.cloudfront.net':
//       return 'https://eqao2-api.vretta.com';
//       // return 'https://eqao-api.vretta.com';
//     case 'drkpaddt3nk24.cloudfront.net':
//       return 'https://eqao-api.vretta.com';
//     case 'd24a8vriqswfok.cloudfront.net':
//       return 'https://apiqc.mathproficiencytest.ca';
//     case 'd1w433s63hot3w.cloudfront.net':
//     case 'd2r9nuffi6xllr.cloudfront.net':
//       return 'https://eqao-qc-api.vretta.com'
//     case 'qc1.mathproficiencytest.ca':
//     case 'qc4.mathproficiencytest.ca':
//       return 'https://apiqc.mathproficiencytest.ca';
//     default:
//       return 'https://api.mathproficiencytest.ca';
// =======
  if (hostname) {
    return context[hostname];
  } else {
    return context['*'];
  }
}
  
export const ANON:IContextData = {
    id: 'ANON',
    homepageRoute: '/en/login-router-st',
    brandName: 'Vretta E-Assessment',
    defaultTimezone: 'America/Toronto',
    logo: 'anon_landing_logo',
    footer: null,
    apiAddress,
    siteFlags: {
      BREADCRUMB_MENU: false,
      BOOKING_POLICY_STATS: true,
      IS_EQAO: true,
      EQAO_LOGIN: true,
      'INST_MNG_TT': true,
      'TEST_RUNNER_WIDTH_CONSTRAINT': true,
      'PREVENT_AUTO_EDIT': true,
      'IS_TC_SUMM': true,
      'IS_TC_DATA': false,
    },
    siteText: {
        copyright: 'title_copyright',
        supportEmail: 'support_email',
        supportPhone: 'eqao_support_phone_number',
        login_admins: 'teacher_administrator',
        my_inst: 'My School',
        my_inst_admin: 'You are the **administrator** of this school.',
        my_inst_manage_acct: 'Manage Teachers+',
        student_ident: 'oen',
        student_ident_2: 'lbl_sasn',
        asmt_logo_en: 'https://eqao.vretta.com/authoring/user_uploads/392800/logo_vretta.png',
        asmt_logo_fr: 'https://eqao.vretta.com/authoring/user_uploads/392800/logo_vretta.png',
        header_logo_en: 'https://eqao.vretta.com/authoring/user_uploads/392800/logo_vretta.png',
        header_logo_fr: 'https://eqao.vretta.com/authoring/user_uploads/392800/logo_vretta.png',
        marking_chat:'Scoring Chat',
        marking:'scoring',
        mark:'score',
        marker:'scorer',
        markers:'scorers',
        marks:'scores',
        marked:'scored',
        reliability:'validity',
        exemplar:'validity response',
        exemplars:'validity responses',
        TnR:'T&V',
        sb_board_logo_en: 'https://eqao.vretta.com/authoring/user_uploads/392800/logo_vretta.png',
        sb_board_logo_fr: 'https://eqao.vretta.com/authoring/user_uploads/392800/logo_vretta.png',
        g9_sample_test_logo_en: 'https://eqao.vretta.com/authoring/user_uploads/392800/logo_vretta.png',
        g9_sample_test_logo_fr: 'https://eqao.vretta.com/authoring/user_uploads/392800/logo_vretta.png',
        sample_test_welcome_logo: 'anon_landing_logo',
        sa_report_logo_link_2022: 'anon_landing_logo',
        payment_invoice_logo_en: 'https://eqao.vretta.com/authoring/user_uploads/392800/logo_vretta.png',
        payment_invoice_logo_fr: 'https://eqao.vretta.com/authoring/user_uploads/392800/logo_vretta.png',
        logo_bw_en: 'https://eqao.vretta.com/authoring/user_uploads/392800/logo_vretta.png',
        logo_bw_fr: 'https://eqao.vretta.com/authoring/user_uploads/392800/logo_vretta.png'
    },
    siteData: {
      'SCORING_SUPPORT': {
        text: 'lbl_technical_support_scor',
        email: 'support_email',
        phone:'eqao_support_phone_number',
      }
    },
    hosts: [
        'd3h4m0g2lmrmq6.cloudfront.net', // prod
        'd1e3j35lsarkvc.cloudfront.net' // qc8
    ]
}
