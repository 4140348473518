import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { IAudioSrcInfo } from './render-audio/render-audio.component';

export interface ILogResponse {
    itemType: string,
    response: any
}
@Injectable({
  providedIn: 'root'
})
export class ElementLogService {

  public onResponseChange: Subject<ILogResponse> = new Subject();

  constructor() { }


}
