import { NgModule } from '@angular/core';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
// import { ServiceWorkerModule } from '@angular/service-worker';
// ui boosts
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CdkTreeModule } from '@angular/cdk/tree';
import { FullCalendarModule } from '@fullcalendar/angular'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import interactionPlugin from '@fullcalendar/interaction'
import { ClipboardModule } from 'ngx-clipboard';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { AgGridModule } from 'ag-grid-angular';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';

// VEA App Modules
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { MarkdownModule, MarkedOptions } from 'ngx-markdown';
import { UiLoginModule } from './ui-login/ui-login.module';
import { UiPartialModule } from './ui-partial/ui-partial.module';
import { UiHierarchyChatModule } from './ui-hierarchy-chat/ui-hierarchy-chat.module';

import { GraphModule } from './graph/graph.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularSplitModule } from 'angular-split';
import { UiWhitelabelEnsModule } from './ui-whitelabel-ens/ui-whitelabel-ens.module';

import { MainNavComponent } from './core/main-nav/main-nav.component';
import { AudioDirective } from './ui-partial/audio-directive.directive';
import { UiStudentModule } from './ui-student/ui-student.module';
import { UiQuestionnaireModule } from './ui-questionnaire/ui-questionnaire.module';

import 'codemirror/mode/javascript/javascript';
import 'codemirror/mode/xml/xml';

export const ngMarkDownOptions = {
  markedOptions: {
    provide: MarkedOptions,
    useValue: {
      gfm: true,
      tables: true,
      breaks: false,
      pedantic: false,
      sanitize: false,
      smartLists: true,
      smartypants: false,
    },
  },
}

FullCalendarModule.registerPlugins([ // register FullCalendar plugins
  dayGridPlugin,
  interactionPlugin
]);

// IMPORTANT
// Add your own project credentials to environments/*.ts

@NgModule({
    declarations: [
        AppComponent,
        MainNavComponent,
     
    ],
    providers: [
        {provide: LocationStrategy, useClass: HashLocationStrategy}
    ],
    imports: [
        BrowserModule.withServerTransition({appId: 'serverApp'}),
        BrowserTransferStateModule,
        AppRoutingModule,
        HttpClientModule,
        CoreModule,
        UiPartialModule,
        UiLoginModule,
        UiHierarchyChatModule,
        UiWhitelabelEnsModule,
        NgxMaskModule.forRoot(),
        AgGridModule.withComponents([]),

        BrowserAnimationsModule,
        MarkdownModule.forRoot(ngMarkDownOptions),
        AngularSplitModule.forRoot(),
        ClipboardModule,
        NgxJsonViewerModule,
        CKEditorModule,
        FullCalendarModule,
        DragDropModule,
        CdkTreeModule,
        FormsModule,
        ReactiveFormsModule,
        GraphModule,
        UiPartialModule,
    ],
    exports: [
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
