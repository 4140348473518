import { IContentElementFrameStateStyle } from "../element-render-frame/model";
import { IContentElement, IContentElementColorPanel } from "../models";

export interface IContentElementImage extends IContentElement {
    altText?: string;
    fileType?: string;
    frameState?: IContentElementFrameStateStyle;
    hiContrastImg?: IContentElementImage
    isHiContrastSensitive?: boolean
    isNoInvertOnHiContrast?: boolean
    mode?: ContrastMode,
    outline?: boolean;
    scale?: number;
    scaleFactor?: number;
    setRelative?: boolean;
    url?: string;
    urls?: string[];
    XPosition?: number;
    YPosition?: number;
    disableAltText?: boolean;
    isFullWidth?: boolean;
  }

export interface IContentElementImageSubText extends IContentElement {
    alignment?: string;
    colourScheme?: IContentElementColorPanel;
    fontFamily?: string;
    invisible: any;
    lineHeight?: number;
    rotation?: number;
    size: number;
    text: string;
    width: number;
    x: number;
    y: number;
  }

  export enum ImageStates {
    SELECTED = "selected",
    HOVERING = "hovering",
    DEFAULT = "default"  
  }

  export interface IContentElementDynamicImage extends IContentElementImage {
    images?: { [key: string]: IContentElementConditionalImage };
    subtexts?: IContentElementImageSubText[];
  
  }

  export interface IContentElementConditionalImage {
    condition: string;
    image?: IContentElementImage;
  }

  export enum ContrastMode {
    NORMAL = 'normal',
    HIGH_CONTRAST = 'hicontrast'
  }

  export const defaultImageScaleFactor = 20;

  export const imageEditInfo = {
    editExcludeFields: ['frameState', 'hiContrastImg'],
    editTextFields: ['text']
  }