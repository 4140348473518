import { NgModule } from '@angular/core';
import { NotifyService } from './notify.service';
import { SidepanelService } from './sidepanel.service';
import { ChatpanelService } from './chatpanel.service';
import { RouteViewBaseComponent } from './route-view-base/route-view-base.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { DomainInterceptor } from './domain.interceptor';

@NgModule({
  imports : [
    HttpClientModule,
  ],
  providers: [
    NotifyService, 
    SidepanelService, 
    ChatpanelService,
    { provide: HTTP_INTERCEPTORS, useClass: DomainInterceptor, multi: true }
  ],
  declarations: [RouteViewBaseComponent],
})
export class CoreModule { }
