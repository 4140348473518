export const downloadStr = (content:string, fileName:string) => {
    const contentType = 'text/plain';
    var a = document.createElement("a");
    var file = new Blob([content], {type: contentType});
    a.href = URL.createObjectURL(file);
    a.download = fileName;
    a.click();
}

export const downloadFile = (file) => {
    var a = document.createElement("a");
    a.href = file;
    a.target = '_blank';
    a.click();
}

export const downloadUrl = (url:string) =>{
    var a = document.createElement("a");
    a.href = url;
    a.download = '';
    a.target = '_blank';
    a.click();
}

