import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { replaceS3Domains } from './util/transform';
import { AuthService } from '../api/auth.service';
import { RoutesService } from '../api/routes.service';

@Injectable({
  providedIn: 'root'
})
export class DomainInterceptor implements HttpInterceptor {

  constructor(
    private auth: AuthService,
    private routes: RoutesService,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const modifiedReq = req.clone({
      url: replaceS3Domains(req.url)
    });

    // Log domain rewrite event
    if(req.url !== modifiedReq.url){
      this.auth.apiCreate(this.routes.LOG, {
        slug: 'INTERCEPTOR_DOMAIN_MODIFICATION',
        data: {
          originalURL: req.url,
          modifiedURL: modifiedReq.url
        }
      })
    }

    return next.handle(modifiedReq);
  }
}
