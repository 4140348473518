import { Component, OnInit, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import {LoginGuardService} from "../../api/login-guard.service";

export interface IMenuTabConfig<T> {
  id: T;
  caption: string;
  count?: () => number | string;
  showIf?: () => boolean;
  ///
  isCountEnabled?: boolean;
  isShowIfEnabled?: boolean;
  data?: any;
  disabled?: boolean;
}

@Component({
  selector: 'menu-bar',
  templateUrl: './menu-bar.component.html',
  styleUrls: ['./menu-bar.component.scss']
})
export class MenuBarComponent implements OnInit, OnChanges {

  @Input() isSubMenu: boolean;
  @Input() isLocked: boolean;
  @Input() menuTabs: IMenuTabConfig<string>[];
  @Input() tabIdInit: string;
  @Input() emitChangeOnClickOnly?:boolean;
  @Output() change = new EventEmitter();

  private activeTabId: string;

  constructor(
      private loginGuard: LoginGuardService
  ) { }

  ngOnInit(): void {
    if (this.tabIdInit) {
      this.setActiveId(this.tabIdInit);
    }
  }

  ngOnChanges() {
    if (this.tabIdInit){
      this.setActiveId(this.tabIdInit);
    }
    if (this.menuTabs){
      this.menuTabs.forEach(tab => {
        tab.isCountEnabled = (typeof tab.count === 'function');
        tab.isShowIfEnabled = (typeof tab.showIf === 'function');
      });
    }
  }

  isTabHidden(tab:IMenuTabConfig<any>){
    if (tab.isShowIfEnabled){
      return tab.showIf();
    }
    return true;
  }

  isActiveId(id:string){ return this.activeTabId === id; }
  setActiveId(id:string, isClicked?:boolean){
    // Extra ensurance that can't switch tabs if the entire menu is locked
    if (this.isLocked) return;

    const tab = this.menuTabs.filter(tab => tab.id === id)[0];
    if (!tab) {
      return;
    }
    if (tab.disabled) {
      this.loginGuard.quickPopup('This page is currently unavailable.')
      return;
    }
    const isChangeOnClick = (typeof isClicked !== 'undefined' && isClicked);
    this.activeTabId = id;
    if (this.emitChangeOnClickOnly) {
      if (isChangeOnClick) {
        this.change.emit(id);
      }
    } else {
      this.change.emit(id);
    }
  }

}
