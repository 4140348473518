import { Directive, ElementRef, Renderer2, AfterViewInit  } from '@angular/core';
import { replaceS3Domains } from '../core/util/transform';

@Directive({
  selector: '[src-domain]'
})
export class SrcDomainDirective implements AfterViewInit {

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngAfterViewInit() {
    const element: HTMLElement = this.el.nativeElement;
    const tagName = element.tagName.toLowerCase();

    if (tagName === 'img' || tagName === 'audio' || tagName === 'video' || tagName === 'a') {
      this.updateElementSrcOrHref(element);
    }
  }

  private updateElementSrcOrHref(element: HTMLElement) {
    if (element instanceof HTMLImageElement || element instanceof HTMLAudioElement || element instanceof HTMLVideoElement) {
      this.updateSrc(element);
    } else if (element instanceof HTMLAnchorElement) {
      this.updateHref(element);
    }
  }

  private updateSrc(element: HTMLImageElement | HTMLAudioElement | HTMLVideoElement) {
    if (element.src) {
      this.setAttribute(element, 'src');
    }

    if (element instanceof HTMLAudioElement || element instanceof HTMLVideoElement) {
      const sources = element.querySelectorAll('source');
      sources.forEach(source => this.setAttribute(source, 'src'));
      element.load();  // Reload the media element to reflect changes
    }
  }

  private updateHref(element: HTMLAnchorElement) {
    this.setAttribute(element, 'href');
  }

  private setAttribute(element: HTMLElement, attributeName: string) {
    const attrValue = element.getAttribute(attributeName);
    if (attrValue) {
      const modifiedValue = replaceS3Domains(attrValue);
      if (attrValue !== modifiedValue) {
        this.renderer.setAttribute(element, attributeName, modifiedValue);
      }
    }
  }
}
