
<fieldset [disabled]="editingDisabled.isReadOnly()">
  <div *ngIf="!this.isEditing">

    <div *ngIf="scoreMatrixElementTypes.includes(contentElement.elementType)">
      <config-score-matrix [element]="contentElement"></config-score-matrix>
    </div>
    
    <!-- elementType: {{contentElement.elementType}} -->
    <div [ngSwitch]="contentElement.elementType">
      <element-config-audio                  *ngSwitchCase = "ElementType.AUDIO"          [element] = "contentElement" ></element-config-audio>
      <element-config-camera                 *ngSwitchCase = "ElementType.CAMERA"         [element] = "contentElement" ></element-config-camera>
      <element-config-dnd                    *ngSwitchCase = "ElementType.DND"            [element] = "contentElement" ></element-config-dnd>
      <element-config-moveable-dnd           *ngSwitchCase = "ElementType.MOVEABLE_DND"   [element] = "contentElement" ></element-config-moveable-dnd>
      <element-config-graphics               *ngSwitchCase = "ElementType.GRAPHICS"       [element] = "contentElement" ></element-config-graphics>
      <element-config-graphing               *ngSwitchCase = "ElementType.GRAPHING"       [element] = "contentElement" ></element-config-graphing>
      <element-config-iframe                 *ngSwitchCase = "ElementType.IFRAME"         [element] = "contentElement" ></element-config-iframe>
      <element-config-frame                  *ngSwitchCase = "ElementType.FRAME"          [element] = "contentElement" ></element-config-frame>
      <element-config-image                  *ngSwitchCase = "ElementType.IMAGE"          [element] = "contentElement" [canMove]='true' ></element-config-image>
      <element-config-input                  *ngSwitchCase = "ElementType.INPUT"          [element] = "contentElement" ></element-config-input>
      <element-config-doc-link               *ngSwitchCase = "ElementType.DOC_LINK"       [element] = "contentElement" ></element-config-doc-link>
      <element-config-matching               *ngSwitchCase = "ElementType.MATCHING"       [element] = "contentElement" ></element-config-matching>
      <element-config-math                   *ngSwitchCase = "ElementType.MATH"           [element] = "contentElement" ></element-config-math>
      <element-config-mcq                    *ngSwitchCase = "ElementType.MCQ"            [element] = "contentElement" ></element-config-mcq>
      <element-config-custom-mcq             *ngSwitchCase = "ElementType.CUSTOM_MCQ"     [element] = "contentElement" ></element-config-custom-mcq>
      <element-config-mic                    *ngSwitchCase = "ElementType.MIC"            [element] = "contentElement" ></element-config-mic>
      <element-config-order                  *ngSwitchCase = "ElementType.ORDER"          [element] = "contentElement" ></element-config-order>
      <element-config-reader                 *ngSwitchCase = "ElementType.READER"         [element] = "contentElement" ></element-config-reader>
      <element-config-canvas                 *ngSwitchCase = "ElementType.CANVAS"         [element] = "contentElement" ></element-config-canvas>
      <element-config-insertion              *ngSwitchCase = "ElementType.INSERTION"      [element] = "contentElement" ></element-config-insertion>
      <element-config-sbs                    *ngSwitchCase = "ElementType.SBS"            [element] = "contentElement" ></element-config-sbs>
      <element-config-solution               *ngSwitchCase = "ElementType.SOLUTION"       [element] = "contentElement" ></element-config-solution>
      <element-config-results-print          *ngSwitchCase = "ElementType.RESULTS_PRINT"  [element] = "contentElement" ></element-config-results-print>
      <element-config-selection-table        *ngSwitchCase = "ElementType.SELECT_TABLE"   [element] = "contentElement" ></element-config-selection-table>
      <element-config-select-text            *ngSwitchCase = "ElementType.SELECT_TEXT"    [element] = "contentElement"></element-config-select-text>
      <element-config-table                  *ngSwitchCase = "ElementType.TABLE"          [element] = "contentElement" ></element-config-table>
      <element-config-text                   *ngSwitchCase = "ElementType.TEXT"           [element] = "contentElement" ></element-config-text>
      <element-config-annotation             *ngSwitchCase = "ElementType.ANNOTATION"     [element] = "contentElement" ></element-config-annotation>
      <element-config-text-link              *ngSwitchCase = "ElementType.TEXT_LINK"      [element] = "contentElement" ></element-config-text-link>
      <element-config-upload                 *ngSwitchCase = "ElementType.UPLOAD"         [element] = "contentElement" ></element-config-upload>
      <element-config-video                  *ngSwitchCase = "ElementType.VIDEO"          [element] = "contentElement" ></element-config-video>
      <element-config-grouping               *ngSwitchCase = "ElementType.GROUPING"       [element] = "contentElement"></element-config-grouping>
      <element-config-passage                *ngSwitchCase = "ElementType.PASSAGE"        [element] = "contentElement"></element-config-passage>
      <element-config-template               *ngSwitchCase = "ElementType.TEMPLATE"       [element] = "contentElement"></element-config-template>
      <element-config-validator              *ngSwitchCase = "ElementType.VALIDATOR"      [element] = "contentElement"></element-config-validator>
      <element-config-custom-interaction     *ngSwitchCase = "ElementType.CUSTOM_INTERACTION" [element] = "contentElement"></element-config-custom-interaction>
      <element-config-bookmark-link          *ngSwitchCase = "ElementType.BOOKMARK_LINK"      [element] = "contentElement" ></element-config-bookmark-link>
      <element-config-static-diagram                *ngSwitchCase = "ElementType.STATIC_DIAGRAM"        [element] = "contentElement"></element-config-static-diagram>
    </div>
  </div>
</fieldset>
  <div *ngIf="this.isEditing" [style.border]="getBorder()"
  [style.color]="'#DCDCDC'"
  [style.margin.em]="0.5"
  [style.wordWrap]="'break-word'"
  [style.boxShadow]="isSelected() ? '0px 0px 10px grey' : 'none'"
  [style.transition]="'box-shadow 0.25s'"
  (click)="$event.stopPropagation(); this.toggleSelect()">
    <div class="diff-container" [class.is-editing]="usingEditingMode()" [style.cursor]="hasChanges() ? 'pointer' : 'auto'">
      <div style="display: flex; justify-content: space-between; align-items: center;">
        {{ getElementTypeDisplay() }}
        <div *ngIf="(editType === EditType.ADDED || editType === EditType.DELETED ) && !inheritedEditType" style="display: flex">
          <button class="button" style="width:2em; height:2em;" (click)="this.onClickAccept()"> <i class="fas fa-check"></i> </button>
          <button class="button" style="width:2em; height:2em;" (click)="this.onClickReject()"> <i class="fas fa-times"></i></button>
        </div>
      </div>
      <div [style.position]="'relative'" *ngFor="let field of displayEditFields">
        <div class="fields-container" [class.is-editing]="usingEditingMode()" *ngIf="hasChanges()">
          <div *ngIf="this.editStatus && this.fieldSelected === field.prop">
              <div class="popover-back">
                <div class="popover-container" (click)="$event.stopPropagation()">
                    <div>
                        <img src="../../../assets/icons/Accept.svg" />
                        <button (click)="this.onClickAccept()"> ACCEPT </button>
                    </div>
                    <div>
                        <img src="../../../assets/icons/Reject.svg" />
                        <button (click)="this.onClickReject()"> REJECT </button>
                    </div>
                </div>
                <img class="bubble-tail" src="../../../assets/icons/bubbleTail.svg"/>
            </div>            
          </div>
          <div *ngIf="!usingEditingMode()" class="fields">
            <div class="field-changed" [style.color]="this.getFieldColor()">{{ field.prop }}</div>
            <span class="author-text" *ngIf="isSelected()">{{ field.timestamp }}</span>
          </div>
          <div 
          *ngIf="!showTextDiffForProp(field.prop)" 
          class="value-changed" 
          (click)="$event.stopPropagation(); onClickValue(field.prop);" 
          [innerHTML]="field.content"
          [style.backgroundColor]="this.getValueColor()"></div>
          
          <text-diff class="value-changed-caption" [class.is-editing]="usingEditingMode()" *ngIf="showTextDiffForProp(field.prop)" [diffs]="field.textDiff" [dateEdited]="field.timestamp" [path]="field.path" [isFocused]="isSelected()" [element]="contentElement" (refreshChanges)="refreshChanges()"></text-diff>
        </div>
      </div>
      <span class="author-text" *ngIf="isSelected() && timestamp">{{ timestamp }}</span>
    </div>
    <ng-container *ngFor="let contentElement of this.subElements">
      <element-config  
      *ngIf="isViewableElement(contentElement)"
      [contentElement] = "contentElement"  
      [isEditing]="isEditing"
      [parentEditType]="editType"
      ></element-config>
    </ng-container>
</div>
